import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { offerActionCreators, offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { FooterActionsType } from '@interstate/components/FooterActions';
import { Typography } from '@interstate/components/Typography';
import { Modal } from '@interstate/components/Modal';

import { ErrorModalDialogContent } from '../common/ErrorModalDialogContent';

export type UnsavedChangesDialogProps = {
    onHide(): void;
    onUpdateSuccess(): void;
    onDraftSuccess(): void;
};

export const UnsavedChangesDialog = ({ onHide, onUpdateSuccess, onDraftSuccess }: UnsavedChangesDialogProps) => {
    const dispatch = useDispatch();

    const isDraftBeingSavedState = useSelector(offerInfoSelectors.getIsDraftBeingSaved);
    const hasDraftBeenSavedState = useSelector(offerInfoSelectors.getHasDraftBeenSaved);
    const isOfferBeingSavedState = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const hasOfferBeenSavedState = useSelector(offerInfoSelectors.getHasBeenSaved);

    const [draftSaveInProgress, setDraftSaveInProgress] = useState(false);
    const [draftSaveFailed, setDraftSaveFailed] = useState(false);
    const [offerSaveInProgress, setOfferSaveInProgress] = useState(false);
    const [offerSaveFailed, setOfferSaveFailed] = useState(false);
    const inProgress = draftSaveInProgress || offerSaveInProgress;

    const handleSaveDraft = useCallback(() => {
        dispatch(offerActionCreators.saveOffer({ isDraft: true }));
        setDraftSaveInProgress(true);
    }, [dispatch]);

    const handleUpdate = useCallback(() => {
        dispatch(offerActionCreators.saveOffer());
        setOfferSaveInProgress(true);
    }, [dispatch]);

    useEffect(() => {
        if (draftSaveInProgress) {
            if (isDraftBeingSavedState === false && hasDraftBeenSavedState === false) {
                setDraftSaveInProgress(false);
                setDraftSaveFailed(true);
                setOfferSaveFailed(false);
            } else if (isDraftBeingSavedState === false && hasDraftBeenSavedState === true) {
                onDraftSuccess();
                onHide();
            }
        }
    }, [hasDraftBeenSavedState, isDraftBeingSavedState, onHide, draftSaveInProgress, onDraftSuccess]);
    useEffect(() => {
        if (offerSaveInProgress) {
            if (isOfferBeingSavedState === false && hasOfferBeenSavedState === false) {
                setOfferSaveInProgress(false);
                setOfferSaveFailed(true);
                setDraftSaveFailed(false);
            } else if (isOfferBeingSavedState === false && hasOfferBeenSavedState === true) {
                onUpdateSuccess();
                onHide();
            }
        }
    }, [hasOfferBeenSavedState, isOfferBeingSavedState, onHide, offerSaveInProgress, onUpdateSuccess]);

    const footer: FooterActionsType = {
        options: [
            {
                action: onHide,
                label: 'Cancel',
                buttonStyle: 'tertiary',
                disabled: inProgress
            },
            {
                action: handleSaveDraft,
                label: draftSaveInProgress ? 'Saving...' : 'Save Draft',
                disabled: inProgress,
                customIdentifiers: {
                    optionButton1: {
                        dataTestId: 'save-draft-unsaved-changes'
                    }
                }
            }
        ],
        primary: {
            action: handleUpdate,
            label: offerSaveInProgress ? 'Updating...' : 'Update',
            disabled: inProgress,
            customIdentifiers: {
                primaryButton: {
                    dataTestId: 'update-unsaved-changes'
                }
            }
        }
    };

    return (
        <>
            <Modal
                data-testid="unsaved-changes-dialog"
                show={true}
                onHide={onHide}
                header={
                    <Typography variant="h3" color="sem.color.on-surface.default">
                        Unsaved Changes
                    </Typography>
                }
                footer={footer}
                size="large"
            >
                {!(draftSaveFailed || offerSaveFailed) && (
                    <Typography variant="body-md" color="sem.color.on-surface.default" tag="span">
                        You made updates to this deal that have not been saved or shared. You can save as a draft or update to
                        publish changes.
                    </Typography>
                )}
                {draftSaveFailed && (
                    <ErrorModalDialogContent
                        alertString="Failed to save draft."
                        contentString="We ran into a problem saving these changes as a draft. Please try again."
                    />
                )}
                {offerSaveFailed && (
                    <ErrorModalDialogContent
                        alertString="Offer update failed."
                        contentString="We ran into a problem updating this offer so the information wasn't shared. Please try again."
                    />
                )}
            </Modal>
        </>
    );
};

export default UnsavedChangesDialog;
