import { NumericInput } from '@interstate/components/NumericInput';
import { useDispatch } from 'react-redux';
import { handleTabChange } from './utils';
import { BaseTabs } from '../baseDealTypes';

interface ReadOnlyInputProps {
    label: string;
    inputValue?: string;
    tab?: BaseTabs;
}

const ReadOnlyInput = (props: ReadOnlyInputProps) => {
    const { label, inputValue, tab } = props;
    const dispatch = useDispatch();

    return (
        <>
            <label
                data-testid={`${label}-label`}
                style={{
                    color: '#005BA8',
                    cursor: 'pointer',
                    marginBottom: '8px'
                }}
                onClick={() => tab && handleTabChange(tab, dispatch)}
            >
                {label}
            </label>
            <NumericInput displayLabel={false} name={label} data-testid={label} readOnly={true} value={inputValue} />
        </>
    );
};

export default ReadOnlyInput;
