// externals
import { FC } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';

// components
import { ShowWithFeatureToggle } from '../../utils/ShowWithFeatureToggle';
import { FormPriceInput } from '../formManager/FormPriceInput';
import { FormSelectInput } from '../formManager/FormSelectInput';

// consts/enums
import { WHOLESALE_BOOK_SOURCE_OPTIONS } from '../../constants';
import { AccelerateVehicleCondition } from '@makemydeal/dr-platform-types';

// utils
import { bookOutValueValidator, pricingInputList } from '../formManager/helper';

// selectors
import { vehicleSelectors } from '@makemydeal/dr-dash-store';

const PricingForm: FC = () => {
    const vehicleCondition = useSelector(vehicleSelectors.getVehicle).condition;
    const isVehicleConditionNew = vehicleCondition === AccelerateVehicleCondition.NEW;

    return (
        <Grid container spacing={2} paddingBottom={1}>
            <Grid xs={12}>
                <Typography variant="h5">Pricing</Typography>
            </Grid>
            {pricingInputList.map(({ name, label }) => (
                <Grid key={name} xs={6}>
                    <FormPriceInput name={name} label={label} />
                </Grid>
            ))}
            <Grid key="wholesaleValue" xs={6}>
                <div data-testid="wholesaleValue-root">
                    <FormPriceInput
                        name="wholesaleValue"
                        label="Bookout Value"
                        validator={isVehicleConditionNew ? undefined : bookOutValueValidator}
                    />
                </div>
            </Grid>

            <Grid key="wholesaleBookSource" xs={6}>
                <FormSelectInput
                    name="wholesaleBookSource"
                    label="Bookout Source"
                    errorMessage="Required"
                    options={WHOLESALE_BOOK_SOURCE_OPTIONS}
                    maxHeight="250px"
                    placeholder="Select"
                />
            </Grid>
        </Grid>
    );
};

export default PricingForm;
