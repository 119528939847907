// externals
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { TableColumn } from '@interstate/components/Table';
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// utils
import { transformProductToModel, VehicleProtectionTableModel } from '@makemydeal/dr-activities-vehicle-protection';
import {
    ProductColumn,
    ProductTitle,
    ProductValue,
    VehiclePreviewCardGrid,
    VehiclePreviewCardRow
} from './VehicleProtectionCardUI.interstate.style';
import { getVPPTableColumns } from './VehicleProtectionPreviewTable';

const VehicleProtectionPreviewCard = () => {
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const isFetchingRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const vppTableColumns = getVPPTableColumns(true);

    const menuProducts = products
        .filter((product) => product.selected)
        .map((product) => transformProductToModel(product, isFetchingRates || isFetchingVppProducts));

    return (
        <VehiclePreviewCardGrid>
            {menuProducts.map((product: VehicleProtectionTableModel, index) => (
                <VehiclePreviewCardRow>
                    <Grid container spacing={3} rowSpacing="18px">
                        {vppTableColumns.map((column: TableColumn) => {
                            const gridTitle = column.title as string;
                            const gridValue = product[column.dataIndex as keyof VehicleProtectionTableModel];

                            return (
                                <Grid xs={4}>
                                    <ProductColumn>
                                        <ProductTitle>{gridTitle}</ProductTitle>
                                        <ProductValue>{gridValue}</ProductValue>
                                    </ProductColumn>
                                </Grid>
                            );
                        })}
                    </Grid>
                </VehiclePreviewCardRow>
            ))}
        </VehiclePreviewCardGrid>
    );
};

export default VehicleProtectionPreviewCard;
