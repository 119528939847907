import numeral from 'numeral';
import { SearchableSelectOptions } from '@cx/ui/SearchableSelect';
import { dateUtils, formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { CASH, LEASE, OfferType, PaymentGridRow } from '@makemydeal/dr-platform-types';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { AMOUNT_PAID_BY_CUSTOMER, CASH_DOWN, DUE_AT_SIGNING, OUT_OF_POCKET } from '../../constants';
import { ComboBoxOption } from '@interstate/components/ComboBox';

export type ComboBoxPairs = {
    id: number;
    name: string;
};

export const termIdToOptionMapper = ({ id, months }: any): SearchableSelectOptions => ({
    value: id,
    label: `${months} Months`
});

export const ENTER_KEY_CODE = 'Enter';

export const formatAnnualMiles = (miles: number): string => `${numeral(miles).format('0,0')} Miles`;

export const parseSelectedTermMonths = (termId: string): string | undefined => {
    if (!termId) {
        return undefined;
    }
    const parts = termId.split('_');
    return parts[2];
};

export const addValueSuffix = (value: string): string => {
    if (value === '') {
        return value;
    }

    if (value === '1') {
        return `${value} Month`;
    }

    return `${value} Months`;
};

export const removeValueSuffix = (value: string): string => {
    return value.replace(' Months', '').replace(' Month', '');
};

export const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    return isoDate && dateUtils.isValidDate(date) ? dateUtils.formatUtcDateTimeToDateOnly(isoDate, 'mmddyyyy') : '';
};

export const formatSingleCardViewPaymentDetails = (currentOfferType: OfferType, monthlyPayment?: number) => {
    return currentOfferType !== CASH ? `${formatDollarsAndCents(monthlyPayment)}/mo` : '';
};
export const formatPaymentDetailsCashDown = (currentOfferType: OfferType, amountPaidByCustomerLease: number) => {
    const label = currentOfferType === LEASE ? AMOUNT_PAID_BY_CUSTOMER : CASH_DOWN;
    return `${label} - ${formatDollarsAndCents(amountPaidByCustomerLease)}`;
};
export const formatMultiCardViewPaymentDetails = (
    currentOfferType: OfferType,
    monthlyPayment?: number,
    cashAmountDue?: number,
    selectedTermId?: string
) => {
    // add indicator L C F for all offer types
    const offerTypeIndicator = currentOfferType[0].toUpperCase();
    let paymentValue = offerTypeIndicator;

    // if offer type is not cash, add term value
    if (currentOfferType !== CASH) {
        const months = parseSelectedTermMonths(selectedTermId || '');
        paymentValue += months || '';
    }

    // add payment value. for cash it is total, for finance/lease it is monthly payment
    // no need to check for cashAmountDue or monthlyPayment, formatDollarsAndCents will handle it
    if (currentOfferType === CASH) {
        paymentValue += ` - ${formatDollarsAndCents(cashAmountDue)}`;
    } else {
        paymentValue += ` - ${formatDollarsAndCents(monthlyPayment)}/mo`;
    }

    return paymentValue;
};

export const getNewTarget = (event: any) => {
    const inputValue = (event.target as HTMLInputElement).value;
    return {
        target: {
            value: inputValue.replace(/,/g, '')
        }
    } as InterstateOnChangeEvent<TextInputEventValue>;
};

/**
 * Determine which label to use for "Cash Down" in the UI
 */
export const generateCashDownLabel = (
    offerType: OfferType,
    deductDueAtSigning: boolean,
    isTargetOutOfPocket: boolean,
    isEnhancedDealDetailsEnabled: boolean,
    isTargetOutOfPocketLeaseEnabled: boolean
) => {
    if (offerType === LEASE) {
        /*  Pre isTargetOutOfPocketLeaseEnabled logic */
        if (!isTargetOutOfPocketLeaseEnabled) {
            if (!isEnhancedDealDetailsEnabled && deductDueAtSigning) {
                return DUE_AT_SIGNING;
            }

            if (isEnhancedDealDetailsEnabled && deductDueAtSigning) {
                return OUT_OF_POCKET;
            }
        }

        /* isTargetOutOfPocketLeaseEnabled logic E39554 */
        if (isTargetOutOfPocketLeaseEnabled) {
            if (!isEnhancedDealDetailsEnabled && !deductDueAtSigning && isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }

            if (!isEnhancedDealDetailsEnabled && deductDueAtSigning && !isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }

            if (!isEnhancedDealDetailsEnabled && deductDueAtSigning && isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }

            if (isEnhancedDealDetailsEnabled && !deductDueAtSigning && isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }

            if (isEnhancedDealDetailsEnabled && deductDueAtSigning && !isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }

            if (isEnhancedDealDetailsEnabled && deductDueAtSigning && isTargetOutOfPocket) {
                return OUT_OF_POCKET;
            }
        }
    }

    return CASH_DOWN;
};

export const buildComboOption = (option: ComboBoxPairs): ComboBoxOption => {
    const { id, name } = option;
    return {
        id: id.toString(),
        value: id.toString(),
        label: name.toString()
    };
};

export const areStringsEqual = (input: string | undefined, key: string) => {
    if (!input) return false;
    return input === key;
};

export const getKeyWithLoadingItem = (data: PaymentGridRow[]) => data.some((group) => group.items.some((item) => item?.isLoading));
