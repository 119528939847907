// libraries
import { BaseSelectors } from '@makemydeal/dr-activities-common';
import { IVehicle } from '@makemydeal/dr-platform-types';

// types
import {
    AdditionalSelectors,
    ChangeVehicleCustomVehicle,
    ChangeVehicleState,
    VehicleCustomData,
    StateTree,
    CatalogVehicleWithIndex
} from '../types';

const DEFAULT_SELECTED_VEHICLE: CatalogVehicleWithIndex = {} as CatalogVehicleWithIndex;

export class Selectors extends BaseSelectors {
    constructor(public sliceName: string, public additionalSelectors: AdditionalSelectors) {
        super(sliceName);
    }

    getCvi = (state: StateTree): ChangeVehicleState => {
        return super.getState(state);
    };

    getShowBuildVehicleError = (state: StateTree): boolean => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.showBuildVehicleError || false;
    };

    getIsSearchError = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.isSearchError || false;
    };

    getShowProcessingError = (state: StateTree): boolean => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.showProcessingError || false;
    };

    getShowSearchError = (state: StateTree): boolean => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.showSearchError || false;
    };

    getShowSearchLoader = (state: StateTree): boolean => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.isSearchInProgress || false;
    };

    getSearchResults = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.cviVehicles;
    };

    getIsStockNumberAvailable = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.isStockNumberAvailable;
    };

    getSearchQuery = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.searchQuery;
    };

    getCatalogYears = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.catalogYears || [];
    };

    getCatalogMakes = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.catalogMakes || [];
    };

    getCatalogModels = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.catalogModels || [];
    };

    getCatalogTrims = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.catalogTrims || [];
    };

    getCatalogVehicles = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.catalogVehicles || [];
    };

    getIsFetching = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return !!changeVehicle.isFetching;
    };

    getShowCatalogError = (state: StateTree) => {
        const changeVehicle = this.getCvi(state);

        return !!changeVehicle.showCatalogError;
    };

    getSelectedVehicle = (state: StateTree): CatalogVehicleWithIndex => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.selectedVehicle || DEFAULT_SELECTED_VEHICLE;
    };

    isChangeProcessing = (state: StateTree): boolean => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.isChangeProcessing || false;
    };

    getCustomVehicle = (state: StateTree): ChangeVehicleCustomVehicle => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.customVehicle || {};
    };

    getAllVehicleCustomData = (state: StateTree): Record<string, VehicleCustomData> => {
        const changeVehicle = this.getCvi(state);

        return changeVehicle.vehicleCustomData || {};
    };

    getCustomDataForVehicle =
        (vin: string) =>
        (state: StateTree): VehicleCustomData => {
            const vehicleCustomData = this.getAllVehicleCustomData(state);
            const DEFAULT_VEHICLE_CUSTOM_DATA = {};

            return vehicleCustomData[vin] || DEFAULT_VEHICLE_CUSTOM_DATA;
        };

    getSelectedVehicleWithCustomData = (state: StateTree): IVehicle => {
        const selectedVehicle = this.getSelectedVehicle(state);

        if (selectedVehicle.vin && selectedVehicle.stockNumber) {
            const { mileage, listedPrice, retailPrice } = this.getCustomDataForVehicle(selectedVehicle.vin)(state);

            return {
                ...selectedVehicle,
                ...(listedPrice !== undefined && { listedPrice }),
                ...(mileage !== undefined && { mileage }),
                ...(retailPrice !== undefined && { retailPrice })
            };
        } else if (selectedVehicle.chromeStyleId) {
            const { msrp, sellingPrice, cost, odometer } = this.getCustomVehicle(state);
            return {
                ...selectedVehicle,
                retailPrice: msrp,
                listedPrice: sellingPrice,
                invoiceAmount: cost,
                cost,
                mileage: odometer
            };
        } else {
            return selectedVehicle;
        }
    };

    getVehicleWithCustomData =
        (vin: string) =>
        (state: StateTree): IVehicle | null => {
            const allVehicles = this.getSearchResults(state);
            const vehicle = allVehicles?.find((vehicle) => vehicle.vin === vin);

            if (vehicle?.vin) {
                const { mileage, listedPrice } = this.getCustomDataForVehicle(vehicle.vin)(state);

                return {
                    ...vehicle,
                    ...(listedPrice !== undefined && { listedPrice }),
                    ...(mileage !== undefined && { mileage })
                };
            } else {
                return vehicle || null;
            }
        };

    shouldShowCost = (state: StateTree): boolean => {
        if (this.additionalSelectors.shouldShowCost) {
            return this.additionalSelectors.shouldShowCost(state);
        }

        return true;
    };

    getVehicleVin = (state: StateTree): string | undefined => {
        if (this.additionalSelectors.getVehicleVin) {
            return this.additionalSelectors.getVehicleVin(state);
        }

        return undefined;
    };

    getVehicleStockNumber = (state: StateTree): string | undefined => {
        if (this.additionalSelectors.getVehicleStockNumber) {
            return this.additionalSelectors.getVehicleStockNumber(state);
        }

        return undefined;
    };
}
