import styled from 'styled-components';

export const BaseDealSummarySectionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
    width: 100%;

    @media (max-width: 976px) {
        flex-direction: column;
        gap: 20px;
    }

    @media (max-width: 480px) {
        padding: 0px;
    }
`;

export const BaseDealSummaryLeftSectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    width: 47%;

    @media (max-width: 976px) {
        width: 100%;
        row-gap: 0px;
    }

    @media (max-width: 480px) {
        row-gap: 0px;
    }
`;

export const BaseDealSummaryRightSectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(100px, auto);

    flex: 1;

    @media (max-width: 976px) {
        width: 100%;
        row-gap: 0px;
    }

    @media (max-width: 480px) {
        row-gap: 0px;
    }
`;

export const InputField = styled.div`
    min-width: 120px;
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 976px) {
        min-width: 140px;

        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    @media (max-width: 480px) {
        min-width: 150px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
`;
