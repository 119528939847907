export const getPSErrorLookupKey = (payload: any) => {
    if (payload.error?.errors?.length) {
        const psErrorObj = payload.error.errors[0].originalPSError;
        let psErrorMessage: string | null = null;
        if (psErrorObj?.validationErrors) {
            psErrorMessage = getValidationErrorMessage(psErrorObj.validationErrors);
        } else if (psErrorObj?.message) {
            psErrorMessage = psErrorObj.message;
        }

        if (psErrorMessage && typeof psErrorMessage === 'string') {
            const lowercasePSErrorMsg: any = psErrorMessage.toLowerCase();
            const regex = /[a-z]/g;
            const onlyLowercaseCharacterError = lowercasePSErrorMsg.match(regex).join('');
            const actionForPayment =
                payload.originalAction.meta?.paymentGrid?.mainPaymentActionType || payload.originalAction.type || '';
            const lowercaseActionForPayment = actionForPayment.toLowerCase().match(regex)?.join('');

            const paymentServiceMappingKey = lowercaseActionForPayment + '_' + onlyLowercaseCharacterError;
            return paymentServiceMappingKey;
        }
    }
    return null;
};

export function getValidationErrorMessage(data: any, msg = ''): string {
    if (!data || msg) {
        return msg;
    }
    for (const [_, elm] of Object.entries(data)) {
        if (Array.isArray(elm)) {
            for (const value of elm) {
                if (value && typeof value === 'object') {
                    msg = getValidationErrorMessage(value, msg);
                } else {
                    msg = value;
                    break;
                }
            }
        } else if (elm && typeof elm === 'object') {
            msg = getValidationErrorMessage(elm, msg);
        } else {
            msg = elm as string;
        }
        if (msg) {
            break;
        }
    }
    return msg;
}
