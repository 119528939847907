// externals
import { addDaysToDate } from '@makemydeal/dr-common-utils';
import { VppDrivetrainType } from '@makemydeal/dr-shared-types';
import { DcVppVehicleUsage } from '@makemydeal/dr-dash-types';

// constants
import { DATE_OUT_OF_RANGE_ERROR, DATE_OVER_THE_MAX_DATE_ERROR } from './errorMessages';

// types
import {
    VppDealAttributeBasicInput,
    VppDealAttributeDateInput,
    VppDealAttributeInput,
    VppDealAttributeInputType,
    VppDealAttributesInputsSettings
} from '../../../../types';

export class VppDealAttributeInputBuilder<T extends VppDealAttributeBasicInput> {
    constructor(private attributes: T) {}
    build(): T {
        return this.attributes;
    }

    getter<R>(attr: string, fn: () => R): this {
        Object.defineProperty(this.attributes, attr, {
            get: fn,
            enumerable: true
        });

        return this;
    }
}

export const vppDealAttributesInputsSettings: VppDealAttributesInputsSettings = {
    amountFinanced: {
        name: 'amountFinanced',
        fullVppPath: 'finance.amountFinanced',
        title: 'Amount Financed',
        inputType: VppDealAttributeInputType.INFO,
        isPrice: true,
        description: 'The total amount that is financed by a lender for the deal.',
        order: 20
    },
    annualMiles: {
        name: 'annualMiles',
        fullVppPath: 'finance.annualMiles',
        title: 'Estimated Annual Mileage',
        description: 'The requested miles allowed to be driven per year on a lease (at no additional charge).',
        inputType: VppDealAttributeInputType.INFO,
        order: 27
    },
    apr: {
        name: 'apr',
        fullVppPath: 'finance.apr',
        title: 'APR',
        description: 'The effective annual percentage rate from a lender to cover the deal (according to Regulation Z).',
        inputType: VppDealAttributeInputType.INFO,
        order: 23
    },
    color: {
        name: 'color',
        fullVppPath: 'vehicle.color',
        title: 'Color',
        description: 'The actual vehicle color.',
        inputType: VppDealAttributeInputType.INFO,
        order: 12
    },
    year: {
        name: 'year',
        fullVppPath: 'vehicle.year',
        title: 'Year',
        description: 'The actual vehicle year.',
        inputType: VppDealAttributeInputType.INFO,
        order: 28
    },
    make: {
        name: 'make',
        fullVppPath: 'vehicle.make',
        title: 'Make',
        description: 'The actual vehicle make.',
        inputType: VppDealAttributeInputType.INFO,
        order: 29
    },
    model: {
        name: 'model',
        fullVppPath: 'vehicle.model',
        title: 'Model',
        description: 'The actual vehicle model.',
        inputType: VppDealAttributeInputType.INFO,
        order: 30
    },
    contractDate: new VppDealAttributeInputBuilder<VppDealAttributeDateInput>({
        name: 'contractDate',
        fullVppPath: 'finance.contractDate',
        title: 'Contract Date',
        inputType: VppDealAttributeInputType.DATEPICKER,
        description: 'Date when the contract is signed.',
        order: 15,
        errorMessage: DATE_OUT_OF_RANGE_ERROR
    })
        .getter('maxDate', () => addDaysToDate(new Date(), 30))
        .getter('minDate', () => addDaysToDate(new Date(), -30))
        .build(),
    customerState: {
        name: 'customerState',
        fullVppPath: 'finance.customerState',
        title: 'Customer State',
        inputType: VppDealAttributeInputType.INFO,
        description: "State associated with the customer's address.",
        order: 24
    },
    dealType: {
        name: 'dealType',
        fullVppPath: 'finance.dealType',
        title: 'Deal Type',
        description: 'The financial type of the deal.',
        inputType: VppDealAttributeInputType.INFO,
        order: 14
    },
    drivetrainType: {
        name: 'drivetrainType',
        fullVppPath: 'vehicle.drivetrainType',
        title: 'Drivetrain Type',
        description: 'The actual vehicle drivetrain type.',
        inputType: VppDealAttributeInputType.DROPDOWN,
        options: [
            {
                value: VppDrivetrainType.ALL_WHEEL_DRIVE,
                label: 'All Wheel Drive'
            },
            {
                value: VppDrivetrainType.FOUR_WHEEL_DRIVE,
                label: 'Four Wheel Drive'
            },
            {
                value: VppDrivetrainType.FRONT_WHEEL_DRIVE,
                label: 'Front Wheel Drive'
            },
            {
                value: VppDrivetrainType.REAR_WHEEL_DRIVE,
                label: 'Rear Wheel Drive'
            }
        ],
        allowUnselectOption: true,
        order: 3
    },
    dualRearWheel: {
        name: 'dualRearWheel',
        fullVppPath: 'vehicle.dualRearWheel',
        title: 'Dual Rear Wheel',
        description: 'The vehicle has four tires on the rear axle instead of two.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 4
    },
    fuelType: {
        name: 'fuelType',
        fullVppPath: 'vehicle.fuelType',
        title: 'Fuel Type',
        description: 'The actual vehicle fuel type.',
        inputType: VppDealAttributeInputType.DROPDOWN,
        options: [
            {
                value: 'Gasoline',
                label: 'Gasoline'
            },
            {
                value: 'Diesel',
                label: 'Diesel'
            },
            {
                value: 'Electric',
                label: 'Electric'
            },
            {
                value: 'NaturalGas',
                label: 'Natural Gas'
            },
            {
                value: 'Hydrogen',
                label: 'Hydrogen'
            },
            {
                value: 'Other',
                label: 'Other'
            }
        ],
        allowUnselectOption: true,
        order: 5
    },
    includeLiftKitSuspension: {
        name: 'includeLiftKitSuspension',
        fullVppPath: 'vehicle.includeLiftKitSuspension',
        title: 'Lift Kit Suspension',
        description: 'The vehicle has the lift kit suspension.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 7
    },
    includeNavigationSystem: {
        name: 'includeNavigationSystem',
        fullVppPath: 'vehicle.includeNavigationSystem',
        title: 'Navigation System',
        description: 'The vehicle has a navigation system installed (e.g., GPS).',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 9
    },
    includeSealsAndGasketsMaintenance: {
        name: 'includeSealsAndGasketsMaintenance',
        fullVppPath: 'finance.includeSealsAndGasketsMaintenance',
        title: 'Seals & Gaskets Maintenance',
        description: 'The seals and gaskets maintenance is included to up-sell.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 26
    },
    includeSnowPlow: {
        name: 'includeSnowPlow',
        fullVppPath: 'vehicle.includeSnowplow',
        title: 'Snowplow',
        description: 'The vehicle has a snowplow.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 8
    },
    includeTurboOrSupercharger: {
        name: 'includeTurboOrSupercharger',
        fullVppPath: 'vehicle.includeTurboOrSupercharger',
        title: 'Turbo or Supercharger',
        description: 'The vehicle has a turbo or supercharger.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 10
    },
    includeWarranty: {
        name: 'includeWarranty',
        fullVppPath: 'finance.includeWarranty',
        title: 'Manufacturer Warranty',
        description: "The vehicle has an existing manufacturer's warranty (e.g., a powertrain warranty as part of the sale).",
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 25
    },
    inServiceDate: new VppDealAttributeInputBuilder({
        name: 'inServiceDate',
        fullVppPath: 'finance.inServiceDate',
        title: 'In Service Date',
        inputType: VppDealAttributeInputType.DATEPICKER,
        description: 'Date when the vehicle will be in service.',
        order: 16,
        errorMessage: DATE_OVER_THE_MAX_DATE_ERROR
    })
        .getter('maxDate', () => new Date())
        .build(),
    inventoryType: {
        name: 'inventoryType',
        fullVppPath: 'vehicle.inventoryType',
        title: 'Inventory Type',
        description: 'The actual vehicle condition.',
        inputType: VppDealAttributeInputType.INFO,
        order: 1
    },
    monthlyPayment: {
        name: 'monthlyPayment',
        fullVppPath: 'finance.monthlyPayment',
        title: 'Monthly Payment',
        description: 'The monthly payment for the deal under a loan.',
        inputType: VppDealAttributeInputType.INFO,
        isPrice: true,
        order: 21
    },
    odometer: {
        name: 'odometer',
        fullVppPath: 'vehicle.odometer',
        title: 'Odometer',
        description: 'The current mileage of the vehicle (initial miles).',
        inputType: VppDealAttributeInputType.INFO_TOP,
        order: 2
    },
    overOneTon: {
        name: 'overOneTon',
        fullVppPath: 'vehicle.overOneTon',
        title: 'Weight Over 1 Ton',
        description: 'The vehicle weight is heavier than one ton.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 13
    },
    oversizedTires: {
        name: 'oversizedTires',
        fullVppPath: 'vehicle.oversizedTires',
        title: 'Oversized Tires',
        description: 'The oversized tires are in the vehicle.',
        inputType: VppDealAttributeInputType.CHECKBOX,
        order: 11
    },
    purchaseDate: new VppDealAttributeInputBuilder({
        name: 'purchaseDate',
        fullVppPath: 'finance.purchaseDate',
        title: 'Purchase Date',
        description: 'Date when the vehicle will be purchased.',
        inputType: VppDealAttributeInputType.DATEPICKER,
        order: 17,
        errorMessage: DATE_OUT_OF_RANGE_ERROR
    })
        .getter('maxDate', () => addDaysToDate(new Date(), 30))
        .getter('minDate', () => addDaysToDate(new Date(), -30))
        .build(),
    retailPrice: {
        name: 'retailPrice',
        fullVppPath: 'finance.retailPrice',
        title: 'Retail Price',
        description: 'The retail price of the vehicle (MSRP - for new vehicles).',
        inputType: VppDealAttributeInputType.INFO,
        isPrice: true,
        order: 18
    },
    sellingPrice: {
        name: 'sellingPrice',
        fullVppPath: 'finance.sellingPrice',
        title: 'Selling Price',
        description:
            'The price that the vehicle is offered for purchase (it is usually the advertised or listed price that started the negotiation).',
        inputType: VppDealAttributeInputType.INFO,
        isPrice: true,
        order: 19
    },
    term: {
        name: 'term',
        fullVppPath: 'finance.term',
        title: 'Term',
        description: 'The duration of the loan or lease within the deal.',
        inputType: VppDealAttributeInputType.INFO,
        order: 22
    },
    usage: {
        name: 'usage',
        fullVppPath: 'vehicle.usage',
        title: 'Vehicle Usage',
        description: 'The vehicle ownership type.',
        inputType: VppDealAttributeInputType.DROPDOWN,
        options: [
            {
                value: DcVppVehicleUsage.PERSONAL,
                label: 'Personal'
            },
            {
                value: DcVppVehicleUsage.BUSINESS,
                label: 'Business'
            },
            {
                value: DcVppVehicleUsage.AGRICULTURAL,
                label: 'Agricultural'
            }
        ],
        allowUnselectOption: true,
        order: 6
    },
    vin: {
        name: 'vin',
        fullVppPath: 'vehicle.vin',
        title: 'VIN',
        description: 'Vehicle identification number.',
        inputType: VppDealAttributeInputType.INFO_TOP,
        order: 0
    }
};

export const vppDealAttributesInputsList: VppDealAttributeInput[] = Object.values(vppDealAttributesInputsSettings);
