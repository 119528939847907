// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors, FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';

// interfaces/types
import type { AlertSeverity } from '@interstate/components/Alert/Types/alertTypes';

// selectors
import { getSelectedTerm, hasMissingPaymentData, getAppliedIncentives } from './offerRedux';
import { DateTime } from 'luxon';

// consts/enums
import { navigationActionTypes } from '../actionTypes';

// utils
import { filterByEndDate } from '../utils/incentivesUtil';

export const wasTheLastFailure = (actionTypeToCheck: string) => (state: StateTree) =>
    state.desking.lastFailureAction === actionTypeToCheck;

export const featureEnabled = (name: keyof FeatureTogglesAndUrlOnly) => (state: StateTree) =>
    featureToggleSelectors.getFeatureToggle(state, name);

/**
 * Get the selected term (with current offertype) and its ID for select options.
 * @returns {string} Selected term Id
 */
export const getSelectedTermId = (state: any) => getSelectedTerm(state)?.id;

export type AlertMessage = {
    message: string;
    /**
     * Alert type; `warning`, `info`, `error`, `success`
     */
    type: AlertSeverity;
    /**
     * Call to action text to appear on the button
     */
    cta?: string;
    /**
     * Provide an action to dispatch. (Typically this is our navigationActionTypes or legacy actions from activity packages).
     */
    ctaAction?: any;
    /**
     * Title if necessary; just passes through to the Interstate Alert.
     */
    title?: string;
};

/**
 * Aggregates alerts that should be displayed atop desking
 */
export const getDeskingAlerts = (state: any): AlertMessage[] => {
    // NOTE: Evaluate for expired incentives
    const messages: AlertMessage[] = [];

    if (shouldDisplayMissingPaymentDataError(state)) {
        messages.push({
            type: 'error',
            message: 'Missing Payment Details',
            title: 'Important Message About Payment Calculation'
        });
    }

    if (hasExpiredIncentives(state)) {
        messages.push({
            type: 'caution',
            message: 'Some items attached to this deal have expired.',
            ctaAction: { type: navigationActionTypes.DASH_START_PROGRAMS_ACTIVITY },
            cta: 'Update now',
            title: 'Important Message About Programs & Incentives'
        });
    }

    return messages;
};

/**
 * Returns true if any of the incentivees in the active selection are expired.
 * Expiration: hard wired to seven days before today.
 */
export const hasExpiredIncentives = (state: StateTree): boolean => {
    const incentives = getAppliedIncentives(state);
    const lowerLimit = DateTime.now().startOf('day').minus({ days: 7 }).toISO();
    const filterCheck = filterByEndDate(lowerLimit!); // NOTE: using same filter as the screen
    const total = incentives.reduce((sum, current) => sum + (!filterCheck({ endDate: current.endDate }) ? 1 : 0), 0);
    return total > 0;
};

export const shouldDisplayMissingPaymentDataError = (state: StateTree): boolean => {
    return hasMissingPaymentData(state);
};

export const shouldShowTotalTaxRateSupersedeAlert = (state: StateTree): boolean => {
    return !!state.desking?.showTotalTaxRateSupersedeAlert;
};

export const getPSErrorLookupKey = (state: StateTree) => state.desking.psErrorLookupKey;
