// libraries
import type { StateTree, DeskingGlobalState } from '@makemydeal/dr-dash-types';

export const getDeskingGlobalState = (state: StateTree): DeskingGlobalState => {
    return state.deskingGlobal || {};
};

export const getPaymentDetailsAccordionExpanded = (state: StateTree): boolean => {
    return getDeskingGlobalState(state).paymentDetailsAccordionExpanded || false;
};

export const getTaxesAccordionExpanded = (state: StateTree): boolean => {
    return getDeskingGlobalState(state).taxesAccordionExpanded || false;
};

export const getFeesAccordionExpanded = (state: StateTree): boolean => {
    return getDeskingGlobalState(state).feesAccordionExpanded || false;
};

export const getLienholderAccordionExpanded = (state: StateTree): boolean => {
    return getDeskingGlobalState(state).lienholderAccordionExpanded || false;
};

export const getRollToPaymentSlideOutExpanded = (state: StateTree): boolean => {
    return getDeskingGlobalState(state).rollToPaymentSlideOutExpanded || false;
};
