import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial/Interstitial/Interstitial';
import React from 'react';
import { FullScreenLoader } from './BaseScreenLoader.style';
import { useSelector } from 'react-redux';
import { paymentSelectors } from '@makemydeal/dr-dash-store';

const BaseScreenLoader = () => {
    const showLoader = useSelector(paymentSelectors.isCalculatingPayment);
    return showLoader ? (
        <FullScreenLoader>
            <LoadingIndicator size="large" message="Updating deal" />
        </FullScreenLoader>
    ) : null;
};

export default BaseScreenLoader;
