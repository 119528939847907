// externals
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { VehicleInfoDrawer, ProfitInfo } from '@makemydeal/dr-dash-components';
import { Action } from '@interstate/components/Action';
import { ArrowsRightLeftIcon, ArrowTopRightOnSquareIcon, ChartPieIcon } from '@interstate/components/Icons';
import { Typography } from '@interstate/components/Typography';
import { Popover } from '@interstate/components/Popover';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Flag } from '@interstate/components/Flag';

// selectors
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { configSelectors, dealXgDetailsSelectors, navigationActionCreators, vehicleSelectors } from '@makemydeal/dr-dash-store';

import {
    StyledVehicleInfoCard,
    StyledVehicleInfoCardImage,
    StyledVehicleInfoCardContainer,
    StyledVehicleInfoCardActions,
    StyledVehicleInfoCardContent,
    StyledVehicleInfoCardStockVin,
    VehicleInfoCardSeparator,
    StyledVehicleInfoCardBaseActions,
    VehicleInfoCardBaseSeparator,
    StyledVehicleInfoCardStock,
    StyledVehicleInfoCardBaseImage
} from './VehicleInfo.style';

import { useCheckElementsOnSameRow } from '../../utils/useCheckElementsOnSameRow';
import { VehicleImage } from './VehicleImage';
import { DealTypeDropdown } from '../dealTypeDropdown/DealTypeDropdown';
import { useMediaQuery } from 'react-responsive';

export const VehicleInfoCard = () => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();

    const { condition, make, model, stockNumber, trim, vin, year, imageUrl, isCustom } = useSelector(vehicleSelectors.getVehicle);
    const vAutoUrl = useSelector(configSelectors.getVAutoInventoryUrl);
    const isSwitchVehicleEnabled = useSelector(dealerSelectors.isSwitchVehicleEnabled);
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);

    const labelsContainerRef = useRef<HTMLDivElement>(null);
    const stockRef = useRef<HTMLDivElement>(null);
    const vinRef = useRef<HTMLDivElement>(null);
    const customRef = useRef<HTMLDivElement>(null);

    const isFirstDelimiterVisible = useCheckElementsOnSameRow(labelsContainerRef, [stockRef, vin ? vinRef : customRef]);
    const isSecondDelimiterVisible = useCheckElementsOnSameRow(labelsContainerRef, [vin ? vinRef : stockRef, customRef]);
    const isEcommerceApplicable = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);

    const vehicleInfoTitle = `${condition} ${year} ${make} ${model} ${trim}`;
    const stockLabel = `Stock #${stockNumber}`;
    const vinLabel = `VIN #${vin}`;
    const ecommerceLabel = 'Ecommerce';
    const customVehicleLabel = 'Custom Vehicle';
    const imgAlt = `Photo of a ${year} ${make} ${model}`;
    const isMobileScreen = useMediaQuery({ query: `(max-width: ${theme.tokens.BaseScreenMd})` });

    const getCardActions = () => {
        return (
            <>
                {isSwitchVehicleEnabled && (
                    <Action
                        data-testid="btnChangeVehicle"
                        size="sm"
                        onClick={() => {
                            dispatch(navigationActionCreators.navigateToChangeVehicle());
                        }}
                        actionIcon={{
                            icon: <ArrowsRightLeftIcon />,
                            start: true
                        }}
                    >
                        Change Vehicle
                    </Action>
                )}

                {vAutoUrl && (
                    <a href={`${vAutoUrl}&vin=${vin}`} target="_blank" data-testid="vauto-link" rel="noreferrer">
                        <Action
                            size="sm"
                            actionIcon={{
                                icon: <ArrowTopRightOnSquareIcon />,
                                start: true
                            }}
                        >
                            vAuto
                        </Action>
                    </a>
                )}

                {!isBaseDealScreenExperience && (
                    <Popover
                        padding={true}
                        header="Profit Details"
                        data-testid="profit-details-popover"
                        position="bottom"
                        trigger="outsideClick"
                        popoverContent={<ProfitInfo />}
                    >
                        <Action
                            data-testid="profit-details-btn"
                            size="sm"
                            actionIcon={{
                                icon: <ChartPieIcon />,
                                start: true
                            }}
                        >
                            Profit
                        </Action>
                    </Popover>
                )}
            </>
        );
    };

    const Separator = isBaseDealScreenExperience ? VehicleInfoCardBaseSeparator : VehicleInfoCardSeparator;
    const getStock = (dataTestId: string) => {
        return (
            <Typography
                tag="span"
                variant="body-sm"
                data-testid={dataTestId}
                className="vehicle-info-card__stock-label"
                ref={stockRef}
            >
                <span>{stockLabel}</span>
                {(vin || isCustom) && (
                    <Separator
                        isVisible={isBaseDealScreenExperience ? isSecondDelimiterVisible : isFirstDelimiterVisible}
                        theme={theme}
                        className="vehicle-info-card_separator"
                        data-testid="vehicle-info-card_separator"
                        aria-hidden="true"
                    ></Separator>
                )}
            </Typography>
        );
    };

    const getVin = (dataTestId: string, vinLabel: string) => {
        return (
            <Typography tag="span" variant="body-sm" data-testid={dataTestId} className="vehicle-info-card__vin-label" ref={vinRef}>
                {vinLabel}
                {isCustom && (
                    <Separator
                        isVisible={isBaseDealScreenExperience ? isFirstDelimiterVisible : isSecondDelimiterVisible}
                        theme={theme}
                        className="vehicle-info-card_separator"
                        data-testid="vehicle-info-card_separator"
                        aria-hidden="true"
                    ></Separator>
                )}
            </Typography>
        );
    };

    return (
        <StyledVehicleInfoCardContainer data-testid="vehicle-info-card-container">
            <StyledVehicleInfoCard theme={theme} data-testid="vehicle-info-card">
                {isBaseDealScreenExperience && (
                    <StyledVehicleInfoCardBaseImage>
                        <VehicleImage src={imageUrl} alt={imgAlt} />
                    </StyledVehicleInfoCardBaseImage>
                )}
                {!isBaseDealScreenExperience && (
                    <StyledVehicleInfoCardImage>
                        <VehicleImage src={imageUrl} alt={imgAlt} />
                    </StyledVehicleInfoCardImage>
                )}
                <StyledVehicleInfoCardContent>
                    <VehicleInfoDrawer
                        actionTitle={<span title={vehicleInfoTitle}>{vehicleInfoTitle}</span>}
                        actionOverrides={{
                            size: 'lg',
                            actionIcon: undefined,
                            sx: { textAlign: 'left' }
                        }}
                    />

                    <StyledVehicleInfoCardStockVin
                        className="vehicle-info-card__stock-vin-container"
                        theme={theme}
                        ref={labelsContainerRef}
                    >
                        {isBaseDealScreenExperience ? (
                            <>
                                {vin && getVin('vehicle-info-card-vin-base-label', vin)}
                                <StyledVehicleInfoCardStock theme={theme}></StyledVehicleInfoCardStock>
                                {stockNumber && getStock('stock-base')}

                                {isEcommerceApplicable && (
                                    <Flag data-testid="vehicle-info-card-ecommerce-label">
                                        <span>{ecommerceLabel}</span>
                                    </Flag>
                                )}
                            </>
                        ) : (
                            <>
                                {stockNumber && getStock('stock')}
                                {vin && getVin('vehicle-info-card-vin-label', vinLabel)}
                            </>
                        )}

                        {isCustom && (
                            <Typography
                                tag="span"
                                variant="body-sm"
                                data-testid="vehicle-info-card-custom-vehicle-label"
                                className="vehicle-info-card__custom-vehicle-label"
                                ref={customRef}
                                sx={{ marginRight: theme.tokens.BaseSpace100 }}
                            >
                                <span>{customVehicleLabel}</span>
                            </Typography>
                        )}
                    </StyledVehicleInfoCardStockVin>

                    {isBaseDealScreenExperience && (
                        <StyledVehicleInfoCardBaseActions theme={theme}>{getCardActions()}</StyledVehicleInfoCardBaseActions>
                    )}
                </StyledVehicleInfoCardContent>
                {!isMobileScreen && isBaseDealScreenExperience && <DealTypeDropdown />}
            </StyledVehicleInfoCard>
            {isMobileScreen && isBaseDealScreenExperience && <DealTypeDropdown />}
            {!isBaseDealScreenExperience && (
                <StyledVehicleInfoCardActions theme={theme}>{getCardActions()}</StyledVehicleInfoCardActions>
            )}
        </StyledVehicleInfoCardContainer>
    );
};
