import { styled } from 'styled-components';

export const StyledPaymentTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2px;

    &.single-card-view {
        margin: 0;
        margin-top: ${({ theme }) => theme.tokens.ComponentCardPadding};
    }

    &.multi-card-view {
        * {
            color: inherit;
        }
        .no-inherit-cash-down-label {
            text-align: right;
            flex: 1 1 100%;
            color: #5a5a5a;
        }
    }
`;
