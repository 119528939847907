// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { featureToggleSelectors, dealerSelectors, initActionTypes, type ReduxNext } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors } from '../selectors';

// consts/enums
import { UPDATED_CREDIT_DECISION_LENDER, UPDATED_CREDIT_DECISION_STATUS } from '../actionTypes/offerActionTypes';

// actions
import { updateCreditDecisionEnabled, updateCreditDecisionLender, updateCreditDecisionStatus } from '../actions/offerRedux';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const middleware = (store: any) => (next: ReduxNext) => (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        case INIT_PENCIL_SUCCESS:
        case UPDATED_CREDIT_DECISION_LENDER:
        case UPDATED_CREDIT_DECISION_STATUS: {
            const isBaseDealScreenExperience = dealerSelectors.isBaseDealScreenExperience(state);
            const creditDecisionLender = offerReduxSelectors.getCreditDecisionLender(state);
            const creditDecisionStatus = offerReduxSelectors.getCreditDecisionStatus(state);
            const useOfferTypeForCalcEndpointChange = featureToggleSelectors.updateCalcEngineOnDealTypeChange(state);
            const previousCreditDecisionEnabledStatus = offerReduxSelectors.getCreditDecisionEnabled(state);
            const offerType = offerReduxSelectors.getCurrentOfferType(state);

            if (action.type === INIT_PENCIL_SUCCESS && !isBaseDealScreenExperience && !previousCreditDecisionEnabledStatus) {
                store.dispatch(updateCreditDecisionLender());
                store.dispatch(updateCreditDecisionStatus(''));
            }

            if (isBaseDealScreenExperience) {
                const checkLenderStatus = ['Approved', 'ConditionallyApproved', 'Declined'];
                const isChecked = Boolean(creditDecisionLender) && checkLenderStatus.includes(creditDecisionStatus as string);

                if (previousCreditDecisionEnabledStatus !== isChecked) {
                    if (useOfferTypeForCalcEndpointChange) {
                        return store.dispatch(updateCreditDecisionEnabled(isChecked, false, offerType));
                    }
                    store.dispatch(updateCreditDecisionEnabled(isChecked));
                }
            }
            break;
        }
    }
};
