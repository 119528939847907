import { IVehicle } from '@makemydeal/dr-platform-types';
import { CatalogVehicle } from '../types';

export const getFilteredSearchResults = (vin?: string, stockNumber?: string, vehicles?: (IVehicle | CatalogVehicle)[]) => {
    if ((!vin && !stockNumber) || !vehicles) {
        return { filteredSearchResults: vehicles, isFilteredSearchError: false };
    }

    const filteredSearchResults = vehicles.filter((vehicle) => !(vehicle.vin === vin && vehicle.stockNumber === stockNumber));

    return { filteredSearchResults, isFilteredSearchError: vehicles.length !== filteredSearchResults.length };
};
