import { Alert } from '@interstate/components/Alert';
import { AlertSeverity } from '@interstate/components/Alert/Types/alertTypes';
import { Typography } from '@interstate/components/Typography';
import { useSelector } from 'react-redux';

import {
    accessoriesActionTypes,
    configSelectors,
    deskingSelectors,
    offerReduxSelectors,
    paymentSelectors,
    tradeActionTypes
} from '@makemydeal/dr-dash-store';
import { IPaymentTermMessage } from '@makemydeal/dr-platform-shared';
import { IPaymentStatusProps } from './types/paymentStatusProps';
import { isEqual } from 'lodash';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const PaymentStatusInterstate = ({ testId, sx: sxOverrides }: IPaymentStatusProps) => {
    const isPaymentError = useSelector(paymentSelectors.getIsError);
    const paymentMessages = useSelector(offerReduxSelectors.getMessagesFromCurrentTerm, isEqual);
    const paymentHasMessages = Boolean(
        paymentMessages &&
            paymentMessages.length &&
            paymentMessages.some((message: IPaymentTermMessage) => message.messageType?.toLowerCase().includes('error'))
    );
    const failedAllowance = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ALLOWANCE_CHANGED));
    const failedPayoff = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.PAYOFF_CHANGED));
    const failedMenuAccessories = useSelector(
        deskingSelectors.wasTheLastFailure(accessoriesActionTypes.UPDATED_OFFER_MENU_PRODUCTS)
    );
    const psErrorLookupKey = useSelector(deskingSelectors.getPSErrorLookupKey);
    const isFormattedPSErrorsEnabled = useSelector(featureToggleSelectors.isFormattedPSErrorsEnabled);
    const psErrorMessageMapper = useSelector(configSelectors.getPSErrors);

    let messageDisplay = null;
    if (paymentMessages.length === 1) {
        const [{ message }] = paymentMessages;
        messageDisplay = message;
    } else if (paymentMessages.length > 1) {
        messageDisplay = paymentMessages.map(({ message }, index) => (index ? ' ' : '') + message).toString();
    } else if (isPaymentError) {
        if (failedAllowance || failedPayoff) {
            messageDisplay = 'Including trade did not return a valid program.';
        } else if (isFormattedPSErrorsEnabled && psErrorLookupKey && psErrorMessageMapper[psErrorLookupKey]) {
            messageDisplay = psErrorMessageMapper[psErrorLookupKey];
        } else if (failedMenuAccessories) {
            messageDisplay = 'Adjustment did not return a payment.';
        } else {
            messageDisplay = 'Service Unavailable. Please try again.';
        }
    }

    let alertType: AlertSeverity | null = null;

    if (isPaymentError) {
        alertType = 'error';
    } else if (paymentHasMessages) {
        alertType = 'info';
    }

    if (!alertType) {
        return null;
    }

    return (
        <Alert
            data-testid={testId}
            title={paymentMessages.length > 1 ? 'Multiple Errors Found' : ''}
            role="payment-status"
            id="payment-status"
            type={alertType}
            sx={{ mb: 2, ...sxOverrides }}
        >
            <Typography tag="span" variant="body-sm" data-testid="payment-error-message">
                {messageDisplay}
            </Typography>
        </Alert>
    );
};

export default PaymentStatusInterstate;
