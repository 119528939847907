import { useSelector } from 'react-redux';

import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { routingSelectors } from '@makemydeal/dr-dash-store';
import BaseDealFooterInterstate from '../baseDealFooter/Footer.interstate';
import FooterInterstate from './Footer.interstate';
import { FooterPlaceholder, StickyFooter } from './Footer.style.interstate';

export const Footer = () => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const isDashboardCurrentRoute = useSelector(routingSelectors.isDashboardCurrentRoute);
    const useDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);
    const enableDraftScenarioPersistence = useSelector(featureToggleSelectors.enableDraftScenarioPersistence);

    if (!(isABPencilEnabled && isDashboardCurrentRoute)) {
        return null;
    }
    return (
        <>
            {!enableDraftScenarioPersistence && <FooterPlaceholder />}
            <StickyFooter enableDraftScenarioPersistence={enableDraftScenarioPersistence}>
                {useDealScreenExperience ? <BaseDealFooterInterstate /> : <FooterInterstate />}
            </StickyFooter>
        </>
    );
};
