import {
    deskingSelectors,
    tradeActionTypes,
    tradeInSelectors,
    tradeShopperActivity,
    vAutoSelectors
} from '@makemydeal/dr-dash-store';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, usePaymentCall } from '@makemydeal/dr-dash-components';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';
import { handleTabChange } from '../utils';
import { BaseTabs } from '../../baseDealTypes';

const ACV = () => {
    const {
        value: acv,
        handleChange: changeAcv,
        handleBlur: acvBlur
    } = usePaymentCall(tradeInSelectors.getTradeACV, tradeShopperActivity.tradeActionCreators.acvChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const currAppraisal = useSelector(vAutoSelectors.getCurrentAppraisalFromVin);
    const vAutoAppraisalAcv = currAppraisal?.value;
    const vAutoAppraisalStatus = currAppraisal?.status;
    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const failedACV = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ACV_CHANGED));
    const dispatch = useDispatch();

    return (
        <>
            <label
                data-testid="acv-input-label"
                style={{
                    color: '#005BA8',
                    cursor: 'pointer'
                }}
                onClick={() => handleTabChange(BaseTabs.TRADE_IN, dispatch)}
            >
                ACV
            </label>

            <NumericInput
                inputPrefix={'$'}
                autoInsertCommas
                allowDecimal
                decimalMaxLength={2}
                decimalMinLength={2}
                data-testid="acv"
                name={kebabCase('ACV')}
                onChange={changeAcv}
                placeholder="10,000.00"
                value={vAutoAppraisalAcv > 0 ? formatDollarsAndCents(vAutoAppraisalAcv) : acv}
                onBlur={acvBlur}
                hasError={failedACV}
                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                disabled={!isTradeInCompleted || !!vAutoAppraisalStatus}
                displayLabel={false}
            />
        </>
    );
};

export default ACV;
