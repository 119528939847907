import type { StateTree } from '@makemydeal/dr-dash-types';
import { RollToBasicObject, RollToErrors, RollToItems } from '../types/rollTo';

/* istanbul ignore next */
export const getRollToPayments = (state: StateTree): RollToBasicObject => state?.rollToPayments || {};
/* istanbul ignore next */
export const hasRollToPayments = (state: StateTree): boolean => {
    const rollToPayments = getRollToPayments(state);
    return !!rollToPayments;
};
/* istanbul ignore next */
export const getRollProgramItems = (state: StateTree): RollToItems => {
    const rollToPayments = getRollToPayments(state);
    return rollToPayments?.rollProgramItems || [];
};
/* istanbul ignore next */
export const isRollToCallPending = (state: StateTree): boolean => {
    const rollToPayments = getRollToPayments(state);
    return rollToPayments?.isRollToCallPending || false;
};

// export const rollToPaymentsMessages = (state: StateTree) => {
//     // TODO: fix return type

//     const rollToPayments = getRollToPayments(state);
//     return rollToPayments?.messages || [];
// };
/* istanbul ignore next */
export const rollToPaymentsErrors = (state: StateTree): RollToErrors => {
    const rollToPayments = getRollToPayments(state);
    return rollToPayments?.errors || [];
};
/* istanbul ignore next */
export const sellingPriceItem = (state: StateTree) => {
    // TODO: fix return type

    const rollToProgramItems = getRollProgramItems(state);
    // TODO: fix any type
    return rollToProgramItems.find((item: any) => item?.rollValueType === 'SellingPrice');
};
/* istanbul ignore next */
export const sellingPrice = (state: StateTree): number | undefined => {
    const selectedItem = sellingPriceItem(state);
    return selectedItem?.rollValue;
};
/* istanbul ignore next */
export const downPaymentItem = (state: StateTree) => {
    // TODO: fix return type

    const rollToProgramItems = getRollProgramItems(state);
    // TODO: fix any type
    return rollToProgramItems.find((item: any) => item?.rollValueType === 'DownPayment');
};

export const downPayment = (state: StateTree): number | undefined => {
    const downPayment = downPaymentItem(state)?.rollValue;
    return downPayment != null ? Math.abs(downPayment) : undefined;
};
/* istanbul ignore next */
export const monthlyPayment = (state: StateTree): number | undefined => {
    // uses the downPaymentNode to get the monthly payment
    const downPaymentNode = downPaymentItem(state);
    const financeSummary = downPaymentNode?.rollProgram?.financeSummary;

    return financeSummary?.monthlyPayment;
};
/* istanbul ignore next */
export const termItem = (state: StateTree) => {
    // TODO: fix return type
    const rollToProgramItems = getRollProgramItems(state);
    // TODO: fix any type
    return rollToProgramItems.find((item: any) => item?.rollValueType === 'Term');
};
/* istanbul ignore next */
export const term = (state: StateTree): number | undefined => {
    const selectedItem = termItem(state);
    return selectedItem?.rollValue;
};
/* istanbul ignore next */
export const rateItem = (state: StateTree) => {
    const rollToProgramItems = getRollProgramItems(state);
    // TODO: fix any type
    return rollToProgramItems.find((item: any) => item?.rollValueType === 'Rate');
};
/* istanbul ignore next */
export const rateType = (state: StateTree): string | undefined => {
    const selectedItem = rateItem(state);
    const financeSummary = selectedItem?.rollProgram?.financeSummary;
    return financeSummary?.rateType;
};
/* istanbul ignore next */
export const rate = (state: StateTree): number | undefined => {
    const selectedItem = rateItem(state);
    return selectedItem?.rollValue;
};

/* istanbul ignore next */
export const tradeItem = (state: StateTree) => {
    // TODO: fix return type
    const rollToProgramItems = getRollProgramItems(state);
    // TODO: fix any type
    return rollToProgramItems.find((item: any) => item?.rollValueType === 'Trade');
};
/* istanbul ignore next */
export const trade = (state: StateTree): number | undefined => {
    const selectedItem = tradeItem(state)?.rollValue;
    return selectedItem != null ? Math.abs(selectedItem) : undefined;
};
