import styled from 'styled-components';

export const FullScreenLoader = styled.div`
    & #interstitial-overlay-root {
        background-color: rgba(255, 255, 255, 0.9);
    }
    & #interstitial-content-root {
        box-shadow: none;
    }
`;
