// externals
import type { Reducer } from 'redux';
import { BaseTabs } from '../actionTypes/baseDealTypes';

type BaseDealState = {
    activeTab: string;
};

export const BASE_DEAL_INITIAL_STATE: BaseDealState = {
    activeTab: BaseTabs.SUMMARY
};
const TAB_CHANGE = 'TAB_CHANGE';

export type BaseDealReducer = Reducer<BaseDealState>;

export const reducer: Reducer<BaseDealState> = (state: BaseDealState = BASE_DEAL_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case TAB_CHANGE:
            return {
                activeTab: action.payload.activeTab
            };
        default:
            return state;
    }
};
