//external
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ProductSummaryWrapper = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 32px;

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            padding-right: ${rem(10)};
            padding-left: ${rem(10)};
            margin-top: ${rem(24)};

            @media (max-width: ${BreakPoint.SMALL}) {
                align-items: stretch;
            }

            .product-amount {
                font-weight: 700;
            }
        `}
`;

export const ProductSummaryLine = styled.div`
    display: flex;
`;

export const ProductSummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;

    @media (max-width: ${BreakPoint.SMALL}) {
        width: 100%;
    }
`;
