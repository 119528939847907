// externals
import { Reducer } from 'redux';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { DeskingState } from '@makemydeal/dr-dash-types';
import { actions as offerActions } from '@makemydeal/dr-offer-redux';

// consts/enums
import {
    HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT,
    PAYMENT_FAILURE,
    PAYMENT_RECEIVED,
    PAYMENT_REQUESTED,
    SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT
} from '../actionTypes/deskingActionTypes';
import { getPSErrorLookupKey } from '../utils/psErrorUtils';

export type DeskingReducer = Reducer<DeskingState>;

const initialState: DeskingState = {
    lastFailureAction: null,
    showTotalTaxRateSupersedeAlert: null,
    psErrorLookupKey: null
};

export const reducer: DeskingReducer = (state = initialState, action: AnyFSA) => {
    switch (action.type) {
        case PAYMENT_REQUESTED:
            return { ...state, lastFailureAction: null };
        case PAYMENT_RECEIVED:
            if (action.meta == null) return state;
            if (action.meta.originalAction == null) return state;
            if (action.meta.offerType == null) return state;
            if (action.payload[action.meta.offerType]?.success !== true) {
                if (action.meta.originalAction.meta?.paymentGrid?.mainPaymentActionType) {
                    return { ...state, lastFailureAction: action.meta.originalAction.meta.paymentGrid.mainPaymentActionType };
                }
                return { ...state, lastFailureAction: action.meta.originalAction.type };
            }

            return state;
        case PAYMENT_FAILURE:
            if (action.meta == null) return state;
            if (action.meta.originalAction == null) return state;

            if (action.meta.originalAction.meta?.paymentGrid?.mainPaymentActionType) {
                return {
                    ...state,
                    lastFailureAction: action.meta.originalAction.meta.paymentGrid.mainPaymentActionType,
                    psErrorLookupKey: getPSErrorLookupKey(action.meta)
                };
            }

            return {
                ...state,
                lastFailureAction: action.meta.originalAction.type,
                psErrorLookupKey: getPSErrorLookupKey(action.meta)
            };

        case SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT: {
            return { ...state, showTotalTaxRateSupersedeAlert: true };
        }

        case offerActions.UPDATED_OFFER_TYPE:
        case HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT: {
            return { ...state, showTotalTaxRateSupersedeAlert: false };
        }
    }
    return state;
};
