// externals
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';

// selectors
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { scopedStateSelectors, scopedActionsCreators } from '@makemydeal/shared-scoped-store';
import { offerReduxSelectors, paymentSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';

// components
import { PaymentStatus } from '@makemydeal/dr-dash-components';
import { Action } from '@interstate/components/Action';
import { DocumentDuplicateIcon, TrashIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { DeskingAlerts } from '../DeskingAlerts';
import { VehicleInfoCard } from '../vehicleInfo/VehicleInfoCard';
import { DeskingCardHeaderWrapper, DeskingCardHeaderActions, DeskingCardHeaderActionsMenu } from './DeskingCard.style';
import { DeskingCardDealLabel } from './DeskingCardDealLabel';

const DRAFT_DEAL_LIMIT = 3;

export const DeskingCardHeader = memo(({ scopeId }: { scopeId: string }) => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled) as boolean;
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);
    const primaryScopeId = useSelector(scopedStateSelectors.getPrimaryScopeId);
    const scopesOrder = useSelector(scopedStateSelectors.getInitializedScopesOrder);
    const sessionId = useSelector(offerReduxSelectors.getSessionId);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealXgVersion = useSelector(offerReduxSelectors.getDealXgVersion);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const vin = useSelector(vehicleSelectors.getVehicleVin);

    const isPrimaryScope = primaryScopeId === scopeId;

    const isDuplicateDealAllowed = scopesOrder.length < DRAFT_DEAL_LIMIT && !isCreditDecisionEnabled && !isPaymentCalculating;

    const logNRAction = useCallback(
        (name: string) => {
            logNewRelicPageAction(name, {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin
            });
        },
        [dealXgId, dealXgVersion, dealerId, sessionId, vin]
    );

    const deleteScope = useCallback(() => {
        dispatch(scopedActionsCreators.deleteScope(scopeId));
        logNRAction('MV:Multiscenario - Delete deal');
    }, [dispatch, scopeId, logNRAction]);

    const handleDuplicate = useCallback(() => {
        dispatch(scopedActionsCreators.cloneScope(scopeId));
        logNRAction('MV:Multiscenario - Duplicate deal');
    }, [dispatch, scopeId, logNRAction]);

    const handlePrimaryDealChange = useCallback(() => {
        dispatch(scopedActionsCreators.changeDefaultScope(scopeId));
        logNRAction('MV:Multiscenario - Change primary deal');
    }, [dispatch, scopeId, logNRAction]);

    return (
        <DeskingCardHeaderWrapper>
            <DeskingCardHeaderActions theme={theme}>
                <DeskingCardDealLabel
                    isPrimaryScope={isPrimaryScope}
                    scopeId={scopeId}
                    onChangePrimaryDeal={handlePrimaryDealChange}
                ></DeskingCardDealLabel>
                <DeskingCardHeaderActionsMenu>
                    <Action
                        actionIcon={{ icon: <TrashIcon />, start: true }}
                        id="delete-deal-draft-button"
                        data-testid="delete-deal-draft-button"
                        onClick={deleteScope}
                        size="lg"
                        disabled={isPrimaryScope}
                    />
                    <Action
                        actionIcon={{ icon: <DocumentDuplicateIcon />, start: true }}
                        id="duplicate-deal-draft-button"
                        data-testid="duplicate-deal-draft-button"
                        onClick={handleDuplicate}
                        size="lg"
                        disabled={!isDuplicateDealAllowed}
                    />
                </DeskingCardHeaderActionsMenu>
            </DeskingCardHeaderActions>

            <VehicleInfoCard />

            <PaymentStatus sx={{ marginBottom: 0 }} />
            <DeskingAlerts />
        </DeskingCardHeaderWrapper>
    );
});
