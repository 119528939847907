import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

interface DealSummaryContainer {
    $isBaseDealScreenExperience?: boolean;
}

export const HeaderRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

export const DealDetailsHeaderWrapper = styled.div`
    margin: 16px 0;
`;

// Styled Components ignores props prefixed with $ when rendering the DOM. Prevents unnecessary attributes from appearing in the HTML output.
export const DealSummaryContainer = styled(Grid)<DealSummaryContainer>`
    ${({ $isBaseDealScreenExperience }) => {
        if ($isBaseDealScreenExperience) {
            return `
                .ids-list-items-container > div {
                    margin-bottom: 0px !important;
                    &:last-child > div {
                        border-bottom: none !important; /* Remove border for last item */
                    }
                }
                 div.ids-list-item-content {
                    border-style: none;
                    border-bottom: 1px solid #b2b2b2 !important;
                    background-color: #f4f4f4;
                    padding: 0 0 0 16px;
                }
                background-color: #f4f4f4;
                padding: 16px !important;
                border-radius: 5px;
            `;
        }
        return `
            div.ids-item-content {
                padding: 0;
            }

            div.ids-list-item-content {
                padding: 0;
            }

            .empty-item > div.ids-item-content {
                background-color: #efefef;
            }

            @media (min-width: ${BreakPoint.TABLET_PORTRAIT}) {
                margin-bottom: 16px;
            }
        `;
    }}
`;
