// externals
import { RSAA } from 'redux-api-middleware';
import { RSAACallAction } from '@makemydeal/dr-react-18-wrappers';

// libraries
import { paymentPayloadUtils } from '@makemydeal/dr-offer-redux';
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// actions
import {
    PAYMENT_FEES_TYPES_REQUEST,
    PAYMENT_FEES_TYPES_SUCCESS,
    PAYMENT_FEES_TYPES_FAILURE
} from '../actionTypes/offerActionTypes';

// consts/enums
import { APPLICATION_NAME } from '../constants';

// utils
import { getCurrentOfferType, getSelectedTerm } from '../selectors/offerRedux';

// types
import type { StateTree } from '@makemydeal/dr-dash-types';
import { HostSelectors } from '@makemydeal/dr-offer-redux/dist/paymentTypes';
import { Dispatch } from 'redux';

export const getPaymentFeesTypes =
    (): any =>
    (dispatch: Dispatch<any, any>, getState: () => StateTree): void => {
        const state = getState();
        const currentOfferType = getCurrentOfferType(state);
        const currentSelectedProgram = getSelectedTerm(state, currentOfferType);
        const hostSelectors = {
            isManagerView: true,
            isBaseDealScreenExperience: dealerSelectors.isBaseDealScreenExperience
        } as HostSelectors;

        const payload = paymentPayloadUtils.createPaymentPayload(state, hostSelectors, {
            type: PAYMENT_FEES_TYPES_REQUEST,
            meta: { sourceApplication: APPLICATION_NAME }
        });

        const locHref = locHrefUtil.getLocHref();
        const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'feesTypes');
        const endpoint = bffApiUrlResult.url;

        dispatch<RSAACallAction>({
            [RSAA]: {
                endpoint,
                method: 'POST',
                body: JSON.stringify({
                    ...payload,
                    preferences: {
                        zipCode: payload.shopper?.zip ?? '',
                        dealerId: payload.dealerId
                    }
                }),
                headers: { 'Content-Type': 'application/json' },
                types: [
                    PAYMENT_FEES_TYPES_REQUEST,
                    { type: PAYMENT_FEES_TYPES_SUCCESS, meta: { currentOfferType, currentSelectedProgram } },
                    PAYMENT_FEES_TYPES_FAILURE
                ]
            }
        });
    };
