// externals
import React from 'react';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { DealProductSummary } from '@makemydeal/dr-dash-types';

// styling
import { ProductSummaryWrapper, ProductSummaryLine, ProductSummaryRow } from './ProductSummary.style';

type ProductSummaryProps = {
    summary: DealProductSummary;
    isFinanceOrLease: boolean;
    isBaseDealScreenExperience?: boolean;
};

const ProductSummary = ({ summary, isFinanceOrLease, isBaseDealScreenExperience = false }: ProductSummaryProps) => {
    const { totalProductCost, totalProductMarkup, totalProductPrice, productMonthlyPayment } = summary;

    if (isBaseDealScreenExperience) {
        return (
            <ProductSummaryWrapper data-testid="product-summary-container" isBaseDealScreenExperience={isBaseDealScreenExperience}>
                <ProductSummaryLine>
                    <ProductSummaryRow>
                        <span data-testid="product-summary-total-cost">Total Cost</span>
                        <span data-testid="product-summary-total-cost-value" className="product-amount">
                            {formatDollarsAndCents(totalProductCost)}
                        </span>
                    </ProductSummaryRow>
                </ProductSummaryLine>
                <ProductSummaryLine>
                    <ProductSummaryRow>
                        <span data-testid="product-summary-total-markup">Total Markup</span>
                        <span className="product-amount">{formatDollarsAndCents(totalProductMarkup)}</span>
                    </ProductSummaryRow>
                </ProductSummaryLine>
                <ProductSummaryLine>
                    <ProductSummaryRow>
                        <span data-testid="product-summary-total-price">Total Price</span>
                        <span className="product-amount">{formatDollarsAndCents(totalProductPrice)}</span>
                    </ProductSummaryRow>
                </ProductSummaryLine>
                {isFinanceOrLease && (
                    <ProductSummaryLine>
                        <ProductSummaryRow>
                            <span data-testid="product-summary-monthly-payment">Monthly Payment</span>
                            <span className="product-amount">{formatDollarsAndCents(productMonthlyPayment)}</span>
                        </ProductSummaryRow>
                    </ProductSummaryLine>
                )}
            </ProductSummaryWrapper>
        );
    }

    return (
        <ProductSummaryWrapper data-testid="product-summary-container" isBaseDealScreenExperience={isBaseDealScreenExperience}>
            <ProductSummaryLine>
                <span data-testid="product-summary-total-cost">Total Cost: {formatDollarsAndCents(totalProductCost)}</span>
            </ProductSummaryLine>
            <ProductSummaryLine>
                <span data-testid="product-summary-total-markup">Total Markup: {formatDollarsAndCents(totalProductMarkup)}</span>
            </ProductSummaryLine>
            <ProductSummaryLine>
                <span data-testid="product-summary-total-price">Total Price: {formatDollarsAndCents(totalProductPrice)}</span>
            </ProductSummaryLine>
            {isFinanceOrLease && (
                <ProductSummaryLine>
                    <span data-testid="product-summary-monthly-payment">
                        Monthly Payment: {formatDollarsAndCents(productMonthlyPayment)}
                    </span>
                </ProductSummaryLine>
            )}
        </ProductSummaryWrapper>
    );
};

export default ProductSummary;
