import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import { Modal } from '@interstate/components/Modal';
import { Button } from '@interstate/components/Button';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { offerActionCreators, offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';
import { UpdateOfferDialogProps } from './UpdateOfferDialogProps';
import { UpdateOfferDialogMode } from './UpdateOfferDialogMode';
import { ErrorModalDialogContent } from '../common/ErrorModalDialogContent';
import { LoaderWrapper, UpdateOfferErrorFooter } from './UpdateOfferDialog.style';

export const UpdateOfferDialog = ({ show, onHide, onSuccess, waitForWS }: UpdateOfferDialogProps) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState(UpdateOfferDialogMode.SAVING);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    // Set mode when offer save status changes
    React.useEffect(() => {
        if (show) {
            if (offerIsBeingSaved === true) {
                return setMode(UpdateOfferDialogMode.SAVING);
            }

            if (offerHasBeenSaved && !offerIsBeingSaved && !waitForWS) {
                setMode(UpdateOfferDialogMode.SUCCESS);
                if (onSuccess) {
                    onSuccess();
                }
                return;
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === false) {
                setMode(UpdateOfferDialogMode.ERROR);
                return;
            }
        }
    }, [offerIsBeingSaved, offerHasBeenSaved, waitForWS]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleRetryClick = () => {
        dispatch(offerActionCreators.saveOffer());
    };
    const header = <Typography variant="h4">Update Offer</Typography>;
    switch (mode) {
        case UpdateOfferDialogMode.SAVING:
            return (
                <Modal
                    size="small"
                    data-testid="update-offer-dialog-saving"
                    show={show}
                    header={header}
                    onHide={onHide}
                    sx={{
                        '#ids-modal-fixed-footer-component': {
                            padding: '20px 24px 24px'
                        }
                    }}
                    footer={{
                        footerComponent: (
                            <LoaderWrapper>
                                <LoadingIndicator size="small" data-testid="update-offer-loading-indicator" />
                            </LoaderWrapper>
                        )
                    }}
                >
                    <Typography variant="body-md" tag="p">
                        We're updating your changes to this offer.
                    </Typography>
                </Modal>
            );
        case UpdateOfferDialogMode.ERROR:
            return (
                <Modal
                    size="small"
                    data-testid="update-offer-dialog-error"
                    show={show}
                    header={header}
                    onHide={onHide}
                    footer={{
                        footerComponent: (
                            <UpdateOfferErrorFooter>
                                <Button
                                    data-testid="update-offer-retry-button"
                                    onClick={handleRetryClick}
                                    size="small"
                                    buttonStyle="primary"
                                >
                                    Retry
                                </Button>
                            </UpdateOfferErrorFooter>
                        )
                    }}
                >
                    <ErrorModalDialogContent
                        alertString=" The changes you made to this offer failed to update. Please try again."
                        contentString="We ran into a problem updating this offer so the information wasn't shared. Please try again."
                    />
                </Modal>
            );
        default: {
            return null;
        }
    }
};
