import { LEASE, OfferType, Rate, RatedProtectionProduct } from '@makemydeal/dr-platform-types';
import { SurchargeOverrides, DealProduct } from '@makemydeal/dr-shared-types';
import {
    DraftDealProduct,
    DealProductSummary,
    StateTree,
    PaymentProduct,
    RequestedSurcharges,
    DealProductWithProductCode,
    VppProductAttributes,
    DealerProduct,
    ProductPlanDetails
} from '@makemydeal/dr-dash-types';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    calculateVehicleProtectionSummary,
    getPlansFromProductsRates,
    getSelectedRate,
    isDealProduct,
    isVppCapProduct,
    isVppProductSelected,
    isVppUpfrontProduct,
    mapAmdToVppVehicleCondition,
    mapOfferTypeToVppDealType
} from '../utils/vehicleProtectionUtils';
import { getCurrentOfferType } from './offerRedux';
import * as offerSelectors from './offerSelectors';
import * as vehicleSelectors from './vehicleSelectors';
import * as paymentSelectors from './payment';

export const getVppProducts = (state: StateTree): DraftDealProduct[] => {
    return state.vehicleProtectionV2?.products || [];
};

export const getDealProducts = (state: StateTree): DealProduct[] => {
    const products = getVppProducts(state);

    return products.filter(isDealProduct);
};

export const getSelectedDealProducts = (state: StateTree): DealProduct[] => {
    const products = getDealProducts(state);

    return products.filter((product) => product.selected);
};

export const getUnselectedDealProducts = (state: StateTree): DealProduct[] => {
    const products = getDealProducts(state);
    const dealXgProductCodes = state.vehicleProtectionV2?.dealXgProducts.map((product) => product.productCode) || [];

    return products.filter((product) => !product.selected && dealXgProductCodes.includes(product.productCode));
};

export const isFetchingVppProducts = (state: StateTree): boolean => {
    return !!state.vehicleProtectionV2?.isFetchingProducts;
};

export const isFetchingVppRates = (state: StateTree): boolean => {
    return !!state.vehicleProtectionV2?.isFetchingRates;
};

export const isVppRatingFailed = (state: StateTree): boolean => {
    return !!state.vehicleProtectionV2?.isRatingFailed;
};

export const isVppRatingTimeout = (state: StateTree): boolean => {
    return !!state.vehicleProtectionV2?.isRatingTimeout;
};

export const isVppRatingRetried = (state: StateTree): boolean => {
    return !!state.vehicleProtectionV2?.isFetchRatesRetried;
};

export const hasSelectedProductsWithInapplicableRateAttributes = (state: StateTree): boolean => {
    const products = getVppSelectedProducts(state);
    const isPlanSelectionEnabled = featureToggleSelectors.isPlanSelectionForVppEnabled(state);

    return products.some((product) => {
        const ratedProduct = getVppRatedProduct(state, product.productCode);
        const hasSelectedRate = getSelectedRate(ratedProduct, product, isPlanSelectionEnabled) !== undefined;

        return ratedProduct && !hasSelectedRate;
    });
};

export const getVppRateToken = (state: StateTree): string => {
    return state.vehicleProtectionV2?.rateToken ?? '';
};

export const getVppSelectedProducts = (state: StateTree): DraftDealProduct[] => {
    return getVppProducts(state).filter(isVppProductSelected);
};

export const getVppSelectedProductCodes = (state: StateTree): string[] => {
    return getVppSelectedProducts(state)
        .map((product) => product.productCode)
        .filter((productCode): productCode is string => typeof productCode === 'string');
};

export const getVppProductsEligibleForSelection = (state: StateTree): DraftDealProduct[] => {
    const products = getVppProducts(state);
    const productsAttributes = getVppProductAttributes(state);

    return products.filter(({ productCode, selected }) => {
        if (!productCode) {
            return false;
        }

        const productAttributes = productsAttributes[productCode];

        if (selected) {
            return !productAttributes?.unselectDisabled;
        } else {
            return !productAttributes?.selectDisabled;
        }
    });
};
export const getVppUnselectedProducts = (state: StateTree): DraftDealProduct[] => {
    return getVppProducts(state).filter((product) => !product.selected);
};

export const getVppSelectedProductsCount = (state: StateTree): number => {
    return getVppSelectedProducts(state).length;
};

export const getVppProduct = (state: StateTree, productCode: string | undefined): DraftDealProduct | undefined => {
    const product = state.vehicleProtectionV2?.products?.find((product) => product.productCode === productCode);

    return product;
};

export const getVppInitialDealXgProduct = (state: StateTree, productCode: string | undefined): DraftDealProduct | undefined => {
    const product = state.vehicleProtectionV2?.dealXgProducts.find((product) => product.productCode === productCode);

    return product;
};

export const getVppDealerProduct = (state: StateTree, productCode: string | undefined): Partial<DealerProduct> | undefined => {
    const product = state.vehicleProtectionV2?.dealerProducts.find((product) => product.productCode === productCode);

    return product;
};

export const getVppDealerProducts = (state: StateTree): Partial<DealerProduct>[] => {
    const products = state.vehicleProtectionV2?.dealerProducts || [];

    return products;
};

export const getVppSurchargesOverrides = (state: StateTree): SurchargeOverrides => {
    const offerDetails = offerSelectors.getOfferDetailsForMenu(state);
    const vehicleDetails = vehicleSelectors.getVehicle(state);

    return {
        ...state.vehicleProtectionV2?.surcharges,
        vin: vehicleDetails.vin,
        dealType: mapOfferTypeToVppDealType(offerDetails.offerType as OfferType),
        inventoryType: mapAmdToVppVehicleCondition(vehicleDetails.condition),
        annualMiles: offerDetails.annualMiles,
        amountFinanced: offerDetails.totalFinanced,
        apr: offerDetails.apr,
        monthlyPayment: offerDetails.monthlyPayment,
        sellingPrice: offerDetails.offerPrice,
        term: offerDetails.term,
        odometer: vehicleDetails.mileage,
        retailPrice: vehicleDetails.retailPrice,
        color: vehicleDetails.exteriorColor,
        year: vehicleDetails.year,
        make: vehicleDetails.make,
        model: vehicleDetails.model
    };
};

export const getVppRequestedSurcharges = (state: StateTree): RequestedSurcharges => {
    return (
        state.vehicleProtectionV2?.requestedSurcharges || {
            usedSurchargesAttributes: [],
            requiredSurchargesAttributes: []
        }
    );
};

export const isPaymentFailed = (state: StateTree): boolean => {
    return state.vehicleProtectionV2?.isPaymentFailed || false;
};

export const getVppSummary = (state: StateTree): DealProductSummary => {
    const products = getVppProducts(state);
    const term = offerSelectors.getSelectedTermMonths(state);
    const calculatedSummary = calculateVehicleProtectionSummary({ products, term });
    const areFeatureTogglesAvailable = featureToggleSelectors.areFeatureTogglesAvailable(state);

    // NOTE: if toggle is turned on - use PO monthly payment of each individual product whatever it's possible
    if (areFeatureTogglesAvailable && featureToggleSelectors.usePaymentProductsMonthlyPayment(state)) {
        const isCalculatingPayment = paymentSelectors.isCalculatingPayment(state);

        if (!isPaymentFailed(state) && !isCalculatingPayment) {
            calculatedSummary.productMonthlyPayment = getVppSelectedProducts(state).reduce((totalMonthlyPayment, product) => {
                const defaultMonthlyPayment = product.productPrice && term ? product.productPrice / term : 0;
                const productMonthlyPayment = product.productMonthlyPayment ?? defaultMonthlyPayment;

                return totalMonthlyPayment + productMonthlyPayment;
            }, 0);
        }
    }

    return calculatedSummary;
};

export const getVppRatedProduct = (state: StateTree, productCode: string | undefined): RatedProtectionProduct | undefined => {
    const ratedProduct = state.vehicleProtectionV2?.rates?.find((rate) => rate.productCode === productCode);

    return ratedProduct;
};

export const getRatedProductsByCategoryCodeAndProvider = (
    state: StateTree,
    productCategoryCode: string,
    providerCode: string
): RatedProtectionProduct[] =>
    state.vehicleProtectionV2?.rates?.filter((rate) => {
        return rate.productCategoryCode === productCategoryCode && rate.providerCode === providerCode;
    }) || [];

export const getRatedProductsWithSameCategoryAndProvider = (
    state: StateTree,
    productCode: string | undefined
): RatedProtectionProduct[] => {
    const product = getVppRatedProduct(state, productCode);
    if (!product) {
        return [];
    }

    const { productCategoryCode, providerCode } = product;
    const matchingRatedProducts = getRatedProductsByCategoryCodeAndProvider(state, productCategoryCode, providerCode);

    return matchingRatedProducts;
};

export const getPlansForProductsWithSameCategoryAndProvider = (
    state: StateTree,
    productCode: string | undefined
): ProductPlanDetails[] => {
    const ratedProducts = getRatedProductsWithSameCategoryAndProvider(state, productCode);

    return ratedProducts.reduce((plansForAllProducts, ratedProduct) => {
        const productPlans = getPlansFromProductsRates(ratedProduct.rates).map((plan) => ({
            productProviderPlan: plan,
            productCode: ratedProduct.productCode,
            productName: ratedProduct.productName
        }));

        return plansForAllProducts.concat(productPlans);
    }, [] as ProductPlanDetails[]);
};

export const getVppProductRates = (state: StateTree, productCode: string | undefined): Rate[] | undefined => {
    const ratedProduct = getVppRatedProduct(state, productCode);

    return ratedProduct?.rates;
};

export const getVppProductRateAttributes = (
    state: StateTree,
    productCode: string | undefined
): RatedProtectionProduct['rateAttributes'] => {
    const ratedProduct = getVppRatedProduct(state, productCode);

    return ratedProduct?.rateAttributes || [];
};

export const isNonRateableProduct = (state: StateTree, productCode: string | undefined): boolean => {
    const ratedProduct = getVppRatedProduct(state, productCode);

    return !ratedProduct || ratedProduct.rateAttributes.length === 0;
};

export const hasMissingRequiredAttributes = (state: StateTree): boolean => {
    const dealerProducts = getVppDealerProducts(state);
    let hasMissingAttributes = false;
    for (const product of dealerProducts) {
        if (product.rateRequirements?.hasMissingField) {
            hasMissingAttributes = true;
            break;
        }
    }
    return hasMissingAttributes;
};

export const getFailedPaymentProductCodes = (state: StateTree): string[] | undefined => {
    const lastSuccessfulPaymentProductCodes = state.vehicleProtectionV2?.lastSuccessfulPaymentProductCodes ?? [];
    return state.vehicleProtectionV2?.paymentProductCodes.filter(
        (productCode) => !lastSuccessfulPaymentProductCodes.includes(productCode)
    );
};

export const getLastSuccessfulPaymentProducts = (state: StateTree): DraftDealProduct[] => {
    const lastSuccessfulPaymentProductCodes = state.vehicleProtectionV2?.lastSuccessfulPaymentProductCodes ?? [];

    return getVppProducts(state).filter(
        (product) => product.productCode && lastSuccessfulPaymentProductCodes.includes(product.productCode)
    );
};

export const getVppProductAttributes = (state: StateTree): Record<string, VppProductAttributes> => {
    const products = getVppProducts(state) as DealProductWithProductCode[];
    const isFetchingRates = isFetchingVppRates(state);
    const isFetchingProducts = isFetchingVppProducts(state);
    const paymentFailed = isPaymentFailed(state);
    const failedProductCodes = getFailedPaymentProductCodes(state);
    const isPlanSelectionEnabled = featureToggleSelectors.isPlanSelectionForVppEnabled(state);

    return products.reduce((attributesMap, product) => {
        const productAttributes: VppProductAttributes = {};

        const initialDealXgProduct = getVppInitialDealXgProduct(state, product.productCode);
        const dealerProduct = getVppDealerProduct(state, product.productCode);
        const productRates = getVppProductRates(state, product.productCode);
        const rateAttributes = getVppProductRateAttributes(state, product.productCode);

        const selectedRate = getSelectedRate({ rates: productRates, rateAttributes }, product, isPlanSelectionEnabled);
        const productCausedPaymentError = !!failedProductCodes?.includes(product.productCode);
        const hasMissingAttributes = dealerProduct && dealerProduct.rateRequirements?.hasMissingField;

        const hasInapplicableRateAttributes = initialDealXgProduct && product.selected && productRates && !selectedRate;
        const hasInapplicablePlan =
            isPlanSelectionEnabled &&
            hasInapplicableRateAttributes &&
            rateAttributes.includes('productProviderPlan') &&
            productRates.every(({ productProviderPlan }) => productProviderPlan !== product.productProviderPlan);

        const productCostChanged =
            initialDealXgProduct &&
            selectedRate &&
            product.selected &&
            Number.isFinite(product.productCost) &&
            Number.isFinite(selectedRate.productCost) &&
            product.productCost !== selectedRate.productCost;

        if (isFetchingProducts || isFetchingRates) {
            productAttributes.editingDisabled = true;
        }

        if (initialDealXgProduct && !dealerProduct) {
            productAttributes.editingDisabled = true;
            if (!isFetchingProducts) {
                productAttributes.productDisappeared = true;
            }
        } else if (hasMissingAttributes) {
            productAttributes.missingRequiredAttributes = true;
            productAttributes.editingDisabled = true;
            productAttributes.selectDisabled = true;
            productAttributes.unselectDisabled = true;
        } else if (productRates === undefined) {
            productAttributes.editingDisabled = true;
            productAttributes.selectDisabled = true;
            if (!isFetchingRates) {
                productAttributes.productWithoutRates = true;
            }
        } else if (!initialDealXgProduct && dealerProduct) {
            productAttributes.newProductFromFie = true;
        } else if (productCostChanged) {
            productAttributes.selectedProductCostChanged = true;
        }
        if (paymentFailed && productCausedPaymentError) {
            productAttributes.paymentFailed = true;
        }
        if (hasInapplicableRateAttributes) {
            productAttributes.hasInapplicableRateAttribute = true;
        }
        if (hasInapplicablePlan) {
            productAttributes.hasInapplicablePlan = true;
        }

        attributesMap[product.productCode] = productAttributes;
        return attributesMap;
    }, {} as Record<string, VppProductAttributes>);
};

export const getVppPaymentProducts = (state: StateTree): PaymentProduct[] => {
    const isLeaseDeal = getCurrentOfferType(state) === LEASE;

    return getVppSelectedProducts(state)
        .filter((product) => Boolean(product.productCategoryCode))
        .map((product) => ({
            categoryCode: product.productCategoryCode!,
            id: product.productCode,
            price: product.productPrice ?? 0,
            ...(Number.isFinite(product.productCost) && { cost: product.productCost }),
            ...(product.profitLocation && { backEndIndicator: product.profitLocation === 'Back' }),
            ...(isLeaseDeal && {
                residualType: product.productResidualType,
                residualValue: product.productResidualValue,
                priceCapitalizedIndicator: product.isProductPriceCapitalized ?? true
            })
        }));
};

export const getVppEditableProducts = (state: StateTree) => {
    return getVppProducts(state).filter((product) => {
        const productAttributes = getVppProductAttributes(state);
        const isEditable = product.productCode && !productAttributes[product.productCode]?.editingDisabled;

        return isEditable;
    });
};

export const getVppCategoryProviders = (state: StateTree, productCategoryCode: string): string[] => {
    const products = getVppEditableProducts(state);

    return products
        .filter((product) => product.productCategoryCode === productCategoryCode)
        .reduce<string[]>((providers, product) => {
            const productProviderName = product.productProviderName;

            if (productProviderName && !providers.includes(productProviderName)) {
                providers.push(productProviderName);
            }

            return providers;
        }, []);
};

export const getCapVppTotal = (state: StateTree): number => {
    return getSelectedCapVppProducts(state).reduce((total, product) => {
        const productPrice = product.productPrice ?? 0;

        return total + productPrice;
    }, 0);
};
export const getSelectedUpfrontVppProducts = (state: StateTree): DraftDealProduct[] => {
    return getVppSelectedProducts(state).filter(isVppUpfrontProduct);
};

export const getUpfrontVppTotal = (state: StateTree): number => {
    return getSelectedUpfrontVppProducts(state).reduce((total, product) => {
        const productPrice = product.productPrice ?? 0;

        return total + productPrice;
    }, 0);
};

export const getSelectedCapVppProducts = (state: StateTree): DraftDealProduct[] => {
    return getVppSelectedProducts(state).filter(isVppCapProduct);
};

/**
 * Returns Manager View (dr-dash) specific  total price of selected VPP
 */
export const getTotalMvVppSelectionPrice = (state: StateTree): number => {
    const products = getVppProducts(state);

    return products.reduce((totalVppSelectionPrice, product) => {
        if (product.selected) {
            totalVppSelectionPrice += product.productPrice || 0;
        }

        return totalVppSelectionPrice;
    }, 0);
};
