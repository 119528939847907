// components
import { Modal } from '@interstate/components/Modal';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';

import { LoaderWrapper } from './UpdateOfferDialog.style';

const styleOverrides = {
    '#ids-modal-fixed-footer-component': {
        padding: '20px 24px 24px'
    }
};

export type UpdateDraftDialogProps = {
    show: boolean;
};

export const UpdateDraftDialog = ({ show }: UpdateDraftDialogProps) => {
    if (!show) {
        return null;
    }

    return (
        <Modal
            size="small"
            data-testid="update-draft-dialog"
            show={show}
            header={<Typography variant="h4">Save as Draft</Typography>}
            sx={styleOverrides}
            footer={{
                footerComponent: (
                    <LoaderWrapper>
                        <LoadingIndicator size="small" data-testid="update-draft-loading-indicator" />
                    </LoaderWrapper>
                )
            }}
        >
            <Typography variant="body-md" tag="p">
                We're saving your changes as a draft.
            </Typography>
        </Modal>
    );
};
