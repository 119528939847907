import { OfferType, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

export enum ETaxNames {
    UPFRONT = 'upfrontTaxes',
    MONTHLYUSE = 'monthlyUseTaxes',
    ADDITIONAL = 'additionalTaxes',
    CAPITALIZED = 'capitalizedTaxes'
}

export type TaxNames = 'additionalTaxes' & 'capitalizedTaxes' & 'monthlyUseTaxes' & 'upfrontTaxes';

export type CategorizedTaxes = Record<keyof TaxNames, paymentServicesTypes.TaxItems>;

export type TaxDetail = {
    [key: string]: any;
};

export interface RetailPriceFieldProps {
    data: TaxDetail;
    handleBlur: (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
    maxValue?: number;
}

export interface TaxTotalAmountFieldProps {
    data: paymentServicesTypes.TaxItem;
    handleBlur: (e: InterstateOnChangeEvent<TextInputEventValue>) => void;
    simplifyMonthlyUsageTax?: boolean;
}

export interface MonthlyTaxSummaryProps {
    taxItem: paymentServicesTypes.TaxItem;
    offerType: OfferType;
    taxOverrideEnabled?: boolean;
    handleAmountBlur: (e: InterstateOnChangeEvent<TextInputEventValue>) => void;
    simplifyMonthlyUsageTax?: boolean;
}
