import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SelectInput } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';

import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors, offerReduxActionCreators, offerActionTypes, compositeSelectors } from '@makemydeal/dr-dash-store';

import { termIdToOptionMapper, parseSelectedTermMonths, addValueSuffix, removeValueSuffix } from './utils';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, TERM } from '../../constants';

const FinanceTermField = ({ isSummaryTab }: { isSummaryTab?: boolean }) => {
    const dispatch = useDispatch();

    const useAnyTermForPaymentToggle = useSelector(featureToggleSelectors.useAnyTermForPayment);
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTermId = useSelector((state) => offerReduxSelectors.getTermIdByOfferType(state, offerType));
    const availableTerms = useSelector(dealerSelectors.getTermLengthsForCurrentCreditTier(offerType));
    const termsOptions = useMemo(() => availableTerms.map(termIdToOptionMapper), [availableTerms, termIdToOptionMapper]);

    const failed = useSelector(compositeSelectors.getLastFailureActionIfError(offerActionTypes.SELECTED_TERM));
    const failedMessage = failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined;

    const [termNumber, setTermNumber] = useState(addValueSuffix(parseSelectedTermMonths(selectedTermId) || ''));

    const updateSelectedTerm = (termId: string) =>
        dispatch(offerReduxActionCreators.selectedTerm(termId, { suppressAnalytics: true, payment: true }));

    const lengthOfTermChanged = (event: any) => {
        const termId = event.target.value;
        if (termId) {
            updateSelectedTerm(termId);
        }
    };

    const handleInputChanged = (e: any) => {
        if (e.target.value === termNumber || e.target.value.length > 3 || isNaN(Number(e.target.value))) {
            return;
        }

        setTermNumber(Number(e.target.value).toString());
    };

    const handleInputFocus = () => {
        setTermNumber(removeValueSuffix(termNumber));
    };

    const handleInputBlur = () => {
        const term = parseSelectedTermMonths(selectedTermId);
        if (term !== termNumber) {
            updateSelectedTerm(`finance_term_${termNumber}`);
        }

        setTermNumber(addValueSuffix(termNumber));
    };

    const select = (
        <SelectInput
            data-testid="CMDTermSelectInput"
            id="CMDTermSelectInput"
            label={isBaseDealScreenExperience && isSummaryTab ? `${TERM} (Monthly)` : TERM}
            name={TERM}
            hasError={failed}
            errorMessage={failedMessage}
            onChange={lengthOfTermChanged}
            value={selectedTermId}
            options={termsOptions as any}
            displayDeselectOption={false}
        />
    );

    const input = (
        <TextInput
            data-testid="CMDTermInput"
            label={isBaseDealScreenExperience ? `${TERM} (Monthly)` : TERM}
            name={TERM}
            hasError={failed}
            errorMessage={failedMessage}
            onChange={handleInputChanged}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={termNumber}
        />
    );

    return useAnyTermForPaymentToggle ? input : select;
};

export default FinanceTermField;
