import { BaggageEntry, Comparison, ComparisonBaggage } from '@makemydeal/dr-dash-bff-types';

/**
 * Predicate used to filter a list of Comparisons to get the published & active one
 * @param comparison
 * @returns
 */
export const byPublishedActiveStatus = (comparison: Comparison) => comparison.isPublished && comparison.status === 'active';

/**
 * Predicate used to filter a list of Comparisons to get the unpublished & active one
 * @param comparison
 * @returns
 */
export const byUnpublishedActiveStatus = (comparison: Comparison) => !comparison.isPublished && comparison.status === 'active';

/**
 * Rebuilds the order array based on the comparison object and successfully retrieved alternate scenario ids
 * @param comparison
 * @param retrievedNonPrimaryScenarioIds
 * @param primaryScopeId
 * @returns
 */
export const getOrderFromRetrievedScenarios = (
    comparison: Comparison,
    retrievedNonPrimaryScenarioIds: any[],
    primaryScopeId: string
): string[] => {
    const order: string[] = [];

    // Rebuild the order array correctly with retrieved versionIds instead of generated ids
    if (comparison.scenarios) {
        for (const scenario of comparison.scenarios) {
            // don't try to hydrate a scope from a scenarioId we did not successfully retrieve
            if (!scenario.isPrimary && retrievedNonPrimaryScenarioIds.includes(scenario.id)) {
                order.push(scenario.id);
            } else {
                order.push(primaryScopeId);
            }
        }
    }

    return order;
};

/**
 * Retrieves the latest draft or published comparison from a list
 *
 * @param comparisonList The full list of Comparison objects retrieved from baggage
 * @param latestDxgVersion The latest published DXG version
 * @returns Either the active draft comparison, or the active published comparison if it's the same as the latest version
 */
export const getActiveComparison = (comparisonList: Comparison[], latestDxgVersion: string) => {
    const activePublishedComparison = comparisonList.find(byPublishedActiveStatus);
    const activeUnpublishedComparison = comparisonList.find(byUnpublishedActiveStatus);
    // Either pick the (unpublished) draft comparison, or the
    // published comparison if it's the same as the latest version
    const activeComparison =
        activeUnpublishedComparison ||
        (activePublishedComparison?.dealXgVersionId === latestDxgVersion && activePublishedComparison);
    return activeComparison;
};
