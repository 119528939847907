import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CASH, FINANCE, LEASE } from '@makemydeal/dr-platform-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

import { DealSummaryContainer } from './DealSummary.interstate.style';
import { ExpandableAreaProvider, InterstateDealSummarySections } from '@makemydeal/dr-dash-components';

import Cash from './CashDealSummary.interstate';
import Lease from './LeaseDealSummary.interstate';
import Finance from './FinanceDealSummary.interstate';

const DealSummary = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isDealScreenExperienceEnabled = useSelector(dealerSelectors.isBaseDealScreenExperience);

    const offerComponent = useMemo(() => {
        switch (currentOfferType) {
            case CASH:
                return <Cash />;
            case LEASE:
                return <Lease />;
            case FINANCE:
                return <Finance />;
        }
    }, [currentOfferType]);

    return (
        <ExpandableAreaProvider>
            <DealSummaryContainer
                data-testid="deal-summary-container"
                xs={3.9}
                sx={{ padding: 0 }}
                width="100%"
                $isBaseDealScreenExperience={isDealScreenExperienceEnabled}
            >
                {isDealScreenExperienceEnabled ? (
                    <InterstateDealSummarySections.InterstateDetails>
                        {offerComponent}
                    </InterstateDealSummarySections.InterstateDetails>
                ) : (
                    offerComponent
                )}
            </DealSummaryContainer>
        </ExpandableAreaProvider>
    );
};

export default DealSummary;
