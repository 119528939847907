import { useMemo, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { formatMultiCardViewPaymentDetails, formatPaymentDetailsCashDown } from '@makemydeal/dr-dash-components';
import { ArrowPathIcon } from '@interstate/components/Icons/ArrowPathIcon';
import { Accordion, AccordionDetails, AccordionSummary } from '@interstate/components/AccordionGroup';
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import {
    deskingGlobalActionCreators,
    deskingGlobalSelectors,
    offerAdditionalSelectors,
    offerReduxSelectors,
    offerSelectors
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { StyledPaymentTitle } from './DerskingCardTitle.style';
import { CASH } from '@makemydeal/dr-platform-types';
import { Box } from '@interstate/components/Box';

export const DeskingCardPaymentDetailsAccordion = () => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const rollToPaymentFeatureToggle = useSelector(featureToggleSelectors.enableGetRollToPayment);
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTermId = useSelector((state) => offerReduxSelectors.getTermIdByOfferType(state, currentOfferType));
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const cashAmountDue = useSelector(offerAdditionalSelectors.getMvCashAmountDue);
    const paymentDetailsAccordionExpanded = useSelector(deskingGlobalSelectors.getPaymentDetailsAccordionExpanded);
    const amountToBePaidByCustomer = useSelector((state) => offerReduxSelectors.getDownPaymentByOfferType(state, currentOfferType));
    const paymentDetailCashDownLabel = useMemo(() => {
        return formatPaymentDetailsCashDown(currentOfferType, amountToBePaidByCustomer);
    }, [currentOfferType, amountToBePaidByCustomer]);
    const paymentDetailLabel = useMemo(() => {
        return formatMultiCardViewPaymentDetails(currentOfferType, monthlyPayment, cashAmountDue, selectedTermId);
    }, [currentOfferType, monthlyPayment, cashAmountDue, selectedTermId]);

    const onAccordionChange = () => {
        dispatch(deskingGlobalActionCreators.togglePaymentDetailsAccordion());
    };

    const handleToggleSlideOut = (e: MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        dispatch(deskingGlobalActionCreators.toggleRollToPayment());
    };

    return (
        <>
            <Accordion
                contained={true}
                onChange={onAccordionChange}
                expanded={paymentDetailsAccordionExpanded}
                data-testid="desking-card-payment-details-accordion"
                sx={{ height: '100%' }}
            >
                <AccordionSummary
                    chevronPosition="left"
                    sx={{ padding: theme.tokens.ComponentCardPadding, alignItems: 'flex-start' }}
                >
                    <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'multi-card-view'}>
                        <Typography data-testid="desking-card-payment-details-title" variant="h5">
                            Payment Details
                        </Typography>
                        <Box style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                            <Typography data-testid="desking-card-payment-details-label" variant="h4" sx={{ fontWeight: 600 }}>
                                {paymentDetailLabel}
                            </Typography>
                            {currentOfferType !== CASH && (
                                <Typography
                                    className="no-inherit-cash-down-label"
                                    data-testid="desking-card-payment-details-cash-down-label"
                                    variant="body-xs"
                                >
                                    {paymentDetailCashDownLabel}
                                </Typography>
                            )}
                        </Box>
                    </StyledPaymentTitle>
                    {rollToPaymentFeatureToggle && (
                        <div
                            style={{
                                marginLeft: '1rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={handleToggleSlideOut}
                            data-testid="roll-to-payment"
                        >
                            <ArrowPathIcon />
                        </div>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    <InterstateDealSummary />
                </AccordionDetails>
            </Accordion>
        </>
    );
};
