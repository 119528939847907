const cellBorder = { border: '0px solid #000' };

// Define a single column template to be used throughout
const colTemplate = '2fr 1fr 1fr 1fr';
const keyValTemplate = '2fr 3fr';

const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: colTemplate,
    gridAutoRows: '3rem',
    gridRowGap: '0.5rem',
    alignItems: 'center'
};

const gridPairsContainerStyle = {
    display: 'grid',
    gridTemplateColumns: keyValTemplate,
    gridAutoRows: '1rem',
    gridRowGap: '1rem',
    alignItems: 'center'
};

const titleStyle = {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    paddingBottom: '1rem'
};

const gridHeaderStyle = {
    fontFamily: 'Roboto Flex',
    color: '#6D6D6D',
    textAlign: 'center' as const,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...cellBorder
};

const leftAlignGridHeaderStyle = {
    ...gridHeaderStyle,
    textAlign: 'left' as const,
    justifyContent: 'flex-start'
};

const leftAlignBoldGridHeaderStyle = {
    ...leftAlignGridHeaderStyle,
    fontSize: '1.2rem',
    fontWeight: '700'
};

const firstColumnStyle = {
    fontFamily: 'Roboto Flex',
    color: '#6D6D6D',
    textAlign: 'left' as const,
    display: 'flex',
    alignItems: 'center',
    ...cellBorder
};

const leftAlignColumnStyle = {
    ...firstColumnStyle,
    color: '#000000',
    textAlign: 'left' as const,
    justifyContent: 'flex-start'
};

const rightAlignColumnStyle = {
    ...firstColumnStyle,
    color: '#000000',
    textAlign: 'right' as const,
    justifyContent: 'flex-end'
};

const visualLineStyle = {
    gridColumn: '1 / -1',
    width: '100%',
    borderTop: '0.05rem solid #000',
    margin: '1.25rem 0'
};

export {
    firstColumnStyle,
    gridContainerStyle,
    gridHeaderStyle,
    gridPairsContainerStyle,
    leftAlignBoldGridHeaderStyle,
    leftAlignColumnStyle,
    leftAlignGridHeaderStyle,
    rightAlignColumnStyle,
    titleStyle,
    visualLineStyle
};
