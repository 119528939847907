// externals
import styled from 'styled-components';

// libraries
import { Card } from '@interstate/components/Card';

type VehicleListItemContainerProps = {
    selected?: boolean;
};

export const VehicleListItemContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const VehicleListItemContainer = styled(Card)<VehicleListItemContainerProps>`
    && {
        border: ${({ selected, theme }) => (selected ? `2px solid ${theme.tokens.BaseColorBlue700}` : '')};
    }
`;

export const VehicleListItemContent = styled.div`
    display: flex;
    gap: 9px;
`;

export const VehicleListInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const VehicleListImageContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 150px;
    height: 101px;
    overflow: hidden;
    display: flex;
    align-content: flex-start;
    justify-content: center;
`;
