import React from 'react';
import { NumberStepper } from '@interstate/components/NumberStepper';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumberStepperEventValue } from '@interstate/components/NumberStepper/Types/numberStepperTypes';
import { leftAlignColumnStyle, rightAlignColumnStyle } from './RollToStyles';
import { formatValue } from './utils';

type Row = {
    label: string;
    current: number;
    rollTo: number;
    step?: number;
    adjust?: number;
};

type AdjustCellProps = {
    row: Row;
    rowIndex: number;
    getNumberStepperValue: (index: number, event: InterstateOnChangeEvent<NumberStepperEventValue>) => void;
};

const RIGHT_ALIGN_LABELS = new Set(['Total Taxes and Fees', 'Total Add Ons', 'Payment']);

const getAdjustCellStyle = (row: Row) => (RIGHT_ALIGN_LABELS.has(row.label) ? rightAlignColumnStyle : leftAlignColumnStyle);

const AdjustCell: React.FC<AdjustCellProps> = ({ row, rowIndex, getNumberStepperValue }) => {
    if (row.adjust !== undefined) {
        return <div style={getAdjustCellStyle(row)}>{formatValue(row.label, row.adjust)}</div>;
    }

    if (row.step !== undefined) {
        return (
            <div style={leftAlignColumnStyle}>
                <NumberStepper
                    onChange={(event: InterstateOnChangeEvent<NumberStepperEventValue>) => getNumberStepperValue(rowIndex, event)}
                    onBlur={() => {}}
                    value={row.current}
                    step={row.step}
                />
            </div>
        );
    }

    return <div style={leftAlignColumnStyle}></div>;
};

export default AdjustCell;
