import { Grid } from '@interstate/components/Grid';
import React from 'react';

import { DaysToFirstPaymentField, PaymentLoading, ProfitInfo, SellRateField, formatDate } from '@makemydeal/dr-dash-components';
import { Popover } from '@interstate/components/Popover';
import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { useSelector } from 'react-redux';
import { FINANCE, LEASE, CASH } from '@makemydeal/dr-platform-types';
import { accessoriesSelectors, offerReduxSelectors, tradeInSelectors, vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// Components
import {
    SellingPriceField,
    CreditDecisionLenderNameField,
    LeaseTermField,
    CashDownField,
    SecurityDepositField,
    FinanceTermField,
    RetailPriceField,
    IsTargetOutOfPocket
} from '@makemydeal/dr-dash-components';

import TradeInAllowance from './TradeInFields/TradeAllowance.interstate';
import ACV from './TradeInFields/ACV.interstate';
import Payoff from './TradeInFields/Payoff.interstate';
import ReadOnlyInput from './ReadOnlyInputs.interstate';
import {
    BaseDealSummaryLeftSectionContainer,
    BaseDealSummaryRightSectionContainer,
    BaseDealSummarySectionContainer,
    InputField
} from './BaseDealSummary.style';
import { BaseTabs } from '../baseDealTypes';

const BaseDealSummary = () => {
    const isMobile = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    //incentives
    const totalRebates = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const incentives = formatDollarsAndCents(totalRebates);

    //accessories
    const totalAccessoriesAmount = useSelector(accessoriesSelectors.getAccessoriesTotalAmount);
    const accessories = formatDollarsAndCents(totalAccessoriesAmount);

    //VPP
    const { totalProductPrice } = useSelector(vehicleProtectionSelectors.getVppSummary);
    const vpp = formatDollarsAndCents(totalProductPrice);

    //fees
    const totalFees = useSelector(offerReduxSelectors.getTotalFee);
    const totalFeesFormatted = formatDollarsAndCents(totalFees);

    // total tax
    const taxTotal = useSelector(offerReduxSelectors.getTotalTax);
    const formattedTotalTax = formatDollarsAndCents(taxTotal);

    // current offer type
    const currentOfferType: string = useSelector(offerReduxSelectors.getCurrentOfferType);

    const leftSectionFields = [
        { component: <RetailPriceField /> },
        { component: <SellingPriceField /> },
        { condition: (type: string) => type !== CASH, component: <CashDownField /> },
        { condition: (type: string) => type === LEASE, component: <IsTargetOutOfPocket /> },
        { condition: (type: string) => type === LEASE, component: <SecurityDepositField /> },
        { component: <TradeInAllowance /> },
        { component: <ACV /> },
        { component: <Payoff /> },
        { component: <ReadOnlyInput label="Fees" inputValue={totalFeesFormatted} tab={BaseTabs.FEES} /> },
        { component: <ReadOnlyInput label="Taxes" inputValue={formattedTotalTax} tab={BaseTabs.TAXES} /> }
    ];

    const rightSectionFields = [
        { condition: (type: string) => type !== CASH, component: <CreditDecisionLenderNameField isSummaryTab={true} /> },
        { condition: (type: string) => type === LEASE, component: <LeaseTermField isSummaryTab={true} /> },
        { condition: (type: string) => type === FINANCE, component: <FinanceTermField isSummaryTab={true} /> },
        { condition: (type: string) => type !== CASH, component: <SellRateField isSummaryTab={true} /> },
        { condition: (type: string) => type === FINANCE, component: <DaysToFirstPaymentField /> },
        { component: <ReadOnlyInput label="Incentives" inputValue={incentives} tab={BaseTabs.INCENTIVES} /> },
        { component: <ReadOnlyInput label="Accessories" inputValue={accessories} tab={BaseTabs.ACCESSORIES} /> },
        { component: <ReadOnlyInput label="Protection product" inputValue={vpp} tab={BaseTabs.PROTECTION} /> }
    ];

    return (
        <Grid padding={'0px'}>
            <Grid container padding={`${isMobile ? 0 : 4}`} justifyContent="space-between">
                <PaymentLoading testId="loading-indicator" />

                <BaseDealSummarySectionContainer
                    className="summary-section-container"
                    data-testid="base-deal-summary-section-container"
                >
                    <BaseDealSummaryLeftSectionContainer className="summary-left-section">
                        {leftSectionFields.map(
                            (field, index) =>
                                (!field.condition || field.condition(currentOfferType)) && (
                                    <InputField key={index}>{field.component}</InputField>
                                )
                        )}
                    </BaseDealSummaryLeftSectionContainer>

                    <BaseDealSummaryRightSectionContainer className="summary-right-section">
                        {rightSectionFields.map(
                            (field, index) =>
                                (!field.condition || field.condition(currentOfferType)) && (
                                    <InputField key={index}>{field.component}</InputField>
                                )
                        )}
                    </BaseDealSummaryRightSectionContainer>
                </BaseDealSummarySectionContainer>
            </Grid>

            <hr />

            <Grid
                sx={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }}
            >
                <Typography sx={{ marginBottom: '10px' }} variant="label-md">
                    Profit
                </Typography>
                <Popover
                    padding={true}
                    header="Profit Details"
                    data-testid="profit-details-popover"
                    position="bottom"
                    trigger="outsideClick"
                    popoverContent={<ProfitInfo />}
                >
                    <Action data-testid="profit-details-btn" size="sm">
                        Details
                    </Action>
                </Popover>
            </Grid>
        </Grid>
    );
};

export default BaseDealSummary;
