import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useMemo, useState } from 'react';
import RollToPairsGrid from './RollToPairsGrid';
import RollToPaymentHeader from './RollToPaymentHeader';
import { useSelector } from 'react-redux';

export enum ActivityText {
    Show = 'Show All',
    Hide = 'Hide All',
    Masking = '*******'
}

const RollToPaymentProfit = () => {
    const profit = useSelector(offerReduxSelectors.getSelectedPaymentProfitDetails);
    const totalAmountDue = useSelector(offerReduxSelectors.getTotalAmountDue);

    const tradeProfit = '100'; //must map in PO
    const vehicleProfit = '200'; //must map in PO
    const frontProfit = profit?.frontEndProfit;

    const reserveProfit = profit?.reserveProfit;
    const backProfit = profit?.backEndProfit;

    const [showProfit, setShowProfit] = useState(false);

    const showHideProfits = useMemo(
        () => () => {
            setShowProfit(!showProfit);
        },
        [showProfit]
    );

    const getDisplayValue = (value: number | string | undefined): number | string =>
        showProfit ? value ?? '' : ActivityText.Masking;

    const actionMessage = showProfit ? ActivityText.Hide : ActivityText.Show;

    return (
        <>
            <RollToPaymentHeader />
            <RollToPaymentHeader gridCategory={'Profit'} gridAction={showHideProfits} actionMessage={actionMessage} />
            <RollToPairsGrid
                headers={'Front'}
                rows={[
                    { label: 'Trade', value: getDisplayValue(tradeProfit) },
                    { label: 'Vehicle', value: getDisplayValue(vehicleProfit), dividerBelow: true },
                    { label: 'Total Front', value: getDisplayValue(frontProfit) }
                ]}
            />
            <RollToPaymentHeader />
            <RollToPairsGrid
                headers={'Back'}
                rows={[
                    { label: 'Finance Reserve', value: getDisplayValue(reserveProfit), dividerBelow: true },
                    { label: 'Total Back', value: getDisplayValue(backProfit) }
                ]}
            />
            <RollToPaymentHeader />
            <RollToPaymentHeader gridCategory={'Balance Due'} />
            <RollToPairsGrid rows={[{ label: 'Total Balance Due', value: getDisplayValue(totalAmountDue) }]} />
        </>
    );
};

export default RollToPaymentProfit;
