// types
import { CatalogAccessory } from '@makemydeal/dr-dash-types';
import { CalculationEndpointType, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { FeeCategories } from '@makemydeal/dr-shared-types';

export type BreakdownItem = {
    name: string;
    value: number;
};

export type Lead = {
    id?: string;
    hash?: string;
    timestamp?: number;
    url?: string | null;
};

export type CreditTierScoreHighLow = {
    high: number;
    low: number;
};

export type UserConsent = 'unchecked' | 'checked';

export type OfferLenderDecisionMocked = {
    success: boolean;
    message: string;
    body: OfferLenderDecisionPayload;
};

export type OfferLenderDecisionPayload = {
    approvalStatus: string;
    approvedAmount: number;
    approvedRate: number;
    buyRate: number | null;
    approvedTerm: number;
    lenderId: string;
    lenderName: string;
    lenderLogo: string;
    monthlyPayment: number;
    decisionShown?: boolean;
    isActive?: boolean;
};

export type AddCatalogAccessoriesActionPayload = { accessories: CatalogAccessory[] };
export type RemoveCatalogAccessoriesActionPayload = { accessories: CatalogAccessory[] };

export type PaymentFeesTypesState = {
    feesByCategory: FeeCategories;
    feesTypesLoading: boolean;
    feesTypesError: string;
    traceId?: string;
    feesOverrides?: paymentServicesTypes.FeeOverride[];
};

export function isCalculationEndpoint(value: any): value is CalculationEndpointType {
    return value === '/program-quotes' || value === '/user-program-quotes';
}
