// externals
import { Draft } from 'immer';

// libraries
import { OfferInfoState } from '@makemydeal/dr-dash-types';
import { AnyFSA } from '@makemydeal/dr-platform-shared';

export const storeLinksInState = (draft: Draft<OfferInfoState>, payload: any) => {
    draft.urls = {
        creditAppURL: payload.creditAppUrl,
        shopperURL: payload.shopperPageUrl,
        testDriveURL: payload.testDriveDeepLinkUrl,
        docUploadURL: payload.docUploadDeepLinkUrl
    };
};

export const updateOfferSavedSuccessState = (draft: Draft<OfferInfoState>, action?: AnyFSA) => {
    draft.lastSuccessfulSaveDateString = new Date().toISOString();
    if (action?.meta?.isDraft) {
        draft.isDraftBeingSaved = false;
        draft.hasDraftBeenSaved = true;
    } else {
        draft.hasBeenSaved = true;
        draft.isOfferBeingSaved = false;
    }
};

export const updateOfferSaveFailureState = (draft: Draft<OfferInfoState>, action?: AnyFSA) => {
    if (action?.meta?.isDraft) {
        draft.hasDraftBeenSaved = false;
        draft.isDraftBeingSaved = false;
    } else {
        draft.hasBeenSaved = false;
        draft.isOfferBeingSaved = false;
    }
};
