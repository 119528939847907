// externals
import { FC } from 'react';

// libraries
import { TextInput } from '@interstate/components/TextInput';

// types
import { CellFinanceDetailsProps } from './types';

// consts/enums
import { InterstateOnChangeCallback } from '@interstate/components/InterstateEvents';
import { NA } from '../common/taxesAndFeesConsts';
import { useFeesFormContext } from './useFeesFormContext';

// utils
import { encodeForDataTestId, handleAcceptOnlyNumbers } from '../../utils/components';
import { useSelector } from 'react-redux';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { NumericInput } from '@interstate/components/NumericInput';

export const FinanceAmountCell: FC<CellFinanceDetailsProps> = ({ detail, index }) => {
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);
    const { handleInputChange, setDisableFeeSubmit } = useFeesFormContext();

    const handleFeeAmountChange: InterstateOnChangeCallback<string> = (e) => {
        const value = e.target.value;
        const hasMoreThanTwoPoints = value.split('.').length > 2;

        if (hasMoreThanTwoPoints) {
            return;
        }

        setDisableFeeSubmit(value === '');

        return handleInputChange({ category: detail.category, field: 'feeAmount', index, value });
    };

    return !isBaseDealScreenExperience ? (
        <TextInput
            name="feeAmount"
            label="Amount"
            required
            className="edit-fee--amount-cell"
            data-testid={`edit-fee--amount-cell-${encodeForDataTestId(detail.name)}`}
            value={detail.amount == null ? NA : detail.amount}
            disabled={detail.isDeleted}
            onKeyDown={handleAcceptOnlyNumbers}
            onChange={handleFeeAmountChange}
        />
    ) : (
        <NumericInput
            label="Amount"
            name="feeAmount"
            onChange={handleFeeAmountChange}
            value={detail.amount && formatDollarsAndCents(detail.amount)}
            data-testid={`edit-fee--amount-cell-${encodeForDataTestId(detail.name)}`}
            allowDecimal
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas
            className="edit-fee--amount-cell"
            disabled={detail.isDeleted}
            required
        />
    );
};
