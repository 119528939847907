// libraries
import { Grid } from '@interstate/components/Grid';
import React, { useState } from 'react';

// components
import BaseDealContainer from './baseDealContainer/BaseDealContainer';
import BaseDealHeader from './BaseDealHeader';
import BaseScreenLoader from './BaseScreenLoader/BaseScreenLoader';

const BaseDealActivity = () => {
    const [showDealSummary, setShowDealSummary] = useState(false);

    return (
        <Grid container data-testid="base-deal-central" spacing={2}>
            <BaseScreenLoader />
            <Grid xs padding="40px 20px 24px">
                <BaseDealHeader showDealSummary={showDealSummary} setShowDealSummary={setShowDealSummary} />
                <BaseDealContainer showDealSummary={showDealSummary} />
            </Grid>
        </Grid>
    );
};

export default BaseDealActivity;
