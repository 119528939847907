// External React library
import React from 'react';

// CSS style components
import {
    gridContainerStyle,
    gridHeaderStyle,
    leftAlignGridHeaderStyle,
    firstColumnStyle,
    leftAlignColumnStyle,
    visualLineStyle
} from './RollToStyles';

// Interstate components and type
import { NumberStepperEventValue } from '@interstate/components/NumberStepper/Types/numberStepperTypes';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

// Util file
import { formatValue } from './utils';

// Import the new AdjustCell component
import AdjustCell from './AdjustCell';

const LEFT_ALIGN_HEADERS = new Set(['Current', 'Roll-To']);

type Row = {
    label: string;
    current: number;
    rollTo: number;
    step?: number;
    adjust?: number;
    dividerBelow?: boolean;
};

type RollToPaymentGridComponentProps = {
    rows: Row[];
    headers: string[];
    getNumberStepperValue: (index: number, event: InterstateOnChangeEvent<NumberStepperEventValue>) => void;
};

const RollToPaymentGrid: React.FC<RollToPaymentGridComponentProps> = ({ headers, rows, getNumberStepperValue }) => {
    return (
        <div style={gridContainerStyle}>
            {/* Grid Headers */}
            {headers.map((header, index) => (
                <div key={index} style={LEFT_ALIGN_HEADERS.has(header) ? leftAlignGridHeaderStyle : gridHeaderStyle}>
                    {header}
                </div>
            ))}
            {/* Grid columns */}
            {rows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <div style={firstColumnStyle}>{row.label}</div>
                    <div style={leftAlignColumnStyle}>{formatValue(row.label, row.current)}</div>
                    <div style={leftAlignColumnStyle}>{formatValue(row.label, row.rollTo)}</div>
                    <AdjustCell row={row} rowIndex={rowIndex} getNumberStepperValue={getNumberStepperValue} />
                    {row.dividerBelow && <div data-testid="divider" style={visualLineStyle}></div>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default RollToPaymentGrid;
