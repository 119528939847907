// externals
import { FC } from 'react';

// libraries
import { CheckBox } from '@interstate/components/CheckBox';

// Components
import { LeaseUpFrontContainer } from './LeaseUpfrontCell.style';

// hooks
import { useFeesFormContext } from './useFeesFormContext';

// types
import { CellLeaseDetailsProps } from './types';

export const LeaseUpfrontCell: FC<CellLeaseDetailsProps> = ({ detail, index }) => {
    const { handleInputChange } = useFeesFormContext();

    return (
        <LeaseUpFrontContainer className="edit-fee--upfront-cell" data-testId="edit-fee--upfront-cell">
            <CheckBox
                name="capIndicator"
                checked={Boolean(detail.upfront)}
                label="Upfront"
                value={`${detail.upfront}-${index}`}
                data-testid={`edit-fee--upfront-cell-${index}`}
                disabled={detail.isDeleted}
                onChange={() => {
                    handleInputChange({
                        category: detail.category,
                        field: 'capIndicator',
                        index,
                        value: Boolean(detail.upfront)
                    });
                }}
            />
        </LeaseUpFrontContainer>
    );
};
