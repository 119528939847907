import { configSelectors, offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';

// types
import { AdditionalSelectors } from '@makemydeal/dr-activities-change-vehicle';

export const additionalSelectors: AdditionalSelectors = {
    getCommonOrgId: offerReduxSelectors.getCommonOrgId,
    getServicesBff: configSelectors.getServicesBff,
    getVehicleVin: vehicleSelectors.getVehicleVin,
    getVehicleStockNumber: vehicleSelectors.getVehicleStockNumber
};
