/* istanbul ignore file */
/** TODO: Unit Tests */
// externals
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { SlideOut } from '@interstate/components/SlideOut';
import { Typography } from '@interstate/components/Typography';
import { InformationCircleIcon } from '@interstate/components/Icons/InformationCircleIcon';

// actions
import {
    deskingGlobalSelectors,
    deskingGlobalActionCreators,
    offerSelectors,
    rollToActionCreators
} from '@makemydeal/dr-dash-store';
import { TabProps, Tabs } from '@interstate/components/Tabs';

import {
    RollToOptionsTabbedContentWrapper,
    StyledRollToOptionsBannerRegion,
    StyledRollToOptionsTextInput
} from './RollToOptions.interstate.style';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { NumericInput } from '@interstate/components/NumericInput';
import RollToPaymentOptions from './rollTo/RollToPaymentOptions';
import RollToPaymentProfit from './rollTo/RollToPaymentProfit';

const RollToOptionsInterstate = () => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();
    const { getRollToPayment }: any = rollToActionCreators;
    const { toggleRollToPayment }: any = deskingGlobalActionCreators;
    const showRollOptions = useSelector(deskingGlobalSelectors.getRollToPaymentSlideOutExpanded);
    const [targetInputValue, setTargetInputValue] = useState<string>('');
    const [targetInputFieldWasDirtied, setTargetInputFieldWasDirtied] = useState<boolean>(false);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);

    const submitTargetInput = useCallback(() => {
        dispatch(getRollToPayment(Number(targetInputValue)));
    }, [dispatch, getRollToPayment, targetInputValue]);

    const clearTargetInput = useCallback(() => {
        setTargetInputValue('');
    }, [setTargetInputValue]);

    const toggleSlideout = useCallback(() => {
        dispatch(toggleRollToPayment());
    }, [dispatch, toggleRollToPayment]);

    // useEffect: mark targetInput as 'dirty' and submit
    useEffect(() => {
        const id = setTimeout(() => {
            if (targetInputValue.length > 0) {
                setTargetInputFieldWasDirtied(true);
                submitTargetInput();
            } else {
                setTargetInputFieldWasDirtied(false);
            }
        }, 500);
        return () => {
            clearTimeout(id);
        };
    }, [targetInputValue, submitTargetInput]);

    // eslint-disable-next-line react/no-multi-comp
    const PaymentTabContent: React.FC = () => {
        return (
            <RollToOptionsTabbedContentWrapper id="roll-to-options-tab-content" data-testid="roll-to-options-tab-content">
                <StyledRollToOptionsBannerRegion id="roll-to-options-banner-region" data-testid="roll-to-options-banner-region">
                    <Typography variant="h3" tag="div">
                        Adjust Target Payment to review your Roll-To Options.
                    </Typography>

                    <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-between" flexWrap="nowrap">
                        <div>
                            <span id="current-payment-label">Current Payment</span>
                            <span id="current-payment-value">${monthlyPayment}</span>
                        </div>

                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <span id="target-payment-input-label">{`Target Payment`}</span>
                            <span>
                                <InformationCircleIcon
                                    data-testid="target-payment-info-icon"
                                    color={theme.tokens.BaseColorBlue500}
                                />
                            </span>

                            <StyledRollToOptionsTextInput>
                                <NumericInput
                                    displayLabel={false}
                                    id="target-payment-text-input"
                                    data-testid="target-payment-text-input"
                                    inputPrefix="$"
                                    value={targetInputValue}
                                    name="roll-to-options-text-input"
                                    onBlur={(e) => setTargetInputValue(e.target.value)}
                                />
                            </StyledRollToOptionsTextInput>
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <div>
                            {targetInputFieldWasDirtied ? (
                                <Button
                                    id="btn-target-payment-clear-input"
                                    data-testid="btn-target-payment-clear-input"
                                    buttonStyle="secondary"
                                    size="small"
                                    onClick={() => clearTargetInput()}
                                >
                                    Clear
                                </Button>
                            ) : (
                                <Button
                                    id="btn-target-payment-set-target"
                                    data-testid="btn-target-payment-set-target"
                                    buttonStyle="secondary"
                                    size="small"
                                    onClick={(e) => dispatch(getRollToPayment(Number(targetInputValue)))}
                                >
                                    Set Target
                                </Button>
                            )}
                        </div>
                    </Box>
                </StyledRollToOptionsBannerRegion>
            </RollToOptionsTabbedContentWrapper>
        );
    };

    const rollToOptionsTabs: TabProps[] = [
        {
            label: 'Payment',
            active: true,
            component: (
                <>
                    <PaymentTabContent />
                </>
            )
        }
    ];

    if (showRollOptions === false) return <></>;

    return (
        <SlideOut
            data-testid="roll-to-payment-slideout"
            show={showRollOptions}
            position="right"
            panelWidth="688"
            onHide={toggleSlideout}
            header={`Roll-To Calculator: Finance Deal`}
            sx={{
                '.ids-SlideOut-content': {
                    padding: '24px'
                }
            }}
        >
            <div id="roll-to-options-wrapper" data-testid="roll-options-wrapper">
                <Tabs id="roll-to-options-tabs" onActivate={() => {}} tabs={rollToOptionsTabs} />
                <RollToPaymentOptions />
                <RollToPaymentProfit />
            </div>
        </SlideOut>
    );
};

export default RollToOptionsInterstate;
