// externals
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { PencilSquareIcon } from '@interstate/components/Icons/PencilSquareIcon';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Button } from '@interstate/components/Button';

// store
import {
    vehicleProtectionSelectors,
    vehicleProtectionActionCreators,
    offerReduxSelectors,
    navigationHooks,
    RouteValues
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';

// style
import {
    EditDealAttributesLink,
    VehicleProtectionContent,
    VehicleProtectionTitle,
    VehicleProtectionFooter,
    VehicleProtectionFooterContainer,
    LoaderWrapper,
    TableWrapper,
    VehicleProtectionTitleContainer
} from './VehicleProtection.style';

// components
import VehicleProtectionTable from '../vehicleProtectionTable/VehicleProtectionTable';
import EditProductDrawer from '../editProductDrawer/EditProductDrawer';
import EditDealAttributesDrawer from '../editDealAttributesDrawer/EditDealAttributesDrawer';
import ProductSummary from '../productSummary/ProductSummary';
import TableGlobalAlerts from '../vehicleProtectionTableGlobalAlerts/TableGlobalAlerts';

// types
import { DealProductWithProductCode } from '@makemydeal/dr-dash-types';
import { CASH } from '@makemydeal/dr-platform-types';
import { BreakPoint } from '@makemydeal/dr-activities-common';

const VehicleProtection = () => {
    const [editableProductCode, setEditableProductCode] = useState<string | undefined>(undefined);

    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const selectedProductsCount = useSelector(vehicleProtectionSelectors.getVppSelectedProductsCount);
    const summary = useSelector(vehicleProtectionSelectors.getVppSummary);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const isFetchingVppRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const productsEligibleForSelection = useSelector(vehicleProtectionSelectors.getVppProductsEligibleForSelection);
    const isFetchingFIE = isFetchingVppProducts || isFetchingVppRates;

    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);

    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);

    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const [isDealAttributesDrawerOpen, setDealAttributesDrawerOpen] = useState(false);

    const handleDrawerClose = useCallback(() => {
        setEditableProductCode(undefined);
    }, []);

    const onOpenDealAttributesDrawer = useCallback(() => setDealAttributesDrawerOpen(true), []);
    const onCloseDealAttributesDrawer = useCallback(() => setDealAttributesDrawerOpen(false), []);

    const handleBackButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);

    const onProductEdit = useCallback((product: DealProductWithProductCode) => {
        setEditableProductCode(product.productCode);
    }, []);

    const onProductApply = useCallback(
        (product: DealProductWithProductCode) => {
            if (product.selected) {
                dispatch(vehicleProtectionActionCreators.unselectVppProduct(product.productCode));
            } else {
                dispatch(vehicleProtectionActionCreators.selectVppProduct(product.productCode));
            }
        },
        [dispatch]
    );

    const onProductApplyAll = useCallback(() => {
        if (selectedProductsCount === productsEligibleForSelection.length) {
            dispatch(vehicleProtectionActionCreators.unselectAllVppProducts());
        } else {
            dispatch(vehicleProtectionActionCreators.selectAllVppProducts());
        }
    }, [dispatch, selectedProductsCount, productsEligibleForSelection.length]);

    const getTable = () => {
        return (
            <>
                <VehicleProtectionTable
                    onProductApply={onProductApply}
                    onProductApplyAll={onProductApplyAll}
                    onProductEdit={onProductEdit}
                    products={products}
                    id="vehicle-protection-plan-table"
                />
                <ProductSummary
                    summary={summary}
                    isFinanceOrLease={isFinanceOrLease}
                    isBaseDealScreenExperience={isBaseDealScreenExperience}
                />
            </>
        );
    };

    const getVPPTitle = () => (
        <VehicleProtectionTitle>
            <span data-testid="vpp-title">Vehicle Protection Products ({selectedProductsCount})</span>
        </VehicleProtectionTitle>
    );

    const getEditDealAttributesLink = (isBaseDealScreenExperience: boolean) => (
        <div>
            {!isBaseDealScreenExperience && (
                <EditDealAttributesLink theme={theme} onClick={onOpenDealAttributesDrawer} data-testid="edit-deal-attributes-link">
                    Edit Deal Attributes
                </EditDealAttributesLink>
            )}

            {isBaseDealScreenExperience && (
                <Button
                    data-testid="edit-deal-attributes-link"
                    buttonStyle="tertiary"
                    onClick={onOpenDealAttributesDrawer}
                    size="small"
                    startIcon={<PencilSquareIcon />}
                >
                    Edit
                </Button>
            )}
        </div>
    );

    return (
        <>
            <TableGlobalAlerts />
            {/* Deal Screen Exp */}
            {!isBaseDealScreenExperience && getVPPTitle()}

            {/* Base Screen Exp */}
            {isBaseDealScreenExperience && (
                <VehicleProtectionTitleContainer>
                    {getVPPTitle()}
                    {getEditDealAttributesLink(isBaseDealScreenExperience)}
                </VehicleProtectionTitleContainer>
            )}

            <VehicleProtectionContent isBaseDealScreenExperience={isBaseDealScreenExperience}>
                {/* Deal Screen Exp */}
                {!isBaseDealScreenExperience && getEditDealAttributesLink(isBaseDealScreenExperience)}

                {isFetchingFIE && !products.length ? (
                    <LoaderWrapper>
                        <LoadingIndicator size="small" />
                    </LoaderWrapper>
                ) : (
                    <>
                        {!isBaseDealScreenExperience && getTable()}
                        {isBaseDealScreenExperience && <TableWrapper>{getTable()}</TableWrapper>}
                    </>
                )}
                <EditProductDrawer productCode={editableProductCode} onClose={handleDrawerClose} />
                <EditDealAttributesDrawer show={isDealAttributesDrawerOpen} onClose={onCloseDealAttributesDrawer} />
            </VehicleProtectionContent>
            <VehicleProtectionFooterContainer>
                <VehicleProtectionFooter theme={theme} isNewAppWidthEnabled={isABPencilEnabled}>
                    <Button
                        data-testid="vehicle-protection-back-to-deal"
                        buttonStyle="primary"
                        size="medium"
                        block={false}
                        onClick={handleBackButtonClick}
                    >
                        {isBaseDealScreenExperience ? 'Apply' : 'Back to My Deal'}
                    </Button>
                </VehicleProtectionFooter>
            </VehicleProtectionFooterContainer>
        </>
    );
};

export default VehicleProtection;
