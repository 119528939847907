// externals
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';

// selectors
import {
    CDMS,
    DMS_PLUS,
    configSelectors,
    offerInfoSelectors,
    pushToDmsActionCreators,
    pushToDmsSelectors,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import ConfirmPushToDmsDialogInterstate from '../pushToDms/confirmPushToDmsDialog/ConfirmPushToDmsDialog.interstate';

// constants
import { createPortal } from 'react-dom';
import { PushToDmsStatus } from '@makemydeal/dr-dash-types';
import { useLastPushToDmsUpdateText, usePushToDmsButtonText } from '../pushToDms/pushToDmsHooks';
import { PUSH_DEAL_CANCEL_EVENT, PUSH_DEAL_FAILED_EVENT, PUSH_DEAL_SUCCESS_EVENT } from '../pushToDms/pushToDmsConstants';
import PushToDmsLoaderDialog from '../pushToDms/pushToDmsLoaderDialog/PushToDmsLoaderDialog';
import PushToDmsDialog from '../pushToDms/pushToDmsDialog/PushToDmsDialog.interstate';

const PushToDms = forwardRef(({ isBaseScreen = false }: { isBaseScreen?: boolean }, ref) => {
    const [showConfirmPushToDmsDialog, setConfirmPushToDmsDialog] = useState(false);
    const [showPushToDmsDialog, setShowPushToDmsDialog] = useState(false);
    const [pushToDmsStatus, setPushToDmsStatus] = useState(PushToDmsStatus.Ready);
    const [dataEntryUrl, setDataEntryUrl] = useState<string | undefined>();
    const [hasBeenPushed, setHasBeenPushed] = useState<{ time: string | Date }>({
        time: ''
    });
    const [showLoaderDialog, setShowLoaderDialog] = useState(false);
    const [isIframeClosedAfterCdmsPush, setIsIframeClosedAfterCdmsPush] = useState(false);

    const offerSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const cdmsResponseData = useSelector(pushToDmsSelectors.getCdmsResponseData);
    const enableOptionalPbcUi = useSelector(featureToggleSelectors.enableOptionalPbcUi);
    const isDMSEnabled = useSelector(dealerSelectors.getDMSIntegrationToggle).toLowerCase() !== 'off';
    const vehicleStockNumber = useSelector(vehicleSelectors.getVehicleStockNumber);
    const lastSucceedDmsDealId = useSelector(pushToDmsSelectors.getLastSucceededDmsDealId);
    const pushToDmsWsEvent = useSelector(pushToDmsSelectors.getPushToDmsWsEvent);
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const cdmsPbcUrl = useSelector(configSelectors.getCDMSPbcUrl);

    const [isPushToCdmsActive, setIsPushToCdmsActive] = useState(false);
    const [isExtendedLoader, setIsExtendedLoader] = useState(false);

    const sendToDMSText = useLastPushToDmsUpdateText(hasBeenPushed.time);
    const pushToDmsButtonText = usePushToDmsButtonText();
    const isCDMSIntegration = dmsIntegrationToggle === CDMS;

    useEffect(() => {
        if (enableOptionalPbcUi) window.addEventListener('message', pbcPostbackMessageListener);

        return () => {
            if (enableOptionalPbcUi) {
                window.removeEventListener('message', pbcPostbackMessageListener);
            }
        };
    }, []);

    useEffect(() => {
        const isValidCdmsResponseData =
            cdmsResponseData && typeof cdmsResponseData === 'object' && Object.keys(cdmsResponseData).length > 0;
        if (isCDMSIntegration && enableOptionalPbcUi && isPushToCdmsActive && isValidCdmsResponseData) {
            const dataEntryURL = cdmsResponseData.dataEntryURL;
            const parsedDataEntryUrl = typeof dataEntryURL === 'string' && dataEntryURL.trim();
            if (parsedDataEntryUrl) {
                setDataEntryUrl(parsedDataEntryUrl);
            } else {
                returnedSuccessfulCdmsResponse && setIsExtendedLoader(true);
                setIsIframeClosedAfterCdmsPush(true);
            }
        }
    }, [cdmsResponseData]);

    useEffect(() => {
        const dmsDealId = pushToDmsWsEvent.payload?.dms_deal_id;
        if (
            enableOptionalPbcUi &&
            isIframeClosedAfterCdmsPush &&
            isPushToCdmsActive &&
            (dmsDealId || (Object.keys(pushToDmsWsEvent).length && !dmsDealId))
        ) {
            handleWaitingLoaderPushToDmsClose();
            if (dmsDealId) handleConfirmPushToDmsSaved();
            else handleConfirmPushToDmsFailed();
            setIsIframeClosedAfterCdmsPush(false);
        }
    }, [pushToDmsWsEvent, isIframeClosedAfterCdmsPush]);

    useImperativeHandle(ref, () => {
        return isBaseScreen ? { handleConfirmPushToDmsShow } : {};
    });

    // istanbul ignore next
    const handleConfirmPushToDmsShow = () => {
        setConfirmPushToDmsDialog(true);

        if (enableOptionalPbcUi && isCDMSIntegration) {
            setShowPushToDmsDialog(false);
            dispatch(pushToDmsActionCreators.cleanPushToDmsWsEvent());
            dispatch(pushToDmsActionCreators.cleanPushToCdmsErrorText());
        }
    };

    // istanbul ignore next
    const handleConfirmPushToDmsSaved = () => {
        setConfirmPushToDmsDialog(false);
        setShowPushToDmsDialog(true);
        setPushToDmsStatus(PushToDmsStatus.Ready);
        setHasBeenPushed(Object.assign({}, { ...hasBeenPushed }, { time: new Date() }));
    };

    // istanbul ignore next
    const handleConfirmPushToDmsFailed = () => {
        setConfirmPushToDmsDialog(false);
        setShowPushToDmsDialog(true);
        setPushToDmsStatus(PushToDmsStatus.Failure);
    };

    // istanbul ignore next
    const handleConfirmPushToDmsCancel = () => {
        setConfirmPushToDmsDialog(false);
        setIsPushToCdmsActive(false);
    };

    const isModalBackdropClicked = (event: any) => {
        return event?.target.classList.contains('MuiDialog-container');
    };

    // istanbul ignore next
    const handleConfirmPushToDmsHide: any = (event: any) => {
        if (isCDMSIntegration && enableOptionalPbcUi && isModalBackdropClicked(event)) {
            return;
        }
        setConfirmPushToDmsDialog(false);
    };

    // istanbul ignore next
    const handlePushToDmsHide: any = (event: any) => {
        if (isCDMSIntegration && enableOptionalPbcUi && isModalBackdropClicked(event)) {
            return;
        }
        setShowPushToDmsDialog(false);
        setIsPushToCdmsActive(false);
    };

    // istanbul ignore next
    const handlePushToDmsDone = () => {
        setShowPushToDmsDialog(false);
        setIsPushToCdmsActive(false);
    };

    //istanbul ignore next
    const handleWaitingLoaderPushToDmsShow = () => {
        setShowLoaderDialog(true);
    };

    const handleWaitingLoaderPushToDmsClose = () => {
        setShowLoaderDialog(false);
    };

    // istanbul ignore next
    const handlePushToDmsLoaderClose = () => {
        setShowLoaderDialog(false);
        setIsPushToCdmsActive(false);
    };

    const dispatch = useDispatch();

    const getDealIdentityText = () => {
        if (dmsIntegrationToggle === DMS_PLUS && offerSaved) {
            return `Stock # ${vehicleStockNumber}`;
        } else if (dmsIntegrationToggle === CDMS) {
            if (!enableOptionalPbcUi && offerSaved && returnedSuccessfulCdmsResponse) {
                return `Stock # ${vehicleStockNumber}`;
            } else {
                return `Deal ID: ${lastSucceedDmsDealId}`;
            }
        }
        return '';
    };

    const IframeWithPbcComponent = createPortal(
        <iframe
            src={`${dataEntryUrl}&hideConfirmationMsg=true`}
            width="100%"
            height="100%"
            data-testid="cdms-dataentry-iframe"
            id="cdms-dataentry-iframe"
            onLoad={handleWaitingLoaderPushToDmsClose}
            style={{
                inset: 0,
                position: 'fixed',
                zIndex: 1305
            }}
        />,
        document.getElementById('cdms-pbc-portal') as HTMLElement
    );

    const pbcPostbackMessageListener = (message: MessageEvent) => {
        if (message.origin === cdmsPbcUrl && message.data) {
            switch (message.data.type) {
                case PUSH_DEAL_SUCCESS_EVENT:
                    setIsExtendedLoader(true);
                    setShowLoaderDialog(true);
                    setDataEntryUrl(undefined);
                    setIsIframeClosedAfterCdmsPush(true);
                    break;
                case PUSH_DEAL_CANCEL_EVENT:
                    setShowLoaderDialog(false);
                    setIsIframeClosedAfterCdmsPush(true);
                    setDataEntryUrl(undefined);
                    setIsPushToCdmsActive(false);
                    break;
                case PUSH_DEAL_FAILED_EVENT:
                    setDataEntryUrl(undefined);
                    setIsIframeClosedAfterCdmsPush(true);
            }
        }
    };

    return (
        <>
            {isDMSEnabled && !isBaseScreen && (
                <Button
                    block
                    sx={{ backgroundColor: 'transparent' }} // TODO: Waiting for transparent background on secondary buttons
                    className="interstate"
                    buttonStyle="secondary"
                    data-testid="push-to-dms-button"
                    size="medium"
                    onClick={handleConfirmPushToDmsShow}
                >
                    {pushToDmsButtonText}
                </Button>
            )}
            {sendToDMSText && !isBaseScreen && (
                <>
                    <Typography
                        variant="body-sm"
                        color="sem.color.on-surface.subdued"
                        tag="div"
                        sx={{
                            marginTop: '16px'
                        }}
                        data-testid="last-pushed-time-string"
                    >
                        {sendToDMSText}
                    </Typography>
                    <Typography
                        variant="strong-md"
                        color="sem.color.on-surface.default"
                        tag="div"
                        sx={{
                            marginTop: '4px'
                        }}
                    >
                        {getDealIdentityText()}
                    </Typography>
                </>
            )}
            <ConfirmPushToDmsDialogInterstate
                show={showConfirmPushToDmsDialog}
                data-testid="confirm-push-to-dms-dialog"
                onSaved={handleConfirmPushToDmsSaved}
                onFailed={handleConfirmPushToDmsFailed}
                onHide={handleConfirmPushToDmsHide}
                onCancel={handleConfirmPushToDmsCancel}
                showLoader={handleWaitingLoaderPushToDmsShow}
                closeLoader={handleWaitingLoaderPushToDmsClose}
                setIsPushToCdmsActive={setIsPushToCdmsActive}
                setIsExtendedLoader={setIsExtendedLoader}
            />
            {enableOptionalPbcUi && showLoaderDialog && isPushToCdmsActive && (
                <PushToDmsLoaderDialog
                    handlePushToDmsLoaderClose={handlePushToDmsLoaderClose}
                    isExtendedLoader={isExtendedLoader}
                    show={showLoaderDialog}
                />
            )}
            <PushToDmsDialog
                data-testid="push-to-dms-dialog"
                show={showPushToDmsDialog}
                status={pushToDmsStatus}
                onHide={handlePushToDmsHide}
                onDone={handlePushToDmsDone}
                isPushToCdmsActive={isPushToCdmsActive}
            />
            {isCDMSIntegration && isPushToCdmsActive && dataEntryUrl && enableOptionalPbcUi && IframeWithPbcComponent}
        </>
    );
});

export default PushToDms;
