import { FC, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Button } from '@interstate/components/Button';
import { Bars3Icon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { CoxBridgeBar, RenderWhenOktaEnabled } from '@makemydeal/dr-shared-components';
import { routingSelectors, smartOfferSaveSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { useResizeObserver } from '../common/useResizeObserver';
import { NavDrawerContext } from '../navDrawer';
import BackToMyDealButton from './BackButton.interstate';
import {
    BackButtonRow,
    BridgeBarContainer,
    HamburgerMenuContainer,
    HeaderContainer,
    HeaderContent,
    HeaderRoot,
    HeaderRowContainer,
    ModifiedIndicatorContainer,
    TopBarContainer
} from './Header.style.interstate';
import { useHeaderMeasure } from './HeaderMeasureProvider';
import { HeaderRowInterstate } from './HeaderRow.interstate';
import { HeaderRowExtendedInterstate } from './HeaderRowExtended.interstate';
import { MAX_ACTIVITY_CONTAINER_WIDTH_NEW } from '../constants';

export type HeaderInterstateProps = {
    appPrefix: string;
};

export const HeaderInterstate: FC<HeaderInterstateProps> = (props) => {
    const theme = useInterstateTheme();

    const [sliderHeight, setSliderHeight] = useState<number>(0);
    const dashboardIsCurrentRoute = useSelector(routingSelectors.isDashboardCurrentRoute);
    const showModified = useSelector(smartOfferSaveSelectors.showDeveloperOfferHasUnsavedChanges);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);

    const showBackButton = !dashboardIsCurrentRoute;

    const refRoot = useRef<HTMLDivElement | null>(null);
    const refSlider = useRef<HTMLDivElement | null>(null);
    const { setHeight } = useHeaderMeasure();
    const { toggleDrawer } = useContext(NavDrawerContext);
    const hamburgerMenuDefaultBreakpoint = useMediaQuery({ query: '(max-width: 1360px)' });
    const hamburgerMenuAbPencilBreakpoint = useMediaQuery({ query: `(max-width: ${MAX_ACTIVITY_CONTAINER_WIDTH_NEW})` });
    const hamburgerMenuBreakpoint = isABPencilEnabled ? hamburgerMenuAbPencilBreakpoint : hamburgerMenuDefaultBreakpoint;

    useResizeObserver(refRoot, (entry) => {
        const totalHeaderHeight = entry.borderBoxSize[0].blockSize;
        setHeight(totalHeaderHeight - sliderHeight);
    });

    useResizeObserver(refSlider, (entry) => {
        setSliderHeight(entry.borderBoxSize[0].blockSize);
    });

    return (
        <HeaderRoot data-testid="header-root" ref={refRoot}>
            <HeaderContainer>
                <TopBarContainer>
                    {hamburgerMenuBreakpoint && !isBaseDealScreenExperience && (
                        <HamburgerMenuContainer>
                            <Button
                                data-testid="DealNavigationBarToggle"
                                id="DealNavigationBarToggle"
                                buttonStyle="tertiary"
                                onClick={toggleDrawer}
                                icon={<Bars3Icon />}
                            />
                        </HamburgerMenuContainer>
                    )}
                    <BridgeBarContainer isNewAppWidthEnabled={isABPencilEnabled}>
                        <RenderWhenOktaEnabled>
                            <CoxBridgeBar appPrefix={props.appPrefix} />
                        </RenderWhenOktaEnabled>
                    </BridgeBarContainer>
                </TopBarContainer>

                <HeaderContent extendedHeaderView={!isABPencilEnabled} theme={theme}>
                    {showBackButton && (
                        <BackButtonRow isNewAppWidthEnabled={isABPencilEnabled} extendedHeaderView={!isABPencilEnabled}>
                            <BackToMyDealButton isBaseDealScreenExperience={isBaseDealScreenExperience} />
                        </BackButtonRow>
                    )}
                    <HeaderRowContainer
                        isNewAppWidthEnabled={isABPencilEnabled}
                        extendedHeaderView={!isABPencilEnabled}
                        theme={theme}
                    >
                        {isABPencilEnabled ? <HeaderRowInterstate /> : <HeaderRowExtendedInterstate />}
                    </HeaderRowContainer>
                    {showModified && (
                        <ModifiedIndicatorContainer>
                            <div
                                title="this is only shown when running locally - it indicates that the smart-offer-save logic has detected that a full save to Deal XG is needed"
                                data-testid="modified-indicator"
                            >
                                *
                            </div>
                        </ModifiedIndicatorContainer>
                    )}
                </HeaderContent>
            </HeaderContainer>
        </HeaderRoot>
    );
};

export default HeaderInterstate;
