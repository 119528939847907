/* istanbul ignore file */
// React external components
import React, { useEffect, useMemo, useRef, useState } from 'react';

// Custom internal components
import RollToPaymentGrid from './RollToPaymentGrid';
import RollToPaymentHeader from './RollToPaymentHeader';

// Interstate components
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumberStepperEventValue } from '@interstate/components/NumberStepper/Types/numberStepperTypes';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import {
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    rollToSelectors,
    tradeInSelectors
} from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

type Row = {
    label: string;
    current: number;
    rollTo: number;
    step?: number;
    adjust?: number;
};

const RollToPaymentOptions = () => {
    const hasRollToPayments = useSelector(rollToSelectors.hasRollToPayments);

    // term
    const term = useSelector(offerReduxSelectors.getSelectedTermMonths) ?? '';
    const termAsStr = term.toString();
    const rollToTerm = useSelector(rollToSelectors.term) ?? '';
    const rollToTermAsStr = rollToTerm.toString();

    // rate
    const currentRateType = useSelector(offerReduxSelectors.getRateType) ?? '';
    const currentRate = useSelector(offerSelectors.getSellRateOverrideWithFallback) ?? '';
    const rollToRateType = useSelector(rollToSelectors.rateType) ?? '';
    const rollToRate = useSelector(rollToSelectors.rate) ?? '';

    // down payment
    const downPayment = useSelector(offerSelectors.getDownPayment) ?? '';
    const downPaymentAsStr = formatDollarsAndCents(downPayment.toString());
    const rollToDownPayment = useSelector(rollToSelectors.downPayment) ?? '';
    const rollToDownPaymentAsStr = formatDollarsAndCents(rollToDownPayment.toString());

    // selling price
    const sellingPrince = useSelector(offerSelectors.getSellingPrice) ?? '';
    const sellingPrinceAsStr = formatDollarsAndCents(sellingPrince.toString());
    const rollToSellingPrince = useSelector(rollToSelectors.sellingPrice) ?? '';
    const rollToSellingPrinceAsStr = formatDollarsAndCents(rollToSellingPrince.toString());

    // trade allowance
    const tradeAllowance = useSelector(tradeInSelectors.getTradeValue) ?? '';
    const tradeAllowanceAsStr = formatDollarsAndCents(tradeAllowance.toString());
    const rollToTradeAllowance = useSelector(rollToSelectors.trade) ?? '';
    const rollToTradeAllowanceAsStr = formatDollarsAndCents(rollToTradeAllowance.toString());

    // taxes & fees
    const taxesAndFees = useSelector(compositeSelectors.taxesAndFeesTotal) ?? '';
    const taxesAndFeesAsStr = formatDollarsAndCents(taxesAndFees.toString());

    // add ons
    const addOns = useSelector(compositeSelectors.getTotalAddons) ?? '';
    const addOnsAsStr = formatDollarsAndCents(addOns.toString());

    // payment
    const payment = useSelector(offerSelectors.getMonthlyPayment) ?? '';
    const paymentAsStr = formatDollarsAndCents(payment.toString());
    const rollToPayment = useSelector(rollToSelectors.monthlyPayment) ?? '';
    const rollToPaymentAsStr = formatDollarsAndCents(rollToPayment.toString());

    // empty cell display
    const emptyCell = '--';

    const rollToRows = useMemo(
        () => [
            { label: 'Term', current: termAsStr, rollTo: rollToTermAsStr, step: 1 },
            { label: currentRateType, current: currentRate, rollTo: rollToRate, step: 1 },
            { label: 'Down Payment', current: downPaymentAsStr, rollTo: rollToDownPaymentAsStr, step: 1 },
            { label: 'Selling Price', current: sellingPrinceAsStr, rollTo: rollToSellingPrinceAsStr, step: 1 },
            { label: 'Trade 1 Allowance', current: tradeAllowanceAsStr, rollTo: rollToTradeAllowanceAsStr, step: 1 },
            { label: 'Total Taxes and Fees', current: taxesAndFeesAsStr, rollTo: emptyCell, adjust: taxesAndFeesAsStr },
            { label: 'Total Add Ons', current: addOnsAsStr, rollTo: emptyCell, adjust: emptyCell, dividerBelow: true },
            { label: 'Payment', current: paymentAsStr, rollTo: rollToPaymentAsStr, adjust: paymentAsStr }
        ],
        [
            termAsStr,
            rollToTermAsStr,
            rollToRateType,
            currentRate,
            rollToRate,
            downPaymentAsStr,
            rollToDownPaymentAsStr,
            sellingPrinceAsStr,
            rollToSellingPrinceAsStr,
            tradeAllowanceAsStr,
            rollToTradeAllowanceAsStr,
            taxesAndFeesAsStr,
            addOnsAsStr,
            paymentAsStr,
            rollToPaymentAsStr
        ]
    );

    const headers = ['', 'Current', 'Roll-To', 'Adjust'];
    const [rows, setRows] = useState<Row[]>(rollToRows as any);

    const initialRowsRef = useRef(rollToRows as any);

    useEffect(() => {
        setRows(rollToRows as any);
    }, [rollToRows]);

    const getNumberStepperValue = (index: number, event: InterstateOnChangeEvent<NumberStepperEventValue>) => {
        const newValue = event.target.value;

        if (typeof newValue !== 'undefined') {
            setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[index] = {
                    ...updatedRows[index],
                    rollTo: newValue
                };
                return updatedRows;
            });
        }
    };

    const revertToOriginal = () => {
        setRows(initialRowsRef.current);
    };

    return (
        <>
            <RollToPaymentHeader
                gridCategory={'Roll-To Payment'}
                gridAction={revertToOriginal}
                actionMessage={'Revert To Original'}
            />
            <RollToPaymentGrid headers={headers} rows={rollToRows as any} getNumberStepperValue={getNumberStepperValue} />
        </>
    );
};

export default RollToPaymentOptions;
