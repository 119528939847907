// external
import { createSelector } from 'reselect';

// libraries
import { selectors } from '@makemydeal/dr-offer-redux';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';
import type { CreditTier, Dealer, DealerCreditTiers } from '@makemydeal/dr-shared-types';
import type { StateTree } from '@makemydeal/dr-dash-types';

// selectors
import {
    isCreditOverwriteBlockEnabled,
    testDriveAfterTradeIn,
    useDealScreenExperience as isBaseDealScreenExperienceFeatureToggle,
    isPrintOfferAndResidualUpdatesEnabled
} from './featureToggleSelectors';

export const getDealerState = (state: StateTree): Partial<Dealer> => {
    return state.dealer || {};
};

export const shouldUseFnI2 = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.useFnI2 || false;
};

export const isCreditOverwriteBlockAndFni2Enabled = (state: StateTree): boolean => {
    // NOTE: The linter is too stupid to figure out that we're not using a hook called `useFnI2` so we have to disable it here.
    return isCreditOverwriteBlockEnabled(state) && shouldUseFnI2(state);
};

export const getDealerEmail = (state: StateTree): string | undefined => {
    const dealer = getDealerState(state);
    return dealer.email;
};

export const getDealerName = (state: StateTree): string => {
    return getDealerState(state).name || '';
};

export const getDealerZip = (state: StateTree): string => {
    return getDealerState(state).address?.zip || '';
};

export const getDealerStateCode = (state: StateTree) => {
    return getDealerState(state).address?.state || '';
};

export const getDealerCity = (state: StateTree): string => {
    return getDealerState(state).address?.city || '';
};

export const getDealerStreet = (state: StateTree): string => {
    return getDealerState(state).address?.street || '';
};

/**
 * @returns dealer ID or -1 if state isn't populated (this should never be the case)
 */
export const getDealerId = (state: StateTree): number => {
    const dealer = getDealerState(state);
    return dealer.dealerId || -1;
};

export const getVinSolutionsDealerId = (state: StateTree): number => {
    return getDealerState(state).vinSolutionsDealerId || -1;
};

/**
 * @returns phone number or '' if null or undefined.
 */
export const getDealerPhone = (state: StateTree): string => {
    const dealer = getDealerState(state);
    return dealer.phone || '';
};

export const isMenuFitToHeightEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isMenuFitToHeightEnabled || false;
};

export const isMenuPricingEnabled = (state: StateTree): boolean => {
    const isDealerMenuPricingEnabled = !state.dealer?.isMenuPricingDisabled;
    return isDealerMenuPricingEnabled;
};

export const isSwitchVehicleEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer?.enableSwitchVehicle || false;
};

export const isVppEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isVppEnabled || false;
};

export const isVppEnabledForShopper = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isVppEnabledForShopper || false;
};

/**
 * For Dash this is hard-coded because there's just one theme (for now).
 */
export const getDealerSelectorsTheme = (state: StateTree) => 'blue';

export const useDRCRM = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.useDRCRM || false;
};

export const isItemizedManufacturerIncentivesEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state) as Dealer;
    return dealer.enableItemizedManufacturerIncentives || false;
};

export const isPrintOfferShowSignatureLineEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.printOfferShowSignatureLine || false;
};

export const isShowPrintItemizedDealBreakdownEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return (isPrintOfferAndResidualUpdatesEnabled(state) && dealer.showPrintItemizedDealBreakdown) || false;
};

export const isCashOfferEnabled = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.cashOfferSettings?.hasCashOffer || false;
};

export const hasLeasing = (state: StateTree): boolean => {
    const leaseSettings = getDealerState(state).leaseSettings;
    return leaseSettings?.hasLeasing || false;
};

export const isTradeValuationKbb = (state: StateTree) => {
    const tradeProvider = state.dealer.tradeInValuationProvider;
    return tradeProvider?.toLocaleLowerCase() === 'kelley blue book';
};

export const getDealerTradeInValuationProvider = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.tradeInValuationProvider;
};

export const getLeadRoutingOverrideSettingsEnabled = (state: StateTree): boolean | undefined => {
    const dealer = getDealerState(state);
    return dealer.leadRoutingOverrideSettings?.leadRoutingOverrideEnabled;
};

export const getLeadOverrideDealerId = (state: StateTree): number | undefined => {
    const dealer = getDealerState(state);
    return dealer.leadRoutingOverrideSettings?.dealertrackOverrideId;
};

export const isDealerTradeInLeadFormForWebsiteEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer?.tradeInValuationLeadForm || false;
};

export const getListingOverride = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer?.listingOverride;
};

export const isDealerTradeInLeadFormForListingSiteEnabled = (state: StateTree): boolean => {
    const listingOverride = getListingOverride(state);
    return listingOverride?.tradeInValuationLeadForm || false;
};

export const hasTestDriveAtHome = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.hasTestDriveAtHome || false;
};

export const testDriveAtHomeOnTradeIn = (state: StateTree) => {
    return !!(hasTestDriveAtHome(state) && isDealerTradeInLeadFormForWebsiteEnabled(state) && testDriveAfterTradeIn(state));
};

export const isSubaruGtpEnabled = (state: StateTree): boolean => state.dealer?.isSubaruGtpEnabled || false;

// TODO: In future we should rename this to DueAtSigningAmountDeduct, it isn't at "sign in"!
export function getIsDueAtSigninAmountDeduct(state: StateTree): boolean {
    const { leaseSettings } = state.dealer;
    // lease cap cost due at signing flag
    // default value needs to be set to true in case this hasn't been implemented on the dealer.
    // False is a valid value though, so we only need to check for undefined or null in order to default.
    return leaseSettings &&
        leaseSettings.isDueAtSigninAmountDeduct !== undefined &&
        leaseSettings.isDueAtSigninAmountDeduct !== null
        ? leaseSettings.isDueAtSigninAmountDeduct
        : true;
}

export const getOwnerId = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.ownerId;
};

export const getIsNewVehicleSinglePrice = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.isNewVehicleSinglePrice;
};

export const getIsUsedVehicleSinglePrice = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.isUsedVehicleSinglePrice;
};

export const hasNoTradeOwnership = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.hasNoTradeOwnership || false;
};

export const isDealertrackCreditProvider = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isDealertrackCreditProvider || false;
};

export const getCreditProvider = (state: StateTree): string => {
    const dealer = getDealerState(state);
    let creditProviderInitialValue;
    if (!dealer.creditProvider || (dealer.creditProvider && dealer.creditProvider === '')) {
        creditProviderInitialValue = isDealertrackCreditProvider(state) ? 'dealertrack' : 'external';
    }
    return dealer.creditProvider || (creditProviderInitialValue as string);
};

export const getDMSIntegrationToggle = (state: StateTree): string => {
    const dealer = getDealerState(state);
    return dealer.dmsIntegration || 'OFF';
};

export const getTradeInHideExcellentConditionWebsite = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.tradeInHideExcellent || false;
};

export const getTradeInHideExcellentConditionListingSite = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.listingOverride?.tradeInHideExcellent || false;
};

export const getCreditTiers = createSelector(
    getDealerState,
    (dealer): DealerCreditTiers => dealer.creditTiers || ({} as DealerCreditTiers)
);

/**
 * Pulls credit tiers from dealer state, the current offer type and credit tier from offer state to
 * return the list of term lengths for the currently-selected offer type
 */
const DEFAULT_EMPTY = [] as { id: string; months: number }[];
// simplified selector so we can `useSelector(getTermLengthsForCurrentCreditTier(offerType))`
export const getTermLengthsForCurrentCreditTier = (offerType: OfferType) =>
    createSelector(
        [
            (state: StateTree): string | undefined => selectors.getOfferDetails(state, offerType).creditTier,
            (state: StateTree): CreditTier[] => (getCreditTiers(state) as any)[offerType]
        ],
        (selectedCreditTierId: string | undefined, creditTiers: CreditTier[]): { id: string; months: number }[] => {
            if (offerType === CASH) {
                return DEFAULT_EMPTY;
            }
            const selectedTierInfo = creditTiers.find((tier: any) => tier.id === selectedCreditTierId);

            return (
                selectedTierInfo?.terms?.map(({ months }: any) => ({ id: `${offerType}_term_${months}`, months })) || DEFAULT_EMPTY
            );
        }
    );

export const isAccessoriesCatalogEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isAccessoriesCatalogEnabled || false;
};

export const isContinueToDeskingDisabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isContinueToDeskingDisabled || false;
};

export const isVAutoAppraisalEnabled = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer?.enableVauto || false;
};

export const getDealPulseToggle = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.showDealPulseInManagerView || false;
};

export const getCrmIntegrationToggle = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.isCrmIntegrationEnabled || false;
};

export const isCreditProviderRouteOne = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.creditProvider === 'r1_dealertrack';
};

export const enableCheckoutAnywhereToggle = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.enableCheckoutAnywhereInMV || false;
};

export const getDealerTextAddress = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.address?.address;
};

export const getRouteOnePartyId = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.routeOnePartyId || '';
};

export const isDarwinEnabled = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.enableDarwin || false;
};

export const getIsTestDealer = (state: StateTree) => {
    const dealer = getDealerState(state);
    return dealer.isTestDealer || false;
};

export const getDealerBrandingExperienceLogo = (state: StateTree): string => {
    const dealer = getDealerState(state);
    return dealer.brandingExperienceLogo || '';
};

export const getRollToPaymentToggle = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.enableGetRollToPayment || false;
};

export const isBaseDealScreenExperience = (state: StateTree): boolean => {
    const dealer = getDealerState(state);
    return dealer.dealScreenExperience === 'base' && isBaseDealScreenExperienceFeatureToggle(state);
};
