import styled from 'styled-components';

import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const ActionsContainer = styled.div<{ isNewAppWidthEnabled: boolean; enableDraftScenarioPersistence: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    gap: 1rem;

    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;

    box-shadow: ${({ theme, enableDraftScenarioPersistence }) =>
        enableDraftScenarioPersistence ? 'none' : ` 0 0 4px 0 ${theme.tokens.BaseColorGray300}`};
    padding: 16px;

    .desking-btn-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .share-container-buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        margin-top: ${({ enableDraftScenarioPersistence }) => (enableDraftScenarioPersistence ? '0' : '10px')};
        flex-direction: row;

        .desking-btn-container {
            flex: 1;
            flex-direction: row;
        }

        .share-container-buttons {
            flex-direction: row;
        }
    }
`;

// NOTE: This is a fix to show the shadow on the top side of the container only
export const CutOffShadowContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    overflow-x: clip;
`;

export const FooterContainer = styled.div`
    box-shadow: ${({ theme }) => ` 0 0 4px 0 ${theme.tokens.BaseColorGray300}`};
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
`;

export const FooterDealInfoContainer = styled.div`
    padding: 4px 16px 4px 16px;
    border-bottom: ${({ theme }) => `1px solid ${theme.tokens.SemColorBorderMuted}`};
`;

export const FooterDealInfoContent = styled.div`
    padding: 4px 10px 4px 10px;
`;

export const FooterDealInfoDocumentIconContainer = styled.span`
    margin-right: 5px;
    display: inline;
    vertical-align: middle;
`;

export const FooterDealInfoExclamationIconContainer = styled.span`
    margin-right: 5px;
    position: relative;
    top: 2px;
`;

export const FooterPlaceholder = styled.div`
    height: 13rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        height: 5.867rem;
    }
`;

export const StickyFooter = styled.div<{ enableDraftScenarioPersistence: boolean }>`
    position: ${({ enableDraftScenarioPersistence }) => (enableDraftScenarioPersistence ? 'sticky' : 'fixed')};
    bottom: 0;
    width: 100%;
    z-index: 1001;
`;
