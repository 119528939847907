import styled from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';

export const DealTypeDropdownContainer = styled(Box)`
    display: flex;
    gap: 8px;
    flex-direction: column;

    button {
        height: auto;
        min-width: 260px;
        justify-content: space-between;
    }
`;

export const DealTypeLabel = styled.span`
    font-weight: 600;
    font-size: 14px;
`;

export const DealTypeSelectTrigger = styled(Button)`
    &.MuiButton-root {
        border: 1px solid #b2b2b2;
        padding-top: 8px;
        padding-bottom: 8px;
    }
`;

export const DealLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    span:first-of-type {
        color: black;
        font-size: 16px;
    }
    span:last-of-type {
        font-size: 12px;
        color: #5a5a5a;
    }
`;
