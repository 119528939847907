import { StateTree } from '@makemydeal/dr-dash-types';
import * as tradeSelectors from '../selectors/trade';
import { getTradeInAmountOwed, getTradeValue } from '../selectors/trade';
import { getAdditionalTaxBreakdown } from '../selectors/offerSelectors';
import { DetailedBreakdown, IDealerFee, OfferType, TaxLabelMappings } from '@makemydeal/dr-platform-types';
import { accessoriesSelectors, offerReduxSelectors, vehicleProtectionSelectors } from '../selectors';
import { roundToTwoDecimal } from '@makemydeal/dr-common-utils';
import { TaxItemCategoryItem } from '@makemydeal/dr-platform-types/typedefs/shared/types/paymentServices';
import { BreakdownItem } from '../types';

// Accessories
export const getAccessoriesBreakdown = (state: StateTree): DetailedBreakdown => {
    return {
        value: accessoriesSelectors.getAccessoriesTotalAmount(state),
        items: accessoriesSelectors.getAccessoriesList(state).map((item) => {
            return {
                name: item.name,
                value: item.amount
            };
        })
    };
};

// Incentives
export const getConditionalIncentivesBreakdown = (state: StateTree, offerType: OfferType): DetailedBreakdown => {
    return {
        value: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
        items: offerReduxSelectors.getAppliedConditionalIncentives(state, offerType).map((item) => {
            return {
                name: item.name,
                value: item.amount
            };
        })
    };
};

export const getNonConditionalIncentivesBreakdown = (state: StateTree, offerType: OfferType): DetailedBreakdown => {
    return {
        value: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
        items: offerReduxSelectors.getNonConditionalsByOfferType(state, offerType).map((item) => {
            return {
                name: item.name,
                value: item.amount
            };
        })
    };
};

// Taxes & Fees
const getTaxRateLabel = (label: string, taxRate: number | TaxItemCategoryItem | undefined) => {
    return `${label}${taxRate ? ` (${taxRate}%)` : ''}`;
};

export const getAdvancedTaxList = (state: StateTree, offerType: OfferType): any => {
    const taxItems = offerReduxSelectors.getTaxItemsWithAdjustedRates(state, offerType);
    const advanceTaxItems = taxItems.filter((item) => item.taxName === 'advanced');
    const primaryBreakdown = advanceTaxItems[0]?.taxBreakdown;
    if (primaryBreakdown) {
        return [
            {
                name: getTaxRateLabel('City Tax', primaryBreakdown.cityTaxRate),
                value: primaryBreakdown.cityTax ?? 0
            },
            {
                name: getTaxRateLabel('County Tax', primaryBreakdown.countyTaxRate),
                value: primaryBreakdown.countyTax ?? 0
            },
            {
                name: getTaxRateLabel('Local Tax', primaryBreakdown.localTaxRate),
                value: primaryBreakdown.localTax ?? 0
            },
            {
                name: getTaxRateLabel('State Tax', primaryBreakdown.stateTaxRate),
                value: primaryBreakdown.stateTax ?? 0
            }
        ];
    }
    return [];
};

export const getAdditionalTaxList = (state: StateTree) => {
    const additionalTaxBreakdown = getAdditionalTaxBreakdown(state);
    return Object.entries(additionalTaxBreakdown).flatMap(([key, value]) => {
        return {
            name: TaxLabelMappings[key as keyof typeof TaxLabelMappings],
            value
        };
    });
};

export const getCapitalizedTaxTotal = (state: StateTree) => {
    const capitalizedTaxTotal = offerReduxSelectors.getCapitalizedTaxTotal(state);
    return {
        name: 'Taxes - Capitalized',
        value: capitalizedTaxTotal
    };
};

export const getDmvCappedFee = (state: StateTree, offerType: OfferType) => {
    const offerDetails = offerReduxSelectors.getOfferTerm(state, offerType);
    const cappedFeeTotal = offerDetails.fees?.cappedFeeTotal;
    return {
        name: offerType === 'cash' ? 'DMV Fee' : 'DMV Fees - Capitalized',
        value: cappedFeeTotal ?? 0
    };
};

export const getDealerCappedFeesList = (state: StateTree, offerType: OfferType) => {
    const offerDetails = offerReduxSelectors.getOfferTerm(state, offerType);
    const dealerFees: IDealerFee[] = offerDetails.dealerFees;
    const filterDealerFees = dealerFees.filter((fee: IDealerFee) => fee.dealerFeeCapped === true);
    return filterDealerFees.map((fee) => ({
        name: fee.dealerFeeName,
        value: fee.dealerFeeAmount
    }));
};

export const getCappedFeesList = (state: StateTree, offerType: OfferType) => {
    const cappedFeesList = [];
    const dealerCappedFeesList = getDealerCappedFeesList(state, offerType);
    const dmvCappedFee = getDmvCappedFee(state, offerType);
    if (dealerCappedFeesList.length > 0) {
        cappedFeesList.push(...dealerCappedFeesList);
    }
    cappedFeesList.push(dmvCappedFee);
    return cappedFeesList;
};

export const getTotalTaxesAndFeesForLease = (taxesAndFees: BreakdownItem[]) => {
    const totalTaxesAndFeesForLease = taxesAndFees.reduce((item, { value }) => item + value, 0);
    return totalTaxesAndFeesForLease;
};

export const getTaxesAndFeesBreakdown = (state: StateTree, offerType: OfferType): DetailedBreakdown => {
    const shopperZipCode = offerReduxSelectors.getShopperZip(state);
    const advancedTaxes = getAdvancedTaxList(state, offerType);
    const cappedFees = getCappedFeesList(state, offerType);
    const additionalTaxes = getAdditionalTaxList(state);
    const capitalizedTaxTotal = getCapitalizedTaxTotal(state);
    const taxesAndFees = [];
    if (advancedTaxes.length > 0) {
        taxesAndFees.push(...advancedTaxes);
    }
    if (cappedFees.length > 0) {
        taxesAndFees.push(...cappedFees);
    }
    if (additionalTaxes.length > 0) {
        taxesAndFees.push(...additionalTaxes);
    }
    if (offerType === 'lease') {
        taxesAndFees.push(capitalizedTaxTotal);
    }
    const filteredTaxesAndFees = taxesAndFees.filter((item) => item.value !== 0);
    const totalTaxesAndFees =
        offerType === 'lease'
            ? getTotalTaxesAndFeesForLease(filteredTaxesAndFees)
            : offerReduxSelectors.getTaxesAndFeesTotal(state, offerType);
    return {
        value: roundToTwoDecimal(totalTaxesAndFees),
        subHeader: `ZIP Code ${shopperZipCode}`,
        items: filteredTaxesAndFees
    };
};

// Vehicle Protection
export const getVehicleProtectionList = (state: StateTree) => {
    const vppProducts = vehicleProtectionSelectors.getVppSelectedProducts(state);
    if (vppProducts.length > 0) {
        return vppProducts.map((item) => {
            return {
                name: item.productName ?? '',
                value: item.productCost ?? 0
            };
        });
    }
    return [];
};

export const getVehicleProtectionBreakdown = (state: StateTree): DetailedBreakdown => {
    const vppSummary = vehicleProtectionSelectors.getVppSummary(state);
    const totalProductPrice = vppSummary.totalProductPrice;
    const vppItems = getVehicleProtectionList(state);
    const filteredVppItems = vppItems.filter((item) => item.value !== 0);
    return {
        value: totalProductPrice,
        items: filteredVppItems
    };
};

// Trade-In Value Applied
export const getTradeInSource = (state: StateTree) => {
    const tradeSource = tradeSelectors.getTradeSource(state);
    switch (tradeSource) {
        case 'ICO':
            return 'Instant Cash Value';
        case 'SD':
            return 'Kelley Blue Book Value';
        case 'SUBARUGTP':
            return 'Subaru Guaranteed Trade-in Value';
        default:
            return 'Manually Entered Trade Value';
    }
};

export const getTradeInList = (state: StateTree) => {
    const tradeSource = getTradeInSource(state);
    const tradeValue = getTradeValue(state);
    const tradeAmountOwed = getTradeInAmountOwed(state);
    const tradeInItems = [];
    tradeInItems.push({
        name: tradeSource,
        value: tradeValue
    });
    if (tradeAmountOwed > 0) {
        tradeInItems.push({
            name: 'Amount Owed On Trade-In',
            value: tradeAmountOwed
        });
    }
    return tradeInItems;
};

export const getNetTradeInEquityBreakdown = (state: StateTree): DetailedBreakdown => {
    const tradeMake = tradeSelectors.getTradeMake(state);
    const tradeModel = tradeSelectors.getTradeModel(state);
    const tradeTrim = tradeSelectors.getTradeTrim(state);
    const tradeYear = tradeSelectors.getTradeYear(state);
    const tradeNetValue = tradeSelectors.getTradeNetValue(state);
    const tradeValue = tradeSelectors.getTradeValue(state);
    const tradeInItems = tradeValue ? getTradeInList(state) : [];
    const tradeSubHeader = tradeValue ? `${tradeYear} ${tradeMake} ${tradeModel} ${tradeTrim}` : '';
    return {
        value: tradeNetValue,
        subHeader: tradeSubHeader,
        items: tradeInItems
    };
};

// Est. Due At Signing (Lease Only)
export const getDealerUpfrontFeesList = (state: StateTree) => {
    const dealerFees = offerReduxSelectors.getDealerFees(state);
    const filterDealerFees = dealerFees.filter((fee: IDealerFee) => fee.dealerFeeCapped === false && fee.includedInCalc === true);
    return filterDealerFees.map((fee) => ({
        name: fee.dealerFeeName,
        value: fee.dealerFeeAmount
    }));
};

export const getLenderUpfrontFeesList = (state: StateTree) => {
    const selectedTerm = offerReduxSelectors.getSelectedTerm(state);
    const lenderFees = offerReduxSelectors.getDealerLenderFeesFromTerm(selectedTerm, true);
    const filterLenderFees = lenderFees.filter(
        (fee: IDealerFee) =>
            fee.originalCategory.toLowerCase() === 'lender' && fee.dealerFeeCapped === false && fee.includedInCalc === true
    );
    return filterLenderFees.map((fee) => ({
        name: fee.dealerFeeName,
        value: fee.dealerFeeAmount
    }));
};

export const getDmvUpfrontFee = (state: StateTree) => {
    const upfrontFeesTotal = offerReduxSelectors.getGovernmentUpfrontFeeTotal(state);
    return {
        name: 'DMV Fees - Due Upfront',
        value: roundToTwoDecimal(upfrontFeesTotal)
    };
};

export const getUpfrontTaxTotal = (state: StateTree) => {
    const upfrontTaxTotal = offerReduxSelectors.getUpfrontTaxTotal(state);
    return {
        name: 'Taxes - Due Upfront',
        value: upfrontTaxTotal
    };
};

export const getNetDownPayment = (state: StateTree) => {
    const netCashDown = offerReduxSelectors.getNetCashDown(state);
    return {
        name: 'Net Down Payment',
        value: netCashDown
    };
};

export const getFirstMonthPayment = (state: StateTree) => {
    const monthlyPayment = offerReduxSelectors.getFirstMonthPayment(state);
    return {
        name: 'First Month Payment',
        value: monthlyPayment
    };
};

export const getEstDueAtSigningFeesList = (state: StateTree) => {
    const feesList = [];
    const upfrontTaxTotal = getUpfrontTaxTotal(state);
    const netCashDown = getNetDownPayment(state);
    const upfrontDmvFeeTotal = getDmvUpfrontFee(state);
    const dealerUpfrontFees = getDealerUpfrontFeesList(state);
    const lenderUpfrontFees = getLenderUpfrontFeesList(state);
    const monthlyPayment = getFirstMonthPayment(state);
    feesList.push(upfrontTaxTotal);
    feesList.push(netCashDown);
    feesList.push(upfrontDmvFeeTotal);
    if (dealerUpfrontFees.length > 0) {
        feesList.push(...dealerUpfrontFees);
    }
    feesList.push(...lenderUpfrontFees);
    feesList.push(monthlyPayment);
    return feesList;
};

export const getEstDueAtSigningBreakdown = (state: StateTree, offerType: OfferType) => {
    const dueAtSigning = offerReduxSelectors.getDueAtSigning(state, offerType);
    const estDueAtSigningFees = getEstDueAtSigningFeesList(state);
    const filteredEstDueAtSigningFees = estDueAtSigningFees.filter((item) => item.value !== 0);
    return {
        value: dueAtSigning,
        items: filteredEstDueAtSigningFees.map((item) => {
            return {
                name: item.name,
                value: item.value
            };
        })
    };
};
