import { useDispatch, useSelector } from 'react-redux';

import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { Box } from '@interstate/components/Box';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

import { SellRateItem, FINANCE } from '@makemydeal/dr-platform-types';
import { APR, MONEY_FACTOR, MONEY_FACTOR_TYPE, INPUT_INVALID_ERROR_MESSAGE } from '../../constants';
import { GridItem, StyledTypography } from './styled.components';
import { ENTER_KEY_CODE, getNewTarget, areStringsEqual } from '../common/utils';
import { IPaymentGridErrorState } from '@makemydeal/dr-dash-types';
import { disabledStyle } from './utils';

const textForFinance = `The additional payments shown are estimates, please adjust rates if needed. All payments are based on the same protection products, accessories and conditional rebates.`;
const textForLease = `All payments are based on the same protection products, accessories and conditional rebates.`;
const RateTypeMap = {
    [MONEY_FACTOR_TYPE]: MONEY_FACTOR,
    [APR]: APR
};

const SellRates: React.FC<{ isLoading?: boolean }> = ({ isLoading = false }) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const sellRates: SellRateItem[] = useSelector(offerReduxSelectors.getPaymentGridSellRates);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const selectedTermMonths = useSelector(offerReduxSelectors.getSelectedTermMonths);
    const paymentGridErrorState: IPaymentGridErrorState = useSelector(offerReduxSelectors.getPaymentGridErrorState);

    const handleChange =
        (month: number | null, currentSellRate?: number | null) => (e: InterstateOnChangeEvent<TextInputEventValue>) => {
            const value = e.target.value === '' ? null : parseFloat(e.target.value);
            if (currentSellRate === value) return;

            logNewRelicPageAction('MV:PaymentGrid - Override sell rate', {
                mvOfferType: offerType,
                mvFieldEdited: 'mv:payment:grid:override-sell-rate',
                mvDealXgId: dealXgId
            });

            const isSelectedTerm = selectedTermMonths === month;
            dispatch(
                offerReduxActionCreators.updateSellRateOverride(value, month, {
                    isSelectedTerm,
                    usePaymentGridMV: true
                })
            );
        };

    const handleKeyDownEvent = (month: number | null, currentSellRate: number | null, event: any) => {
        if (event.code === ENTER_KEY_CODE) {
            const newTarget = getNewTarget(event);
            handleChange(month, currentSellRate)(newTarget);
        }
    };

    return (
        <>
            <Grid xs={3} textAlign="center">
                <StyledTypography className="large" style={{ color: 'black' }}>
                    Sell Rate
                </StyledTypography>
            </Grid>
            {sellRates.map((item, index) => (
                <GridItem
                    xs={3}
                    className={' payment-grid-item'}
                    key={`${index}-${item.val}`}
                    paddingTop="5px"
                    border="none"
                    data-testid={`sell-rate-${index}`}
                >
                    <NumericInput
                        className="sell-rate-input"
                        data-testid={`sell-rate-input-${item.month}`}
                        name={'sell-rate'}
                        value={`${item.val ?? ''}`}
                        label={item.type ? RateTypeMap[item.type] : ' '}
                        allowDecimal={true}
                        hasError={
                            areStringsEqual(paymentGridErrorState.lastUpdatedInput, `input_sell_rate_${item.month}`) &&
                            paymentGridErrorState.hasError
                        }
                        maxValue={100}
                        errorMessage={INPUT_INVALID_ERROR_MESSAGE}
                        customErrorMessages={{ exceedsMaxValueRule: INPUT_INVALID_ERROR_MESSAGE }}
                        autoInsertCommas={false}
                        decimalMaxLength={6}
                        onBlur={handleChange(item.month, item.val)}
                        onKeyDown={(e) => handleKeyDownEvent(item.month, item.val, e)}
                        disabled={!item.month}
                        sx={{
                            ...(isLoading && disabledStyle)
                        }}
                    />
                </GridItem>
            ))}
            <Box flex="auto" padding="1rem 0">
                {offerType === FINANCE ? textForFinance : textForLease}
            </Box>
        </>
    );
};

export default SellRates;
