// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput } from '@interstate/components/SelectInput';
import { CREDIT_DECISION_STATUS } from '../../constants';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

const CreditDecisionStatusField = () => {
    const dispatch = useDispatch();
    const creditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const creditDecisionStatus = useSelector(offerReduxSelectors.getCreditDecisionStatus);
    const options = ['None', 'Approved', 'ConditionallyApproved', 'Declined', 'Pending'].map((t) => ({ value: t, label: t }));
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);

    return (
        <SelectInput
            data-testid={'lender-decision-status'}
            id="CreditDecisionStatusField"
            label={CREDIT_DECISION_STATUS}
            name={CREDIT_DECISION_STATUS}
            onChange={(event) => {
                const action = offerReduxActionCreators.updateCreditDecisionStatus(event.target.value as string);
                dispatch(action);
            }}
            value={creditDecisionStatus}
            disabled={!creditDecisionEnabled && !isBaseDealScreenExperience}
            options={options}
            displayDeselectOption={false}
        />
    );
};

export default CreditDecisionStatusField;
