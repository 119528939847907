import { AuxButton } from '@interstate/components/Button';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { ExpandableAreaKeyProp } from './types';
import { useExpandableArea } from './useExpandableArea';
import { DEFAULT_EXPANDABLE_ID, EXPANDABLE_ROOT } from '../../constants';

export type ExpandableAreaSectionProp = ExpandableAreaKeyProp &
    PropsWithChildren & {
        collapsedDisplay?: ReactNode | string;
        expandedDisplay?: ReactNode | string;
    };

/**
 * A trigger component to toggle collapse with an associated CollapsibleArea. Use collapsibleId to differentiate areas when using multiple. Expanded and collapsed text props are Show/Hide by default.
 * @param props
 */
export const ExpandableAreaSection: FC<ExpandableAreaSectionProp> = ({
    expandableId = DEFAULT_EXPANDABLE_ID,
    children,
    expandedDisplay = 'Hide Details',
    collapsedDisplay = 'Show Details',
    ...additionalButtonProps
}) => {
    const { doToggle, isExpanded, doToggleAll } = useExpandableArea();
    const expanded = isExpanded(expandableId);
    const isRootLevel = expandableId === EXPANDABLE_ROOT;

    const handleClick: any = (e: Event) => {
        if (isRootLevel) {
            // if root level is clicked, then expand or collapse all levels
            doToggleAll();
        } else {
            doToggle(expandableId);
        }

        e.stopPropagation();
    };

    const handleKeyDown: any = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (isRootLevel && e.key === 'Enter') {
            // Handle the expansion of the item when the Enter Key is pressed.
            doToggleAll();
        }
        e.stopPropagation();
    };

    if (children) {
        return (
            <div
                onClick={handleClick}
                {...additionalButtonProps}
                {...(isRootLevel && {
                    id: EXPANDABLE_ROOT,
                    tabIndex: 0,
                    onKeyDown: handleKeyDown
                })}
            >
                {children}
            </div>
        );
    }
    return (
        <AuxButton onClick={handleClick} {...additionalButtonProps}>
            {expanded && <>{expandedDisplay}</>}
            {!expanded && <>{collapsedDisplay}</>}
        </AuxButton>
    );
};
