// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { paymentPayloadUtils } from '@makemydeal/dr-offer-redux';

// actions
import {
    REQUEST_ROLL_TO_PAYMENTS,
    REQUEST_ROLL_TO_PAYMENTS_FAILURE,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS
} from '../actionTypes/offerActionTypes';

// consts/enums
import { APPLICATION_NAME } from '../constants';

// utils
import { getCurrentOfferType, getSelectedTerm } from '../selectors/offerRedux';

export const getRollToPayment = (monthlyPayment: number) => (dispatch: any, getState: any) => {
    const state = getState();
    const currentOfferType = getCurrentOfferType(state);
    const currentSelectedTerm = getSelectedTerm(state, currentOfferType);
    const hostSelectors = { isManagerView: true } as any;

    const paymentRollTarget = monthlyPayment;

    const payload = paymentPayloadUtils.createPaymentPayload(state, hostSelectors, {
        type: REQUEST_ROLL_TO_PAYMENTS,
        meta: {
            excludeOverrides: true,
            sourceApplication: APPLICATION_NAME,
            isRollToCall: true
        }
    });

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'user-program-rolls');
    const endpoint = bffApiUrlResult.url;

    const rollToPayload = {
        ...payload,
        payment: {
            ...payload.payment,
            paymentRoll: paymentRollTarget
        }
    };

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            body: JSON.stringify(rollToPayload),
            headers: { 'Content-Type': 'application/json' },
            types: [REQUEST_ROLL_TO_PAYMENTS, { type: REQUEST_ROLL_TO_PAYMENTS_SUCCESS }, REQUEST_ROLL_TO_PAYMENTS_FAILURE]
        }
    });
};
