import { useMemo, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { formatSingleCardViewPaymentDetails, formatPaymentDetailsCashDown } from '@makemydeal/dr-dash-components';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { offerReduxSelectors, offerSelectors, deskingGlobalActionCreators } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { ArrowPathIcon } from '@interstate/components/Icons/ArrowPathIcon';
import { DeskingCardLeftPanelContent } from './DeskingCard.style';
import { StyledPaymentTitle } from './DerskingCardTitle.style';
import { Typography } from '@interstate/components/Typography';
import { CASH } from '@makemydeal/dr-platform-types';
import { Box } from '@interstate/components/Box';

interface Props {
    isSingleCardView?: boolean;
}

export const DeskingCardPaymentDetailsSidePanel: React.FC<Props> = ({ isSingleCardView }) => {
    const ARROW_PATH_ICON_COLOR = '#005ba8';
    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const amountToBePaidByCustomer = useSelector((state) => offerReduxSelectors.getDownPaymentByOfferType(state, currentOfferType));
    const paymentDetailLabel = useMemo(() => {
        return formatSingleCardViewPaymentDetails(currentOfferType, monthlyPayment);
    }, [currentOfferType, monthlyPayment]);
    const paymentDetailCashDownLabel = useMemo(() => {
        return formatPaymentDetailsCashDown(currentOfferType, amountToBePaidByCustomer);
    }, [currentOfferType, amountToBePaidByCustomer]);
    const rollToPaymentFeatureToggle = useSelector(featureToggleSelectors.enableGetRollToPayment);
    const handleToggleSlideOut = (e: MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        dispatch(deskingGlobalActionCreators.toggleRollToPayment());
    };

    return (
        <>
            <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'single-card-view'}>
                <Typography sx={{ flexShrink: 0 }} data-testid="desking-card-payment-details-side-panel-title" variant={'h3'}>
                    Payment Details
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography data-testid="desking-card-payment-details-side-panel-detail-label" variant="h3">
                        {paymentDetailLabel}
                    </Typography>
                    {isSingleCardView && rollToPaymentFeatureToggle && (
                        <div onClick={handleToggleSlideOut} data-testid="roll-to-payment" style={{ marginLeft: '1rem' }}>
                            <ArrowPathIcon fill={ARROW_PATH_ICON_COLOR} style={{ cursor: 'pointer' }} />
                        </div>
                    )}
                </Box>
                {currentOfferType !== CASH && (
                    <Typography
                        sx={{ color: '#5a5a5a', textAlign: 'right', flexGrow: 1 }}
                        data-testid="desking-card-payment-details-side-panel-cash-down-label"
                        variant="body-xs"
                    >
                        {paymentDetailCashDownLabel}
                    </Typography>
                )}
            </StyledPaymentTitle>

            <DeskingCardLeftPanelContent>
                <InterstateDealSummary />
            </DeskingCardLeftPanelContent>
        </>
    );
};
