// CSS style components
import { MouseEventHandler } from 'react';
import { titleStyle } from './RollToStyles';

// Interstate component
import { Button } from '@interstate/components/Button';

type RollToPaymentHeaderProps = {
    gridAction?: MouseEventHandler<HTMLButtonElement>;
    gridCategory?: string;
    actionMessage?: string;
};

const RollToPaymentHeader: React.FC<RollToPaymentHeaderProps> = ({ gridCategory = '', gridAction, actionMessage }) => {
    return (
        <div style={titleStyle}>
            <h3>{gridCategory}</h3>
            {actionMessage && (
                <Button size="small" buttonStyle="tertiary" onClick={gridAction}>
                    {actionMessage}
                </Button>
            )}
        </div>
    );
};

export default RollToPaymentHeader;
