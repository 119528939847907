import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';

import { smartOfferSaveSelectors } from '@makemydeal/dr-dash-store';

import { Snackbar } from '@interstate/components/Snackbar';

import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import { getTimeout } from './unsavedChangesUtils';

const defaultTimeout = 10 * 60 * 1000; // 10 minutes

const BEFORE_UNLOAD_EVENT = 'beforeunload';
const USER_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
];

export type UnsavedChangesManagerProps = {
    /** Timeout duration in seconds after which the Unsaved Changes Dialog will appear */
    timeoutProp?: number;
};

// Reusable toggler for boolean state updates
// istanbul ignore next
const toggler = (prev: boolean) => !prev;

export const UnsavedChangesManager = ({ timeoutProp = defaultTimeout }: UnsavedChangesManagerProps) => {
    const timeout = /* istanbul ignore next */ getTimeout() || timeoutProp; // first branch covered by integration tests
    const store = useStore();
    const timerRef = useRef(null as any);

    // Unsaved Changes dialog
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);

    // callback not executed by this component, ignore coverage
    // istanbul ignore next
    const handleHide = useCallback(() => setShowUnsavedChangesDialog(false), []);

    // Manages the Snackbar visibility
    const [showUpdateSuccessSnackbar, setUpdateSuccessNotification] = useState(false);
    const [showDraftSuccessSnackbar, setSaveDraftSuccessNotification] = useState(false);

    // some of these behaviors are passed as callbacks, coverage provided by UI integration tests
    // istanbul ignore next
    const toggleUpdateSuccessSnackbar = () => setUpdateSuccessNotification(toggler);
    // istanbul ignore next
    const toggleDraftSuccessSnackbar = () => setSaveDraftSuccessNotification(toggler);

    const showDialogIfUnsavedChangesExist = useCallback(() => {
        const state: any = store.getState();
        const hasUnsavedChanges = smartOfferSaveSelectors.currentOfferHasUnsavedChanges(state);
        if (hasUnsavedChanges) {
            setShowUnsavedChangesDialog(true);
        }
    }, [store]);

    // Timer management
    // For the hardcoded list of DOM events, restart the timer
    useEffect(() => {
        const restartTimer = () => {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(showDialogIfUnsavedChangesExist, timeout);
        };
        USER_EVENTS.forEach((event) => {
            addEventListener(event, restartTimer);
        });
        return () => {
            USER_EVENTS.forEach((event) => {
                removeEventListener(event, restartTimer);
            });
            clearTimeout(timerRef.current);
        };
    }, [showDialogIfUnsavedChangesExist, timeout]);

    // Use the state changes of the dialog visibility to manage the timer
    useEffect(() => {
        // If the dialog visibility changes, clear the timer
        clearTimeout(timerRef.current);
        if (!showUnsavedChangesDialog) {
            // If the dialog is dismissed, start the timer
            timerRef.current = setTimeout(showDialogIfUnsavedChangesExist, timeout);
        }
        return () => {
            clearTimeout(timerRef.current);
        };
    }, [showUnsavedChangesDialog, showDialogIfUnsavedChangesExist, timeout]);

    // Manage beforeunload event. Purposely creating the callback once, so add/remove event listeners
    // refer to the same function in memory, so exempting the exhaustive deps rule for the hook and effect
    const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
        const state: any = store.getState();
        const hasUnsavedChanges = smartOfferSaveSelectors.currentOfferHasUnsavedChanges(state);
        if (hasUnsavedChanges) {
            event.preventDefault();
            setShowUnsavedChangesDialog(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        addEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
        return () => {
            removeEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showUnsavedChangesDialog && (
                <UnsavedChangesDialog
                    onHide={handleHide}
                    onDraftSuccess={toggleDraftSuccessSnackbar}
                    onUpdateSuccess={toggleUpdateSuccessSnackbar}
                />
            )}
            <Snackbar
                data-testid="update-offer-success-unsaved-changes"
                show={showUpdateSuccessSnackbar}
                message="We've updated your changes to this offer."
                position="bottom-center"
                onClose={toggleUpdateSuccessSnackbar}
                type="success"
            />
            <Snackbar
                data-testid="update-draft-success-unsaved-changes"
                show={showDraftSuccessSnackbar}
                message="Successfully Saved as Draft!"
                position="bottom-center"
                onClose={toggleDraftSuccessSnackbar}
                type="success"
            />
        </>
    );
};

export default UnsavedChangesManager;
