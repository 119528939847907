// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import {
    ISaveOffer,
    SaveOfferDealer,
    SaveOfferMenuProductsPayload,
    SaveOfferPayload,
    SaveOfferTradePayload,
    SoftSaveDealer,
    SoftSaveOffer,
    SoftSaveOfferRequest
} from '@makemydeal/dr-dash-bff-types';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { paymentActionTypes } from '@makemydeal/dr-offer-redux';
import type { OfferType } from '@makemydeal/dr-platform-types';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { scopedStateSelectors } from '@makemydeal/shared-scoped-store';
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil, queryParamStore } from '@makemydeal/dr-shared-ui-utils';

// interfaces/types
import type { SavedOfferPayloadObject } from '../types/comparisonUtilsTypes';
import { AccessoriesAdapter } from '../utils/manualAccessoriesAdapter';

// selectors
import { getAccessories } from '../selectors/accessoriesSelectors';
import * as bridgeUserSelectors from '../selectors/bridgeUserSelectors';
import { createCalculationDetailsForOfferSave } from '../selectors/calculationDetailsSelectors';
import * as connectionSelectors from '../selectors/connectionSelectors';
import * as offerSelectors from '../selectors/offerSelectors';
import * as vehicleSelectors from '../selectors/vehicleSelectors';
import * as offerRedux from './offerRedux';

// consts/enums
import {
    COBUYER_SHOW_INVALID_MODAL,
    LINKS_FAILURE,
    LINKS_REQUEST,
    LINKS_SUCCESS,
    OFFER_SAVE_FAILURE,
    OFFER_SAVE_NOCHANGE_REQUEST,
    OFFER_SAVE_NOCHANGE_SUCCESS,
    OFFER_SAVE_REQUEST,
    OFFER_SAVE_SUCCESS,
    SAVE_OFFER,
    SOFT_SAVE_FAILURE,
    SOFT_SAVE_REQUEST,
    SOFT_SAVE_SUCCESS,
    UPDATED_MANUAL_INCENTIVES,
    UPDATED_TOTAL_TAX_OVERRIDE,
    UPDATED_TOTAL_TAX_UPFRONT_OVERRIDE,
    UPDATE_DEAL_REF_ID,
    UPDATE_PAYMENT_FEES_OVERRIDE
} from '../actionTypes/offerActionTypes';
import { SavedOfferPayloadObjectComparison } from '../types/comparisonUtilsEnums';
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';

// utils
import { FeeOverride } from '@makemydeal/dr-platform-types/typedefs/shared/types/paymentServices';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { ACQUISITION_FEE_ID } from '../constants';
import {
    getInternalPrevOfferPayload,
    getInternalPrevOfferPayloadDealXgId,
    getInternalPrevOfferPayloadDealXgVersion
} from '../selectors/internalPrevOfferSelectors';
import { hasLinks } from '../selectors/offerInfoSelectors';
import { getCurrentOfferType, getFeesOverride, getIsUserProgramQuotes } from '../selectors/offerRedux';
import { compareOfferPayloadObjects } from '../utils/comparisonUtils';
import { buildEmployeeDetails } from '../utils/employeeUtils';
import { buildIncentivesNode } from '../utils/incentivesUtil';
import { consoleLogComparisonInfo } from '../utils/loggingUtils';
import { toOfferTypeSpecificFormat } from '../utils/manualAccessoriesUtils';
import { createMenuPayload } from '../utils/menuPayloadUtil';
import { createDealerNode, createGatewayOfferPayload } from '../utils/offerPayloadUtil';
import { savedOfferPayloadRegistry } from '../utils/savedOfferPayloadRegistry';
import { createTradePayload } from '../utils/tradePayloadUtil';
import { AnyFSA } from '@makemydeal/dr-platform-shared';

export type saveOfferRSAAOptions = SaveOfferOptions & {
    state: StateTree;
    offerSavePayload: SavedOfferPayloadObject;
    offerSavePayloadIndex?: number;
};

export type SaveOfferOptions = {
    source?: string;
    isDraft?: boolean;
    originalAction?: string;
};

export const saveOffer = (opts?: SaveOfferOptions) => {
    const action: AnyFSA = {
        type: SAVE_OFFER
    };
    if (!opts) {
        return action;
    }
    action.meta = {
        ...(opts.source && { source: opts.source }),
        ...(opts.isDraft != null && { isDraft: opts.isDraft }),
        ...(opts.originalAction && { originalAction: opts.originalAction })
    };
    return action;
};

export const buildOfferSavePayloadObject = (state: StateTree, purpose = BuildPayloadPurpose.Unknown): ISaveOffer => {
    if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
        return buildMultipleOfferSavePayload(state);
    }

    return buildOfferSavePayload(state, purpose);
};

export const buildOfferSavePayload = (state: StateTree, purpose = BuildPayloadPurpose.Unknown): ISaveOffer => {
    const offerType = getCurrentOfferType(state);
    const connectionId = connectionSelectors.getConnectionId(state);
    const vin = vehicleSelectors.getVehicleVin(state);
    const username = bridgeUserSelectors.getBridgeUsername(state);
    const employees = buildEmployeeDetails(state);
    const incentives = buildIncentivesNode(state);
    const accessories = toOfferTypeSpecificFormat(AccessoriesAdapter.toTargetFormat(getAccessories(state)), offerType);
    const menuPayload: SaveOfferMenuProductsPayload = createMenuPayload(state);
    const offerPayload: SaveOfferPayload = createGatewayOfferPayload(state, undefined, purpose);
    const tradePayload: SaveOfferTradePayload = createTradePayload(state);
    const dealerPayload: SaveOfferDealer = createDealerNode(state);
    const calculationDetails = createCalculationDetailsForOfferSave(state);
    const offerSavePayload: ISaveOffer = {
        vin,
        menu: menuPayload,
        offer: offerPayload,
        trade: tradePayload,
        dealer: dealerPayload,
        username,
        employees,
        incentives,
        accessories,
        connectionId,
        calculationDetails,
        purpose
    };
    return offerSavePayload;
};

// In the near future, this state type will be solidified
export const buildMultipleOfferSavePayload = (state: any): ISaveOffer => {
    const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state);
    const primaryScopeState = scopedStateSelectors.getScopeStateById(state, primaryScopeId);

    const primaryOfferPayload = buildOfferSavePayload(primaryScopeState as StateTree);

    const orderedScopeIds = scopedStateSelectors.getActiveScopesOrder(state);
    const alternateScenarios: ISaveOffer[] = [];
    for (const scopeId of orderedScopeIds) {
        if (scopeId !== primaryScopeId) {
            const scopeState = scopedStateSelectors.getScopeStateById(state, scopeId);
            const alternateScenario = buildOfferSavePayload(scopeState as StateTree);
            alternateScenario.meta = {
                scenarioId: scopeId
            };
            alternateScenarios.push(alternateScenario);
        }
    }
    if (alternateScenarios.length) {
        primaryOfferPayload.comparisonInfo = {
            order: orderedScopeIds,
            alternateScenarios
        } as any;
        primaryOfferPayload.meta = {
            scenarioId: primaryScopeId
        };
    }
    return primaryOfferPayload;
};

export const buildSoftSavePayloadObject = (state: StateTree, purpose = BuildPayloadPurpose.Unknown) => {
    const fullOfferSavePayload: SaveOfferPayload = createGatewayOfferPayload(state, undefined, purpose);
    const offerPayload: SoftSaveOffer = {
        dealerId: fullOfferSavePayload.dealerId,
        dealExchangeDealId: fullOfferSavePayload.dealExchangeDealId,
        dealExchangeVersionId: fullOfferSavePayload.dealExchangeVersionId,
        transferToDMS: fullOfferSavePayload.transferToDMS
    };
    const fullDealerPayload: SaveOfferDealer = createDealerNode(state);
    const dealerPayload: SoftSaveDealer = {
        dealerId: fullDealerPayload.dealerId
    };
    const offerSavePayload: SoftSaveOfferRequest = {
        offer: offerPayload,
        dealer: dealerPayload,
        purpose
    };
    return offerSavePayload;
};

/**
 * @deprecated - Please invoke or extend the `saveOffer` action creator rather than dispatching this action directly
 *
 * This offer save logic replaces the legacy always-make-api-call logic and only makes the calls
 * when the offer payload has changed.  If the offer payload doesn't change from the last call it
 * simply returns the same Deal XG ID and Version from the last API call.
 */
export const smartOfferSave = (state: StateTree, source?: string, purpose = BuildPayloadPurpose.OfferSave) => {
    const offerSavePayload = buildOfferSavePayloadObject(state, purpose);
    const offerSavePayloadIndex = savedOfferPayloadRegistry.queueOfferPayloadObject(offerSavePayload);
    const lastOfferPayloadObject = getInternalPrevOfferPayload(state);
    const comparisonResult = compareOfferPayloadObjects(lastOfferPayloadObject, offerSavePayload);
    if (comparisonResult.simpleResult === SavedOfferPayloadObjectComparison.Different) {
        if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
            /* eslint-disable-next-line no-console */
            console.log('DASH DEBUG: "Smart Offer Save" offer payload different, making API call');
            consoleLogComparisonInfo(comparisonResult);
        }
        return saveOfferRSAA({ state, offerSavePayload, offerSavePayloadIndex, source });
    } else {
        if (purpose === BuildPayloadPurpose.PushToDms && featureToggleSelectors.isPushToDmsSmartSaveEnabled(state)) {
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                /* eslint-disable-next-line no-console */
                console.log('DASH DEBUG: "Smart Offer Save" offer payload not different, performing soft save');
            }
            const dealXgId = getInternalPrevOfferPayloadDealXgId(state);
            const dealXgVersion = getInternalPrevOfferPayloadDealXgVersion(state);
            return buildSoftSaveRequest(state, dealXgId, dealXgVersion, source, purpose);
        } else if (!hasLinks(state)) {
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                /* eslint-disable-next-line no-console */
                console.log('DASH DEBUG: "Smart Offer Save" offer payload not different, fetching links');
            }
            const dealXgId = getInternalPrevOfferPayloadDealXgId(state);
            const dealXgVersion = getInternalPrevOfferPayloadDealXgVersion(state);
            return buildLinksRequest(dealXgId, dealXgVersion, source);
        } else {
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                /* eslint-disable-next-line no-console */
                console.log('DASH DEBUG: "Smart Offer Save" offer payload not different, skipping API call');
            }
            const dealXgId = getInternalPrevOfferPayloadDealXgId(state);
            const dealXgVersion = getInternalPrevOfferPayloadDealXgVersion(state);
            return offerSaveNoChangeRequest(dealXgId, dealXgVersion, source);
        }
    }
};

export const saveOfferRSAA = (options: saveOfferRSAAOptions) => {
    const { state, offerSavePayload, offerSavePayloadIndex, source, isDraft = false } = options;
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'offer/save');
    const endpoint = bffApiUrlResult.url;
    const enableEnhancedPushToDmsPlusMV = featureToggleSelectors.enableEnhancedPushToDmsPlusMV(state);
    const dmsIntegrationToggle = dealerSelectors.getDMSIntegrationToggle(state);
    const enableDraftScenarioPersistence = featureToggleSelectors.enableDraftScenarioPersistence(state);

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', isDraft: String(isDraft) },
            body: JSON.stringify(offerSavePayload),
            types: [
                { type: OFFER_SAVE_REQUEST, meta: { isDraft } },
                {
                    type: OFFER_SAVE_SUCCESS,
                    meta: {
                        source,
                        isDraft,
                        offerSavePayloadIndex,
                        enableEnhancedPushToDmsPlusMV,
                        dmsIntegrationToggle,
                        enableDraftScenarioPersistence
                    }
                },
                {
                    type: OFFER_SAVE_FAILURE,
                    meta: { source, isDraft, offerSavePayloadIndex, enableEnhancedPushToDmsPlusMV, dmsIntegrationToggle }
                }
            ]
        }
    };
};

export const buildLinksRequest = (dealXgId: string | undefined, dealXgVersion: string | undefined, source: string | undefined) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(
        MANAGER_VIEW_APP_PREFIX,
        locHref,
        `/deal/${dealXgId}/version/${dealXgVersion}/links`
    );
    const endpoint = bffApiUrlResult.url;
    const meta = {
        source
    };
    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                {
                    type: LINKS_REQUEST,
                    meta
                },
                {
                    type: LINKS_SUCCESS,
                    meta
                },
                {
                    type: LINKS_FAILURE,
                    meta
                }
            ]
        }
    };
};

export const buildSoftSaveRequest = (
    state: StateTree,
    dealXgId: string | undefined,
    dealXgVersion: string | undefined,
    source: string | undefined,
    purpose?: BuildPayloadPurpose | undefined
) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(
        MANAGER_VIEW_APP_PREFIX,
        locHref,
        `/deal/${dealXgId}/version/${dealXgVersion}/soft-save`
    );
    const enableEnhancedPushToDmsPlusMV = featureToggleSelectors.enableEnhancedPushToDmsPlusMV(state);
    const dmsIntegrationToggle = dealerSelectors.getDMSIntegrationToggle(state);
    const endpoint = bffApiUrlResult.url;
    const meta = {
        source,
        enableEnhancedPushToDmsPlusMV,
        dmsIntegrationToggle
    };
    const softSavePayload = buildSoftSavePayloadObject(state, purpose);
    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(softSavePayload),
            types: [
                {
                    type: SOFT_SAVE_REQUEST,
                    meta
                },
                {
                    type: SOFT_SAVE_SUCCESS,
                    meta
                },
                {
                    type: SOFT_SAVE_FAILURE,
                    meta
                }
            ]
        }
    };
};

export const offerSaveNoChangeRequest = (
    dealXgId: string | undefined,
    dealXgVersion: string | undefined,
    source: string | undefined
) => ({
    type: OFFER_SAVE_NOCHANGE_REQUEST,
    payload: {
        dealXgId,
        dealXgVersion
    },
    meta: {
        source
    }
});

export const offerSaveNoChangeSuccess = (
    dealXgId: string | undefined,
    dealXgVersion: string | undefined,
    source: string | undefined
) => ({
    type: OFFER_SAVE_NOCHANGE_SUCCESS,
    payload: {
        dealXgId,
        dealXgVersion
    },
    meta: {
        source
    }
});

export const updateAcqFeeOverride = (amount: number) => (dispatch: any, getState: any) => {
    const acqFeeUpFrontOverride = offerSelectors.getAcqFeeUpFrontOverrideWithFallback(getState());
    let feesOverride = getFeesOverride(getState());
    feesOverride = feesOverride ? feesOverride : [];
    const acqFee: any = feesOverride.find((fee: any) => fee.feeTypeId === ACQUISITION_FEE_ID);
    if (acqFee) {
        acqFee.feeAmount = amount;
    } else {
        feesOverride.push({
            feeTypeId: ACQUISITION_FEE_ID,
            feeAmount: amount,
            reqCapIndicator: !acqFeeUpFrontOverride,
            feeDescription: '',
            feeName: 'Acquisition Fee',
            category: FeesCategoriesType.LENDER,
            includeInCalcIndicator: true
        });
    }
    dispatch(updatedFeesOverride(feesOverride));
};

export const updateAcqFeeUpFrontOverride = (enabled: boolean) => (dispatch: any, getState: any) => {
    const amount = offerSelectors.getAcqFeeOverrideWithFallback(getState());
    let feesOverride = getFeesOverride(getState());
    feesOverride = feesOverride ? feesOverride : [];
    const acqFee: any = feesOverride.find((fee: any) => fee.feeTypeId === ACQUISITION_FEE_ID);
    if (acqFee) {
        acqFee.reqCapIndicator = !enabled;
    } else {
        feesOverride.push({
            feeTypeId: ACQUISITION_FEE_ID,
            feeAmount: amount,
            reqCapIndicator: !enabled,
            feeDescription: '',
            feeName: 'Acquisition Fee',
            category: FeesCategoriesType.LENDER,
            includeInCalcIndicator: true
        });
    }
    dispatch(updatedFeesOverride(feesOverride));
};

export const applyProgramEndDays = () => ({
    type: paymentActionTypes.PROGRAMS_END_DAYS,
    payload: 7
});

export const updateManualIncentives = ({
    manualIncentives,
    dealerCashTotal,
    totalRebates,
    skipPayment
}: {
    manualIncentives: any[] | undefined;
    dealerCashTotal: number;
    totalRebates: number;
    skipPayment?: boolean;
}) => {
    let payload: any;
    if (manualIncentives == null) payload = { manualIncentives: undefined };
    else
        payload = {
            manualIncentives: {
                dealerCash: dealerCashTotal,
                incentives: manualIncentives,
                totalRebates
            }
        };

    return {
        type: UPDATED_MANUAL_INCENTIVES,
        payload,
        ...(!skipPayment && {
            meta: {
                middleware: {
                    payment: true
                }
            }
        })
    };
};

export const updateShowCoBuyerInvalidModal = (visible: boolean) => ({
    type: COBUYER_SHOW_INVALID_MODAL,
    payload: visible
});

export const updatedOfferType =
    (offerType: OfferType): any =>
    (dispatch: any, getState: any) => {
        const state = getState();

        if (getIsUserProgramQuotes(state)) {
            dispatch({
                type: 'UPDATE_OVERRIDES',
                payload: offerType
            });
        }

        dispatch(offerRedux.updatedOfferType(offerType, { suppressAnalytics: true }));
    };

export const updatedTotalTaxOverride = (overrideValue?: number) => {
    return {
        meta: {
            middleware: {
                payment: true
            }
        },
        type: UPDATED_TOTAL_TAX_OVERRIDE,
        payload: overrideValue
    };
};

export const updatedTotalTaxUpfrontOverride = (overrideValue: boolean) => {
    return {
        meta: {
            middleware: {
                payment: true
            }
        },
        type: UPDATED_TOTAL_TAX_UPFRONT_OVERRIDE,
        payload: overrideValue
    };
};

export const updatedFeesOverride = (fees: FeeOverride[]) => {
    return {
        meta: {
            middleware: {
                payment: true
            }
        },
        type: UPDATE_PAYMENT_FEES_OVERRIDE,
        payload: fees
    };
};

export const updateDealRefId = ({ dealRefId }: { dealRefId: string }) => {
    return {
        type: UPDATE_DEAL_REF_ID,
        payload: {
            dealRefId
        }
    };
};
