// externals
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import CheckoutAnywhereDrawer from './CheckoutAnywhereDrawer';
import PushToRouteOneDialog from '../pushToRouteOne/PushToRouteOneDialog';
import PushToRouteOneButton from '../pushToRouteOne/PushToRouteOneButton';

// style
import { ButtonsContainerInterstate, Container } from './FinishDealCard.style';

// selectors
import {
    configSelectors,
    creditAppSelectors,
    deskingActionCreators,
    offerActionCreators,
    offerInfoSelectors,
    offerReduxSelectors,
    offerSelectors,
    pushToDarwinActionCreators,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { LabelMappings } from '../DealActivityTypes';

// constants
import { apiConfigConsts, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { ShowWithSelector } from '../../utils/ShowWithSelector';
import { UpdateOfferDialog } from '../../UpdateOfferDialog/UpdateOfferDialog';
import { CASH } from '@makemydeal/dr-platform-types';
import { ShowWithFeatureToggle } from '../../utils/ShowWithFeatureToggle';
import PushToDms from './PushToDms.interstate';

const FinishDealCard = () => {
    const [showPushToR1Dialog, setShowPushToR1Dialog] = useState(false);
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);
    const enablePushToR1 = useSelector(featureToggleSelectors.enablePushToR1);
    const enablePatchDealRefId = useSelector(featureToggleSelectors.enablePatchDealRefId);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;
    const dealRefId = useSelector(offerSelectors.getDealRefId) || offerSessionUtils.getSessionValue(apiConfigConsts.FS_DEAL_REF_ID);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const creditProvider = useSelector(dealerSelectors.getCreditProvider);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const creditAppCompleted = useSelector(creditAppSelectors.isCreditAppCompleted);
    const [showOfferSaveForFni, setShowOfferSaveForFni] = useState(false);

    const [openFni, setOpenFni] = useState(false);
    const [waitForWS, setWaitForWS] = useState(true);

    const enablePushToR1CTA = enablePushToR1 && creditProvider !== 'dealertrack' && offerType !== CASH;

    useEffect(() => {
        if (openFni && dealRefId) {
            setWaitForWS(false);
            openFniInNewTab();
            setOpenFni(false);
        }
    }, [dealRefId]);

    const parseUnifiDeepLinkFromConfigUrl = (configUrl: any) => {
        if (configUrl.length > 0) {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            configUrl = `${configUrl}/dealjacket_deal_summary/${fsProvider}/${dealRefId}/`;
        }
        return configUrl;
    };

    const openFniInNewTab = () => {
        const url: string = parseUnifiDeepLinkFromConfigUrl(configUrl);
        window.open(url, '_blank');
    };

    const openUniFiDeepLinkUrl = () => {
        if (enablePatchDealRefId && !dealRefId) {
            setOpenFni(true);
            dispatch(offerActionCreators.saveOffer());
            setShowOfferSaveForFni(true);
        } else {
            openFniInNewTab();
        }
    };

    // istanbul ignore next
    const handlePushToR1DialogShow = () => {
        setShowPushToR1Dialog(true);
    };

    // istanbul ignore next
    const handleHidePushToR1Dialog = () => {
        setShowPushToR1Dialog(false);
    };

    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [isCheckoutAnywhereDrawerEnabled, setIsCheckoutAnywhereDrawerEnabled] = useState(false);
    const dispatch = useDispatch();

    const handleSaveOffer = () => {
        if (isCDL3InMVEnabled) {
            dispatch(deskingActionCreators.beginCheckoutClicked());
        }

        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOffer(true);
    };

    const handlePushToDarwin = () => {
        dispatch(pushToDarwinActionCreators.pushToDarwin());
    };

    return (
        <Container>
            <Typography variant="h3" color="base.color.black" sx={{ padding: '16px 0', margin: 0 }}>
                {LabelMappings['additionalActions']}
            </Typography>
            <ButtonsContainerInterstate>
                <ShowWithSelector
                    selector={dealerSelectors.enableCheckoutAnywhereToggle}
                    active={
                        <>
                            <UpdateOfferDialog
                                show={showUpdateOffer}
                                onHide={() => setShowUpdateOffer(false)}
                                onSuccess={() => setIsCheckoutAnywhereDrawerEnabled(true)}
                            />
                            <Button
                                sx={{ backgroundColor: 'transparent' }}
                                className="interstate"
                                buttonStyle="secondary"
                                size="medium"
                                onClick={handleSaveOffer}
                                data-testid="open-checkout-link"
                                block
                            >
                                Begin Checkout
                            </Button>
                        </>
                    }
                />
                {isCheckoutAnywhereDrawerEnabled && (
                    <CheckoutAnywhereDrawer
                        setShowUpdateOffer={setShowUpdateOffer}
                        setIsOpenSlider={setIsCheckoutAnywhereDrawerEnabled}
                        isOpenSlider={isCheckoutAnywhereDrawerEnabled}
                    />
                )}

                <Button
                    sx={{ backgroundColor: 'transparent' }} // TODO: Waiting for transparent background on secondary buttons
                    className="interstate"
                    buttonStyle="secondary"
                    size="medium"
                    disabled={enablePatchDealRefId ? false : !dealRefId}
                    onClick={openUniFiDeepLinkUrl}
                    data-testid="finalizeDeal-link"
                    block
                >
                    Open in F&I
                </Button>
                <PushToDms />
                {enablePushToDarwin && (
                    <Button
                        block
                        sx={{ backgroundColor: 'transparent' }}
                        className="interstate"
                        buttonStyle="secondary"
                        data-testid="push-to-darwin-button"
                        size="medium"
                        onClick={handlePushToDarwin}
                    >
                        Push to Darwin
                    </Button>
                )}
            </ButtonsContainerInterstate>

            {enablePushToR1CTA && creditAppCompleted && <PushToRouteOneButton onClick={handlePushToR1DialogShow} />}

            <PushToRouteOneDialog
                data-testid="push-to-route-one-dialog"
                show={showPushToR1Dialog}
                onHide={handleHidePushToR1Dialog}
            />
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog show={showOfferSaveForFni} onHide={() => setShowOfferSaveForFni(false)} waitForWS={waitForWS} />
            </ShowWithFeatureToggle>
        </Container>
    );
};

export default FinishDealCard;
