import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

export const getCashDealLabel = (totalAmountDue: number) => {
    return `C, ${formatDollarsAndCents(totalAmountDue)}`;
};

export const getLeaseDealLabel = ({
    amountPaidByCustomer,
    months = 0,
    monthlyPayment,
    apr
}: {
    amountPaidByCustomer: number;
    months?: number;
    monthlyPayment: number;
    apr: number;
}) => {
    return [
        `L${months}, ${formatDollarsAndCents(monthlyPayment)}/mo - ${apr}%APR`,
        `Amount Paid by Customer: ${formatDollarsAndCents(amountPaidByCustomer)}`
    ];
};

export const getFinanceDealLabel = ({
    downPayment,
    months = 0,
    monthlyPayment,
    apr
}: {
    downPayment: number;
    months?: number;
    monthlyPayment: number;
    apr: number;
}) => {
    return [
        `F${months}, ${formatDollarsAndCents(monthlyPayment)}/mo - ${apr}%APR`,
        `Cash Down: ${formatDollarsAndCents(downPayment)}`
    ];
};
