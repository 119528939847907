import { Action } from '@interstate/components/Action';
import { navigationActionCreators, offerActionCreators, offerSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@interstate/components/Typography';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';
import { useEffect, useState } from 'react';
import { UpdateOfferDialog } from '../../UpdateOfferDialog/UpdateOfferDialog';
import { apiConfigConsts, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { ShowWithFeatureToggle } from '../../utils/ShowWithFeatureToggle';

const DigitalDealJacketLink = () => {
    const [showSaveOfferForDdj, setShowSaveOfferForDdj] = useState(false);
    const enablePatchDealRefId = useSelector(featureToggleSelectors.enablePatchDealRefId);
    const dealRefId = useSelector(offerSelectors.getDealRefId) || offerSessionUtils.getSessionValue(apiConfigConsts.FS_DEAL_REF_ID);
    const [waitForWS, setWaitForWS] = useState(true);
    const [openDdj, setOpenDdj] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (openDdj && dealRefId) {
            setWaitForWS(false);
            dispatch(navigationActionCreators.navigateToDDJ());
            setOpenDdj(false);
        }
    }, [dealRefId]);
    return (
        <>
            <Typography variant="h3" sx={{ marginBottom: '0px', marginTop: '10px' }}>
                Digital Deal Jacket
            </Typography>
            <Action
                data-testid="digital-deal-jacket-link"
                sx={{ textAlign: 'left' }}
                onClick={() => {
                    if (enablePatchDealRefId && !dealRefId) {
                        setOpenDdj(true);
                        dispatch(offerActionCreators.saveOffer());
                        setShowSaveOfferForDdj(true);
                    } else {
                        dispatch(navigationActionCreators.navigateToDDJ());
                    }
                }}
            >
                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                    Manage Documents
                    <ChevronRightIcon fontSize={10} />
                </Typography>
            </Action>
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog show={showSaveOfferForDdj} onHide={() => setShowSaveOfferForDdj(false)} waitForWS={waitForWS} />
            </ShowWithFeatureToggle>
        </>
    );
};

export default DigitalDealJacketLink;
