export enum BaseTabs {
    SUMMARY = 'summary',
    TRADE_IN = 'trade-in',
    FEES = 'fees',
    TAXES = 'taxes',
    PROTECTION = 'protection',
    INCENTIVES = 'incentives',
    LENDER = 'lender',
    ACCESSORIES = 'accessories'
}
