export const formatValue = (label: string, value: number): string => {
    if (label === 'APR') {
        return `${value}%`;
    } else if (
        ['Down Payment', 'Selling Price', 'Trade 1 Allowance', 'Total Taxes and Fees', 'Total Add Ons', 'Payment'].includes(label)
    ) {
        return `${value.toLocaleString()}`;
    }
    return String(value);
};
