/* eslint-disable react/no-multi-comp */
// TODO: use centralized component between here and activities-desking
import { ComponentProps, FC, ReactNode, useCallback } from 'react';

// components
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

// constants
import { SUMMARY_ACCORDION_ID, ZERO_DOLLARS, EXPANDABLE_ROOT, DEAL_SUMMARY_DETAILS_EXPAND_ID } from '../../constants';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import {
    ExpandableArea,
    ExpandableAreaProvider,
    useCheckExpandableAreaProvider,
    useExpandableArea
} from '../../common/ExpandableArea';
import { skewWords } from '../../utils/formatUtils';
import { StyledExpandableAreaSection, StyledListItem } from './DealSummaryItem.style';

export interface IDealSummaryItemProps {
    label: string;
    value: string;
    children?: ReactNode;
    expandibleButtonStyles?: ComponentProps<typeof Grid>['sx'];
    expandibleAreaStyles?: ComponentProps<typeof Grid>['sx'];
    labelStyles?: ComponentProps<typeof Typography>['sx'];
    valueStyles?: ComponentProps<typeof Typography>['sx'];
    /**
     * @deprecated Unused prop
     */
    showLargeValue?: boolean;
    isEmpty?: boolean;
    /**
     * @deprecated Unused prop
     */
    isExpanded?: boolean;
    hasDelta?: boolean;
}

type LineItemRowProps = {
    styles?: ComponentProps<typeof Grid>['sx'];
    children: ReactNode;
};

const LineItemRow = ({ children, styles }: LineItemRowProps) => (
    <Grid
        container
        sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingY: '12px',
            pr: '16px',
            ...styles
        }}
    >
        {children}
    </Grid>
);

export const ChevronIcon: FC<{
    expandableId: string;
    isDealSummaryDetailsExpandId?: boolean;
    isBaseDealScreenExperience?: boolean;
}> = ({ expandableId, isDealSummaryDetailsExpandId, isBaseDealScreenExperience }) => {
    const { isExpanded, isAllExpanded } = useExpandableArea();
    const expanded = isDealSummaryDetailsExpandId ? isAllExpanded() : isExpanded(expandableId);

    return (
        <ChevronRightIcon
            style={{
                transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                marginRight: isBaseDealScreenExperience ? '15px' : '4px'
            }}
            className="chevron-icon"
            data-testid={expandableId}
        />
    );
};

export const DealSummaryItem = ({
    label,
    value,
    children,
    isEmpty = false,
    hasDelta = false,
    expandibleButtonStyles,
    expandibleAreaStyles,
    labelStyles,
    valueStyles
}: IDealSummaryItemProps) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    const hasContextProvider = useCheckExpandableAreaProvider();

    const dealSummaryItemPrefix = skewWords(['deal', 'summary', 'item']);
    const kebabLabel = kebabCase(label);
    const labelId = skewWords([dealSummaryItemPrefix, 'label', kebabLabel]);
    const valueId = skewWords([dealSummaryItemPrefix, 'value', kebabLabel]);
    const containerTestId = skewWords([dealSummaryItemPrefix, kebabLabel]);
    const expandableOfferId = skewWords([offerType, containerTestId]);
    const className = isEmpty ? skewWords(['empty', 'item']) : '';
    const expandableId = SUMMARY_ACCORDION_ID;
    const nonZeroValue = isEnhancedDealDetailsEnabled || value !== ZERO_DOLLARS;
    const redrawDeepMenu = nonZeroValue && children;
    const isBaseDealScreenExperience = useSelector(dealerSelectors.isBaseDealScreenExperience);
    const isDealSummaryDetailsExpandId = isBaseDealScreenExperience && containerTestId === DEAL_SUMMARY_DETAILS_EXPAND_ID;

    const createExpandableAreaSection = useCallback(
        (children: ReactNode): ReactNode => {
            return (
                <>
                    <StyledExpandableAreaSection
                        expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
                        $isDealSummaryDetailsExpandId={isDealSummaryDetailsExpandId}
                    >
                        <LineItemRow
                            styles={{
                                pl: isBaseDealScreenExperience ? '0px' : '8px',
                                ml: isBaseDealScreenExperience ? '-2px' : '0px',
                                ...(isBaseDealScreenExperience && { pt: '10px', pb: '10px' }),
                                ...expandibleButtonStyles
                            }}
                        >
                            <ChevronIcon
                                expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
                                isDealSummaryDetailsExpandId={isDealSummaryDetailsExpandId}
                                isBaseDealScreenExperience={isBaseDealScreenExperience}
                            />
                            <Typography
                                tag="span"
                                sx={{
                                    flexGrow: 1,
                                    ...labelStyles
                                }}
                                variant="body-sm"
                                color={isBaseDealScreenExperience ? 'sem.color.on-surface.default' : 'sem.color.on-surface.muted'}
                                data-testid={containerTestId}
                            >
                                {label}
                            </Typography>
                            <Typography
                                tag="span"
                                variant="body-lg"
                                color="sem.color.on-surface.default"
                                data-testid={valueId}
                                sx={{
                                    ...valueStyles,
                                    ...(isBaseDealScreenExperience && { fontWeight: 600, fontSize: '16px' })
                                }}
                            >
                                {value}
                            </Typography>
                        </LineItemRow>
                    </StyledExpandableAreaSection>
                    <ExpandableArea
                        data-testid={expandableId}
                        expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
                    >
                        <Grid
                            sx={{
                                pb: 2,
                                pr: 2,
                                pl: isDealSummaryDetailsExpandId ? 0 : '26px',
                                ...expandibleAreaStyles
                            }}
                        >
                            {children}
                        </Grid>
                    </ExpandableArea>
                </>
            );
        },
        [children]
    );

    const createExpandableAreaProvider = useCallback(
        (children: ReactNode): ReactNode => {
            return <ExpandableAreaProvider>{createExpandableAreaSection(children)}</ExpandableAreaProvider>;
        },
        [children]
    );

    const deepMenu = useCallback(
        (children: ReactNode, hasContextProvider: boolean) => {
            return (
                <StyledListItem className={`${className} ${hasDelta ? 'has-delta' : ''}`} hasDelta={hasDelta}>
                    {hasContextProvider ? createExpandableAreaSection(children) : createExpandableAreaProvider(children)}
                </StyledListItem>
            );
        },
        [hasContextProvider, children]
    );

    if (redrawDeepMenu) {
        return deepMenu(children, hasContextProvider);
    }

    return (
        <StyledListItem data-testid={containerTestId} hasDelta={hasDelta} className={`${className} ${hasDelta ? 'has-delta' : ''}`}>
            <LineItemRow
                styles={{
                    pl: '26px',
                    ...(isBaseDealScreenExperience && { pt: '10px', pb: '10px' }),
                    ...expandibleButtonStyles
                }}
            >
                <Typography
                    tag="span"
                    variant="body-sm"
                    color={isBaseDealScreenExperience ? 'sem.color.on-surface.default' : 'sem.color.on-surface.muted'}
                    data-testid={labelId}
                    sx={{
                        flexGrow: 1,
                        ...labelStyles
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    sx={{
                        ...valueStyles,
                        ...(isBaseDealScreenExperience && { fontWeight: 600, fontSize: '16px' })
                    }}
                    tag="span"
                    variant="body-lg"
                    color="sem.color.on-surface.default"
                    data-testid={valueId}
                >
                    {value}
                </Typography>
            </LineItemRow>
        </StyledListItem>
    );
};

export default DealSummaryItem;
