// istanbul ignore file # Interstate breakage.
import { SlideOut } from '@interstate/components/SlideOut';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const VehicleProtectionTitle = styled.h1`
    &&& {
        text-align: left;
        padding: 2rem 0;
        margin: 0;
    }
`;

export const VehicleProtectionContent = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 72px;

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            gap: ${rem(17)};

            #ids-table-vehicle-protection-plan-table > div.MuiBox-root {
                display: none;
            }
        `}
`;

export const VehicleProtectionFooterContainer = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
`;

export const VehicleProtectionFooter = styled.div<{ isNewAppWidthEnabled: boolean }>`
    border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    display: flex;
    justify-content: flex-end;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    padding: ${({ theme }) => theme.tokens.BaseSpace40};
    width: 100%;
`;

export const EditDealAttributesLink = styled.a`
    font-size: ${(props) => props.theme.tokens.BrandTypographyFontSizeBase};
`;

export const StyledSlideOut = styled(SlideOut)`
    #sliding-drawer-footer-actions {
        padding-right: 24px !important;
    }

    .with-overflow-y {
        min-height: 101%;
        margin-bottom: 80px;
    }
`;

export const StyledSlideOutFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
    background-color: rgba(244, 244, 244, 0.96);
    border-top: 1px solid #b2b2b2;
    padding: 8px 20px;
`;

export const StyledSlideOutProductPlanFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
    padding: 8px 20px;
    background-color: white;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    min-height: 36px;
    position: relative;
    justify-content: center;

    & #interstitial-overlay-root {
        background-color: transparent;
        min-width: 32px;
        min-height: 36px;
    }
    & #interstitial-content-root {
        min-width: 32px;
        box-shadow: none;
    }
`;

export const TableWrapper = styled.div`
    border: 1px solid #b2b2b2;
    margin-bottom: ${rem(22)};
`;

export const VehicleProtectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
