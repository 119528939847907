// components
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';
import { DEALER_SUPPORT_REQUEST_FORM } from '../constants';

export type ErrorModalDialogContentProps = {
    alertString: string;
    contentString: string;
    alertTestId?: string;
};

export const ErrorModalDialogContent = ({
    alertString,
    contentString,
    alertTestId = 'modal-alert'
}: ErrorModalDialogContentProps) => {
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPORT_REQUEST_FORM);
    };

    return (
        <>
            <Alert type="error" sx={{ margin: '24px 0' }} data-testid={alertTestId}>
                <Typography variant="body-sm" color="sem.color.on-surface.default" tag="span">
                    {alertString}
                </Typography>
            </Alert>
            <Typography variant="body-md" color="sem.color.on-surface.default" tag="p">
                {contentString}
            </Typography>
            <Grid sx={{ margin: '24px 0' }}>
                <Typography variant="strong-md" color="sem.color.on-surface.default" tag="span">
                    If the problem persists, please contact support:{' '}
                </Typography>
                <Typography variant="body-sm" color="sem.color.on-surface.default" tag="span">
                    1-888-895-2994 or create a
                </Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket" sx={{ marginLeft: '4px' }}>
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        support ticket
                    </Typography>
                </Action>
            </Grid>
        </>
    );
};
