// libraries
import { useEffect } from 'react';
import hotkeys from 'hotkeys-js';

// components
import { PaymentStatus } from '@makemydeal/dr-dash-components';
import { DeskingAlerts } from '../DeskingAlerts';
import { BaseDealHeaderRoot, BaseDealHeaderTitle, BaseDealBreakdown } from './BaseDealActivity.interstate.style';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { ChevronRightIcon, ChevronLeftIcon } from '@interstate/components/Icons';

type baseDealHeaderProps = {
    showDealSummary: boolean;
    setShowDealSummary: React.Dispatch<React.SetStateAction<boolean>>;
};

const BaseDealHeader: React.FC<baseDealHeaderProps> = ({ showDealSummary, setShowDealSummary }) => {
    // hotkey for breakdown
    useEffect(() => {
        hotkeys('alt+b', function (event) {
            event.preventDefault();
            setShowDealSummary(!showDealSummary);
        });

        return () => {
            hotkeys.unbind('alt+b');
        };
    }, [showDealSummary]);

    return (
        <>
            <PaymentStatus sx={{ marginBottom: 2 }} />
            <DeskingAlerts />
            <BaseDealHeaderRoot>
                <BaseDealHeaderTitle>Deal Details</BaseDealHeaderTitle>
                <BaseDealBreakdown>
                    <Action
                        data-testid="base-deal-breakdown"
                        onClick={() => {
                            setShowDealSummary(!showDealSummary);
                        }}
                    >
                        <Typography variant="anchor-block-md" color="base.color.blue.500" className="base-deal-breakdown-icon">
                            {showDealSummary ? null : <ChevronLeftIcon />}
                            Breakdown
                            {showDealSummary ? <ChevronRightIcon /> : null}
                        </Typography>
                    </Action>
                </BaseDealBreakdown>
            </BaseDealHeaderRoot>
        </>
    );
};

export default BaseDealHeader;
