import styled from 'styled-components';
import { Box } from '@interstate/components/Box';

export const SimpleMonthUsage = styled(Box)<{ taxOverrideEnabled: boolean }>`
    margin-right: ${(props) => (props.taxOverrideEnabled ? '0px' : '14px')};
    justify-content: ${(props) => (props.taxOverrideEnabled ? 'space-between' : 'auto')};
    width: ${(props) => (props.taxOverrideEnabled ? '350px' : 'auto')};
`;

export const StyledMonthlyRate = styled.div<{ $textAlign?: string; $minWidth?: string; $marginTop?: string }>`
    min-width: ${(props) => props.$minWidth || '3.125rem'};
    text-align: ${(props) => props.$textAlign || 'left'};
    margin-top: ${(props) => props.$marginTop || '0.3rem'};
    font-size: 16px;
`;
