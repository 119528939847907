import { FC } from 'react';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import ActionsInterstate from './Actions.interstate';
import { ActionsContainer, CutOffShadowContainer, FooterContainer } from './Footer.style.interstate';

export const FooterInterstate: FC = () => {
    const theme = useInterstateTheme();

    return (
        <FooterContainer className="text-center" data-testid="base-deal-screen-footer-actions-container">
            <CutOffShadowContainer isNewAppWidthEnabled={true}>
                <ActionsContainer isNewAppWidthEnabled={true} theme={theme}>
                    <ActionsInterstate></ActionsInterstate>
                </ActionsContainer>
            </CutOffShadowContainer>
        </FooterContainer>
    );
};

export default FooterInterstate;
