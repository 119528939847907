import { createContext } from 'react';

export type ExpandableAreaContextType = {
    /**
     * Toggle expandable area by key.
     * @param expandableId Key to identify an individual area.
     * @param override Optional value to directly update state. If undefined, will toggle value
     * @returns
     */
    doToggle: (expandableId?: string, override?: boolean) => void;
    /**
     * Returns True if area specified by key is expanded.
     * @param expandableId Identifier key (any key you want)
     * @returns True if expanded; false if collapsed.`
     */
    isExpanded: (expandableId?: string) => boolean;
    /**
     * Returns True if area specified by key exists.
     * @param expandableId Identifier key
     * @returns True if exists; false if doesn't exist.`
     */
    hasId: (expandableId?: string) => boolean;
    /**
     * Expand all toggles
     * @returns
     */
    doToggleAll: () => void;
    /**
     * Returns True if any toggle is expanded.
     * @returns True if any toggle expands; false if none of the toggles expand.`
     */
    isAllExpanded: () => boolean;
};

export const ExpandableAreaContext = createContext<ExpandableAreaContextType | null>(null);
