import DealSummaryItem from '../common/DealSummaryItem';
import { expandibleAreaStyles, expandibleButtonStyles, labelStyles } from '../utils/styles';
import * as constants from '../../constants';
import { ReactNode } from 'react';

type detailsProps = {
    children: ReactNode;
};
const Details = ({ children }: detailsProps) => {
    return (
        <DealSummaryItem
            label={constants.DEAL_SUMMARY_DETAILS}
            value={''}
            labelStyles={labelStyles}
            expandibleButtonStyles={expandibleButtonStyles}
            expandibleAreaStyles={expandibleAreaStyles}
        >
            {children}
        </DealSummaryItem>
    );
};

export default Details;
