/**
 * @returns timeout value from sessionStorage or undefined
 */
export const getTimeout = (): number | undefined => {
    // BYPASS_OKTA is only set for UI Integration Tests or by developers for local development
    // so this function will always return undefined in an AWS environment
    if (process.env.BYPASS_OKTA === 'true') {
        const value = window.sessionStorage.getItem('unsavedChangesTimeout');
        return value == null ? undefined : Number(value);
    }
    return undefined;
};
