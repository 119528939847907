// local
import {
    APP_HOST,
    CHOOSE_VEHICLE_APP_PREFIX,
    CHOOSE_VEHICLE_HOST_PREFIX,
    CHOOSE_VEHICLE_LOCAL_BFF_PORT,
    DEV_HOST,
    HOST_PREFIX_REPLACEMENT_TOKEN,
    LOCAL_DEV_HOST,
    MANAGER_VIEW_APP_PREFIX,
    MANAGER_VIEW_HOST_PREFIX,
    MANAGER_VIEW_LOCAL_BFF_PORT,
    OPEN_DEAL_APP_PREFIX,
    OPEN_DEAL_HOST_PREFIX,
    OPEN_DEAL_LOCAL_BFF_PORT,
    PRODUCTION_HOST,
    SALES_CHECKIN_APP_PREFIX,
    SALES_CHECKIN_HOST_PREFIX,
    SALES_CHECKIN_LOCAL_BFF_PORT,
    SALES_VIEW_APP_PREFIX,
    SALES_VIEW_HOST_PREFIX,
    SALES_VIEW_LOCAL_BFF_PORT
} from '../consts/apiConfigConsts';

// libraries
import { urlSlicer } from '@makemydeal/dr-common-utils';
// consts/enums
import { HostEnvironment } from './hostUrlHelperEnums';

// interfaces/types
import type { QueryStringParam } from './hostUrlHelperTypes';

// utils
import { getBffEnvOverride } from '../offerSessionUtils/offerSessionUtils';

export const getHostPrefixFromEnvironment = (hostPrefix: string | undefined, environment: HostEnvironment): string | undefined => {
    switch (environment) {
        case HostEnvironment.Dev:
            return `${hostPrefix}-dev`;
        case HostEnvironment.Dev2:
            return `${hostPrefix}-dev2`;
        case HostEnvironment.QA:
            return `${hostPrefix}-qa`;
        case HostEnvironment.UAT:
            return `${hostPrefix}-uat`;
        case HostEnvironment.Prod:
            return hostPrefix;
        default:
            return '';
    }
};

export const mapAppToHostPrefix = (appPrefix: string): string => {
    switch (appPrefix) {
        case MANAGER_VIEW_APP_PREFIX: {
            return MANAGER_VIEW_HOST_PREFIX;
        }
        case SALES_VIEW_APP_PREFIX: {
            return SALES_VIEW_HOST_PREFIX;
        }
        case OPEN_DEAL_APP_PREFIX: {
            return OPEN_DEAL_HOST_PREFIX;
        }
        case CHOOSE_VEHICLE_APP_PREFIX: {
            return CHOOSE_VEHICLE_HOST_PREFIX;
        }
        case SALES_CHECKIN_APP_PREFIX: {
            return SALES_CHECKIN_HOST_PREFIX;
        }
        default: {
            throw new Error(`Unable to map app prefix "${appPrefix}" to a host prefix`);
        }
    }
};

export const mapAppToLocalPortNumber = (appPrefix: string): number => {
    switch (appPrefix) {
        case MANAGER_VIEW_APP_PREFIX: {
            return MANAGER_VIEW_LOCAL_BFF_PORT;
        }
        case SALES_VIEW_APP_PREFIX: {
            return SALES_VIEW_LOCAL_BFF_PORT;
        }
        case OPEN_DEAL_APP_PREFIX: {
            return OPEN_DEAL_LOCAL_BFF_PORT;
        }
        case CHOOSE_VEHICLE_APP_PREFIX: {
            return CHOOSE_VEHICLE_LOCAL_BFF_PORT;
        }
        case SALES_CHECKIN_APP_PREFIX: {
            return SALES_CHECKIN_LOCAL_BFF_PORT;
        }
        default: {
            throw new Error(`Unable to map app prefix "${appPrefix}" to a local port number`);
        }
    }
};

export const getHostFromEnvironment = (hostPrefix: string | undefined, environment: HostEnvironment): string => {
    const prefix = getHostPrefixFromEnvironment(hostPrefix, environment);
    const prefixToUse = prefix || '';

    switch (environment) {
        case HostEnvironment.Dev:
        case HostEnvironment.Dev2:
        case HostEnvironment.QA:
            return DEV_HOST.replace(HOST_PREFIX_REPLACEMENT_TOKEN, prefixToUse);
        case HostEnvironment.UAT:
        case HostEnvironment.Prod:
            return PRODUCTION_HOST.replace(HOST_PREFIX_REPLACEMENT_TOKEN, prefixToUse);
        case HostEnvironment.Local:
            return LOCAL_DEV_HOST;
        default:
            return '';
    }
};

export const isAppDomain = (appPrefix: string | undefined, domain: string) => {
    if (!appPrefix) {
        return false;
    }
    return domain.toLocaleLowerCase().includes(appPrefix);
};

export const getHostPrefixFromEnvironmentApp = (appPrefix: string, environment: HostEnvironment): string => {
    switch (environment) {
        case HostEnvironment.Dev:
            return `dev.${appPrefix}`;
        case HostEnvironment.Dev2:
            return `dev2.${appPrefix}`;
        case HostEnvironment.QA:
            return `qa.${appPrefix}`;
        case HostEnvironment.DarkProd:
            return `dark.${appPrefix}`;
        case HostEnvironment.Prod:
            return appPrefix;
        default:
            return '';
    }
};

export const getHostFromEnvironmentApp = (appPrefix: string, environment: HostEnvironment): string => {
    const prefix = getHostPrefixFromEnvironmentApp(appPrefix, environment);

    switch (environment) {
        case HostEnvironment.Dev:
        case HostEnvironment.Dev2:
        case HostEnvironment.QA:
        case HostEnvironment.DarkProd:
        case HostEnvironment.Prod:
            return APP_HOST.replace(HOST_PREFIX_REPLACEMENT_TOKEN, prefix);
        case HostEnvironment.Local:
            return LOCAL_DEV_HOST;
        default:
            return '';
    }
};
export const getHostUrlEnvironment = (appPrefix: string, docLocHref: string): HostEnvironment => {
    const schemeAndDomain = urlSlicer.extractSchemeAndDomain(docLocHref);
    const scheme = urlSlicer.extractScheme(docLocHref) + '://';
    const domainAndPort = schemeAndDomain.substr(scheme.length);
    const colonIndex = domainAndPort.indexOf(':');
    const domainOnly = colonIndex < 0 ? domainAndPort : domainAndPort.substr(0, colonIndex);

    // check host only against the host we generate
    const environments = [
        HostEnvironment.Dev,
        HostEnvironment.Dev2,
        HostEnvironment.DarkProd,
        HostEnvironment.Prod,
        HostEnvironment.QA,
        HostEnvironment.UAT,
        HostEnvironment.Local
    ];

    // go through all the environments and find out which one we are in
    for (const env of environments) {
        const host = isAppDomain(appPrefix, domainOnly)
            ? getHostFromEnvironmentApp(appPrefix, env)
            : getHostFromEnvironment(mapAppToHostPrefix(appPrefix), env);
        if (host === domainOnly.toLowerCase()) {
            return env;
        }
    }

    // if we get here, we do not know the environment
    return HostEnvironment.Unknown;
};

export const getQueryStringParams = (docLocHref: string): QueryStringParam[] => {
    const queryString = urlSlicer.extractQueryString(docLocHref);

    if (!queryString) {
        return [];
    }
    const queryStringParts = queryString.split('&');
    return queryStringParts.map((part: string) => {
        const equalIdx = part.indexOf('=');
        const name = equalIdx >= 0 ? part.substr(0, equalIdx) : part;
        const value = equalIdx >= 0 ? part.substr(equalIdx + 1) : null;
        return { name, value };
    });
};

export const getQueryParamFromDocLocHref = (docLocHref: string, name: string): string | null => {
    const queryStringParams = getQueryStringParams(docLocHref);
    const matchingParams = queryStringParams.filter((param: QueryStringParam) => param.name.toLowerCase() === name.toLowerCase());
    return matchingParams.length === 1 ? matchingParams[0].value : null;
};

export const getEnvOverrideFromSessionStorage = (envQueryParamName: string): HostEnvironment => {
    const value = getBffEnvOverride(envQueryParamName);

    if (value === null) {
        return HostEnvironment.None;
    } else {
        switch (value) {
            case 'local': {
                return HostEnvironment.Local;
            }
            case 'dev': {
                return HostEnvironment.Dev;
            }
            case 'dev2': {
                return HostEnvironment.Dev2;
            }
            case 'qa': {
                return HostEnvironment.QA;
            }
            case 'uat': {
                return HostEnvironment.UAT;
            }
            case 'prod':
            case 'production': {
                return HostEnvironment.Prod;
            }
            default: {
                return HostEnvironment.Unknown;
            }
        }
    }
};
