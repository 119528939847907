// libraries
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hotkeys from 'hotkeys-js';

// components
import { BaseDealTabs } from '../BaseDealActivity.interstate.style';
import { RebatesIndex } from '../../rebates';
import { Tabs } from '@interstate/components/Tabs';
import { VehicleProtectionPreview } from '../../vehicleProtection/VehicleProtectionPreview';
import BaseDealLeander from '../../baseDealLender/BaseDealLender';
import BaseDealSummary from './BaseDealSummary.interstate';
import FeesCard from '../../fees/FeesCard';
import InterstateAccessoriesCard from '../../accessories/AccessoriesCard.interstate';
import InterstateTradeFormCard from '../../tradeForm/TradeFormCard.interstate';
import TaxCard from '../../taxes/Taxes/TaxCard';

// selectors
import { baseDealSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// types
import { BaseTabs } from '../baseDealTypes';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';

// utils
import { findButtonByRoleAndText } from '../../../utils/components';
import { handleTabChange } from './utils';

const HOTKEYS = 'alt+y,alt+t,alt+f,alt+x,alt+p,alt+i,alt+l,alt+a,alt+right,alt+left';

const getTabs = (activeTab: BaseTabs, offerType: OfferType) => {
    return [
        { component: <BaseDealSummary />, label: 'Summary', value: BaseTabs.SUMMARY },
        {
            component: (
                <>
                    <InterstateTradeFormCard isEditDisabled={false} />
                </>
            ),
            label: 'Trade In',
            value: BaseTabs.TRADE_IN
        },
        {
            component: (
                <>
                    <FeesCard />
                </>
            ),
            label: 'Fees',
            value: BaseTabs.FEES
        },
        {
            component: (
                <>
                    <TaxCard />
                </>
            ),
            label: 'Taxes',
            value: BaseTabs.TAXES
        },
        {
            component: (
                <>
                    <VehicleProtectionPreview />
                </>
            ),
            label: 'Protection',
            value: BaseTabs.PROTECTION
        },
        {
            component: (
                <>
                    <RebatesIndex />
                </>
            ),
            label: 'Incentives',
            value: BaseTabs.INCENTIVES
        },
        ...(offerType === CASH
            ? []
            : [
                  {
                      component: (
                          <>
                              <BaseDealLeander />
                          </>
                      ),
                      label: 'Lender',
                      value: BaseTabs.LENDER
                  }
              ]),
        {
            component: (
                <>
                    <InterstateAccessoriesCard />
                </>
            ),
            label: 'Accessories',
            value: BaseTabs.ACCESSORIES
        }
    ].map((item) => ({
        ...item,
        active: activeTab === item.value
    }));
};

const BaseDealTabContent = () => {
    const reduxActiveTab = useSelector(baseDealSelectors.getActiveTab);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const [activeTab, setActiveTab] = useState<BaseTabs>(reduxActiveTab);
    const [shouldFocus, setShouldFocus] = useState(false);

    const dispatch = useDispatch();

    const tabs = useMemo(() => getTabs(activeTab, offerType), [activeTab, offerType]);

    // Convert enum values to an array
    const tabOrder = tabs.map((tab) => tab.value);
    const tabOrderLength = tabOrder.length;

    const getNextTab = (currentTab: BaseTabs): BaseTabs => {
        const currentIndex = tabOrder.indexOf(currentTab);
        return tabOrder[(currentIndex + 1) % tabOrderLength];
    };

    const getPrevTab = (currentTab: BaseTabs): BaseTabs => {
        const currentIndex = tabOrder.indexOf(currentTab);
        return tabOrder[(currentIndex - 1 + tabOrderLength) % tabOrderLength];
    };

    useEffect(() => {
        setActiveTab(reduxActiveTab);
    }, [reduxActiveTab]);

    useEffect(() => {
        if (offerType === CASH && activeTab === BaseTabs.LENDER) {
            handleTabChange(BaseTabs.SUMMARY, dispatch);
        }
    }, [offerType, activeTab]);

    useEffect(() => {
        hotkeys(HOTKEYS, function (event, handler) {
            event.preventDefault();
            setShouldFocus(true);

            switch (handler.key) {
                case 'alt+y':
                    handleTabChange(BaseTabs.SUMMARY, dispatch);
                    break;
                case 'alt+t':
                    handleTabChange(BaseTabs.TRADE_IN, dispatch);
                    break;
                case 'alt+f':
                    handleTabChange(BaseTabs.FEES, dispatch);
                    break;
                case 'alt+x':
                    handleTabChange(BaseTabs.TAXES, dispatch);
                    break;
                case 'alt+p':
                    handleTabChange(BaseTabs.PROTECTION, dispatch);
                    break;
                case 'alt+i':
                    handleTabChange(BaseTabs.INCENTIVES, dispatch);
                    break;
                case 'alt+l':
                    handleTabChange(BaseTabs.LENDER, dispatch);
                    break;
                case 'alt+a':
                    handleTabChange(BaseTabs.ACCESSORIES, dispatch);
                    break;
                case 'alt+right':
                    handleTabChange(getNextTab(activeTab), dispatch);
                    break;
                case 'alt+left':
                    handleTabChange(getPrevTab(activeTab), dispatch);
                    break;
            }
        });

        return () => {
            hotkeys.unbind(HOTKEYS);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        // set focus to tabs whenever changed
        if (shouldFocus) {
            const element = findButtonByRoleAndText('tab', activeTab);
            if (element) {
                element.focus();
            }
        }
    }, [activeTab, shouldFocus]);

    return (
        <BaseDealTabs>
            <Tabs
                data-testid="base-deal-tabs"
                id="base-deal-tabs"
                onActivate={(val) => {
                    handleTabChange(val, dispatch);
                }}
                tabs={tabs}
            />
        </BaseDealTabs>
    );
};

export default BaseDealTabContent;
