import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import ActionsInterstate from './Actions.interstate';
import { ActionsContainer, CutOffShadowContainer, FooterContainer } from './Footer.style.interstate';
import { FooterDealInfo } from './FooterDealInfo';

export const FooterInterstate: FC = () => {
    const theme = useInterstateTheme();
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const enableDraftScenarioPersistence = useSelector(featureToggleSelectors.enableDraftScenarioPersistence);

    return (
        <CutOffShadowContainer isNewAppWidthEnabled={isABPencilEnabled}>
            <FooterContainer theme={theme} className="text-right" data-testid="footer-actions-container">
                {enableDraftScenarioPersistence && <FooterDealInfo />}
                <ActionsContainer
                    theme={theme}
                    isNewAppWidthEnabled={isABPencilEnabled}
                    enableDraftScenarioPersistence={enableDraftScenarioPersistence}
                >
                    <ActionsInterstate />
                </ActionsContainer>
            </FooterContainer>
        </CutOffShadowContainer>
    );
};

export default FooterInterstate;
