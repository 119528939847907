import { ListItem } from '@interstate/components/List';
import styled from 'styled-components';
import { ExpandableAreaSection } from '../../common/ExpandableArea/ExpandableAreaSection';

interface StyledListItemProps {
    hasDelta?: boolean;
}
interface StyledExpandableAreaSection {
    $isDealSummaryDetailsExpandId?: boolean;
}
export const StyledListItem = styled(ListItem)<StyledListItemProps>`
    ${({ hasDelta }) => {
        return hasDelta
            ? `
            overflow: hidden;
            border-left: 4px solid #ffc20e;
            `
            : '';
    }}

    .chevron-icon {
        stroke-width: 2px;
        margin-right: 4px;
        font-size: 12px;
        color: #2b6bdd;
    }
`;

export const StyledExpandableAreaSection = styled(ExpandableAreaSection)<StyledExpandableAreaSection>`
    ${({ $isDealSummaryDetailsExpandId }) => {
        if ($isDealSummaryDetailsExpandId) {
            return `
            background-color: #FFFFFF;
            cursor: pointer;
            border-bottom: 2px solid #b2b2b2;
            padding-left: 16px;
            padding-right: 16px;
            span[data-testid="deal-summary-item-details"] {
                    display: flex;
                    flex-direction: row-reverse;
                    font-weight: 600;
                }      
            `;
        }
        return `cursor: pointer;`;
    }}
`;
