import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@interstate/components/Box';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';
import { deskingActionCreators, deskingSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    TAX_AMOUNT_TOTAL_THRESHOLD,
    ADDITIONAL_CLASS,
    MUT_TAX,
    PRIMARY_CLASS,
    taxNameMappingTable,
    TAXRATE_TOTAL_THRESHOLD,
    TAXRATE_MUT_TOTAL_THRESHOLD
} from '../constants';
import {
    createTrueKeysObject,
    getHumanReadableTaxName,
    getTotalTaxRate,
    hasTaxBreakdown,
    isTaxRateKey,
    objectHasNumericValue
} from './helpers';
import { StyledPriceInputContainer, StyledTaxesContainer } from './TaxItemSummary.style';
import TaxItemSummaryDetailed from './TaxItemSummaryDetailed';
import TaxItemSummarySimple from './TaxItemSummarySimple';
import TaxItemTotalField from './TaxItemTotalField';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import MonthlyTaxSummary from './MonthlyTaxSummary';

interface TaxItemSummaryProps {
    taxItem: paymentServicesTypes.TaxItem;
    taxOverrideEnabled?: boolean;
    hideTaxName?: boolean;
}

const TaxItemSummary: React.FC<TaxItemSummaryProps> = ({ taxItem, taxOverrideEnabled, hideTaxName }) => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isUseOverrideTaxAmountForPayment = useSelector(featureToggleSelectors.useOverrideTaxAmountForPayment);
    const isOverrideAdditonalTaxesForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideAdditonalTaxesForPaymentEnabled);
    const showTotalTaxRateSupersedeAlert = useSelector(deskingSelectors.shouldShowTotalTaxRateSupersedeAlert);
    const simplifyMonthlyUsageTax = useSelector(featureToggleSelectors.simplifyMonthlyUsageTax);

    const [showDetailedTaxSummary, setShowDetailedTaxSummary] = useState(false);
    const [isTotalTaxRateOverThreshold, setIsTotalTaxRateOverThreshold] = useState(false);

    const isAdditionalTaxItemEditable = isOverrideAdditonalTaxesForPaymentEnabled && taxItem.class === ADDITIONAL_CLASS;
    const taxItemBreakDownHasValues = hasTaxBreakdown(taxItem) && objectHasNumericValue(taxItem.taxBreakdown);
    const shouldShowSummaryDetail = taxItemBreakDownHasValues || showDetailedTaxSummary;
    const isMutView = isUseOverrideTaxAmountForPayment && taxItem.taxName === MUT_TAX;

    const [taxBreakdownRateLocal, setTaxBreakdownRateLocal] = useState({});

    useEffect(() => {
        if (!showTotalTaxRateSupersedeAlert) {
            setTaxBreakdownRateLocal({});
            setIsTotalTaxRateOverThreshold(false);
        }
    }, [showTotalTaxRateSupersedeAlert]);

    const handleTaxBreakdownChange =
        (breakdownType: 'amount' | 'rate') => (event: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => {
            const newRate = parseFloat(event.target.value) || 0;
            const taxBreakdown = taxItem.taxBreakdown as Record<string, any>;

            if (isUseOverrideTaxAmountForPayment && isTaxRateKey(keyName)) {
                const local = {
                    ...taxBreakdownRateLocal,
                    [keyName]: newRate
                };

                const totalTaxRate = getTotalTaxRate(taxBreakdown, local);

                if (totalTaxRate >= TAXRATE_TOTAL_THRESHOLD) {
                    setTaxBreakdownRateLocal(local);
                    setIsTotalTaxRateOverThreshold(true);
                    dispatch(deskingActionCreators.showTotalTaxRateSupersedeAlert());
                    return;
                }

                dispatch(deskingActionCreators.hideTotalTaxRateSupersedeAlert());
            }

            if (taxBreakdown?.[keyName] === newRate || (!taxBreakdown && taxItem.taxRate === newRate)) {
                return;
            }

            const isAmountExceedThreshold =
                taxItem.taxBreakdown &&
                Object.values({ ...taxItem.taxBreakdown, [keyName]: newRate }).some(
                    (value) => typeof value === 'number' && value > TAX_AMOUNT_TOTAL_THRESHOLD
                );

            const updatedTaxItem = {
                ...taxItem,
                taxBreakdown: {
                    ...taxItem.taxBreakdown,
                    ...taxBreakdownRateLocal,
                    [keyName]: newRate
                },
                manualChanges: {
                    ...taxItem.manualChanges,
                    ...createTrueKeysObject(Object.keys(taxBreakdownRateLocal)),
                    [keyName]: true,
                    ...(breakdownType === 'amount' && {
                        cityTaxRate: false,
                        countyTaxRate: false,
                        stateTaxRate: false,
                        localTaxRate: false
                    })
                },
                isManualTax: true,
                ...(breakdownType === 'amount' && { isManualTaxRate: false, isManualTaxAmount: true }),
                ...(breakdownType === 'rate' && { isManualTaxRate: true, isManualTaxAmount: false })
            };

            if (isAmountExceedThreshold) {
                return;
            }
            dispatch(actionCreators.updateTaxItems([updatedTaxItem], offerType, true));
            dispatch(deskingActionCreators.hideTotalTaxRateSupersedeAlert());
        };

    const handleTaxTotalItemChange = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        const fieldName = taxItem.taxName === MUT_TAX ? 'monthlyTax' : 'taxAmount';
        const newValue = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;

        if (taxItem[fieldName] === newValue) {
            return;
        }

        /**
         * Under feature flag, display "--" for rate and amount,
         * when one of the total tax item is overridden and the tax item has breakdowns.
         * That way, it will be visible that breakdown items were overridden because the total was overridden.
         */
        if (isUseOverrideTaxAmountForPayment) {
            if (objectHasNumericValue(taxItem.taxBreakdown)) {
                setShowDetailedTaxSummary(true);
            }
        }

        const emptyBreakdown = {
            taxBreakdown: undefined
        };

        const updatedTaxItem = {
            ...taxItem,
            manualChanges: {
                taxAmount: true
            },
            [fieldName]: newValue,
            isManualTax: true,
            isManualTaxRate: false,
            isManualTaxAmount: true,
            ...(isMutView &&
                simplifyMonthlyUsageTax &&
                // istanbul ignore next
                emptyBreakdown)
        };

        const isAmountExceedThreshold = newValue > TAX_AMOUNT_TOTAL_THRESHOLD;

        if (isAmountExceedThreshold) {
            return;
        }
        dispatch(actionCreators.updateTaxItems([updatedTaxItem], offerType, true));
        dispatch(deskingActionCreators.hideTotalTaxRateSupersedeAlert());
    };

    const renderTaxName = () => {
        return hideTaxName ? (
            // this div is here by intention to layout correctly when the total of monthly tax is overriden and tab visited again.
            <div></div>
        ) : (
            <Typography variant="body-md" color="base.color.black" sx={{ marginTop: 1, marginBottom: 1 }}>
                {isUseOverrideTaxAmountForPayment ? getHumanReadableTaxName(taxItem.taxName, taxNameMappingTable) : taxItem.taxName}
            </Typography>
        );
    };

    const renderTaxSummary = () => {
        if (shouldShowSummaryDetail) {
            return (
                <TaxItemSummaryDetailed
                    taxItem={taxItem}
                    key={taxItem.taxName}
                    handleChange={handleTaxBreakdownChange}
                    {...(isUseOverrideTaxAmountForPayment && { isTotalTaxRateOverThreshold })}
                />
            );
        }

        return <TaxItemSummarySimple taxItem={taxItem} handleChange={handleTaxBreakdownChange} />;
    };

    const renderTaxAmount = () => {
        const isPrimaryClass = taxItem.class === PRIMARY_CLASS;
        if (isUseOverrideTaxAmountForPayment) {
            if (!taxOverrideEnabled && (isPrimaryClass || isAdditionalTaxItemEditable)) {
                return (
                    <Box display="flex" justifyContent="flex-end">
                        <StyledPriceInputContainer>
                            <TaxItemTotalField key={taxItem.taxAmount} data={taxItem} handleBlur={handleTaxTotalItemChange} />
                        </StyledPriceInputContainer>
                    </Box>
                );
            }
        }

        return (
            <Box display="grid" textAlign="end" rowGap={1} paddingRight={0.5}>
                <Typography variant="label-md" color="sem.color.on-surface.muted">
                    Tax Total
                </Typography>
                <Typography variant="body-md" tag="span">
                    {formatDollarsAndCents(taxItem.taxAmount)}
                </Typography>
            </Box>
        );
    };

    const renderMutTax = () => {
        return (
            <Box display="flex" justifyContent="flex-end">
                <MonthlyTaxSummary
                    taxItem={taxItem}
                    offerType={offerType}
                    taxOverrideEnabled={taxOverrideEnabled}
                    simplifyMonthlyUsageTax={simplifyMonthlyUsageTax}
                    handleAmountBlur={handleTaxTotalItemChange}
                />
            </Box>
        );
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="tax-items-loading-indicator" />
            <StyledTaxesContainer data-testid={`individualTax-${taxItem.taxName}`} theme={theme}>
                {isMutView ? (
                    <>
                        {renderTaxName()}
                        {!simplifyMonthlyUsageTax && renderTaxSummary()}
                        {renderMutTax()}
                    </>
                ) : shouldShowSummaryDetail || taxOverrideEnabled ? (
                    <>
                        {renderTaxName()}
                        {renderTaxSummary()}
                        {renderTaxAmount()}
                    </>
                ) : (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {renderTaxName()}
                        {renderTaxAmount()}
                    </Box>
                )}
            </StyledTaxesContainer>
        </LoadingSectionParent>
    );
};

export default TaxItemSummary;
