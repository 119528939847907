import isEqual from 'lodash.isequal';

import {
    accessoriesSelectors,
    bridgeUserSelectors,
    configSelectors,
    offerReduxSelectors,
    tradeInSelectors,
    vehicleProtectionSelectors,
    vehicleSelectors,
    widgetSettingsSelectors,
    offerSelectors
} from '.';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { StateTree } from '@makemydeal/dr-dash-types';
import {
    OfferType,
    PdfCash,
    PdfCashBreakdown,
    PdfDeal,
    PdfDealer,
    PdfFinance,
    PdfFinanceBreakdown,
    PdfLease,
    PdfLeaseBreakdown,
    PdfPaymentBreakdown,
    PdfPaymentSummary,
    PDfPaymentGrid,
    PdfPerson,
    PdfTradeIn,
    PdfVehicle,
    RequestPdfPayload
} from '@makemydeal/dr-platform-types';
import {
    getAccessoriesBreakdown,
    getConditionalIncentivesBreakdown,
    getNonConditionalIncentivesBreakdown,
    getTaxesAndFeesBreakdown,
    getNetTradeInEquityBreakdown,
    getVehicleProtectionBreakdown,
    getEstDueAtSigningBreakdown
} from '../utils/pdfUtils';
import { RealState, scopedStateSelectors } from '@makemydeal/shared-scoped-store';

const getDealLink = (state: StateTree) => {
    const _bffConfig = configSelectors.getServicesBff(state);
    const _urlConfig = configSelectors.getUrls(state);
    const dealXgId = offerReduxSelectors.getDealXgId(state);
    const prefix = _urlConfig?.sendToShopper || `${_bffConfig.protocol}://${_bffConfig.host}`;
    return `${prefix}/shopperPage.html?dealXgId=${dealXgId}&route=shopper-quote-dashboard`;
};

const getDiscountForPrinting = (state: StateTree) => {
    return offerReduxSelectors.getDiscount(state);
};

const getPdfPaymentFinanceBreakdown = (state: StateTree, offerType: OfferType): PdfFinanceBreakdown => {
    return {
        monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
        term: offerReduxSelectors.getSelectedTermMonths(state) as number,
        apr: offerReduxSelectors.getApr(state),
        msrp: vehicleSelectors.getRetailPrice(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        downPayment: offerReduxSelectors.getDownPaymentByOfferType(state, offerType),
        estimatedTotalFinanced: offerReduxSelectors.getSelectedTermAmountFinanced(state),
        accessoriesTotal: getAccessoriesBreakdown(state),
        conditionalIncentivesTotal: getConditionalIncentivesBreakdown(state, offerType),
        nonConditionalIncentivesTotal: getNonConditionalIncentivesBreakdown(state, offerType),
        netTradeInEquity: getNetTradeInEquityBreakdown(state),
        taxesAndFeesTotal: getTaxesAndFeesBreakdown(state, offerType),
        vehicleProtectionTotal: getVehicleProtectionBreakdown(state)
    };
};

const getPdfPaymentLeaseBreakdown = (state: StateTree, offerType: OfferType): PdfLeaseBreakdown => {
    return {
        monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
        term: offerReduxSelectors.getSelectedTermMonths(state) as number,
        msrp: vehicleSelectors.getRetailPrice(state),
        mileage: vehicleSelectors.getMileage(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        estimatedAdjustedCapCost: offerReduxSelectors.getAdjustedCapCost(state),
        accessoriesTotal: getAccessoriesBreakdown(state),
        estDueAtSigning: getEstDueAtSigningBreakdown(state, offerType),
        conditionalIncentivesTotal: getConditionalIncentivesBreakdown(state, offerType),
        nonConditionalIncentivesTotal: getNonConditionalIncentivesBreakdown(state, offerType),
        netTradeInEquity: getNetTradeInEquityBreakdown(state),
        taxesAndFeesTotal: getTaxesAndFeesBreakdown(state, offerType),
        vehicleProtectionTotal: getVehicleProtectionBreakdown(state),
        residualValue: offerReduxSelectors.getAdjustedResidualValue(state)
    };
};

const getPdfPaymentCashBreakdown = (state: StateTree, offerType: OfferType): PdfCashBreakdown => {
    return {
        msrp: vehicleSelectors.getRetailPrice(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        accessoriesTotal: getAccessoriesBreakdown(state),
        conditionalIncentivesTotal: getConditionalIncentivesBreakdown(state, offerType),
        nonConditionalIncentivesTotal: getNonConditionalIncentivesBreakdown(state, offerType),
        netTradeInEquity: getNetTradeInEquityBreakdown(state),
        taxesAndFeesTotal: getTaxesAndFeesBreakdown(state, offerType),
        totalAmountDue: offerReduxSelectors.getCashTotalAmountDueAfterCredits(state),
        vehicleProtectionTotal: getVehicleProtectionBreakdown(state)
    };
};

const getPdfPaymentBreakdownPayload = (state: StateTree, offerType: OfferType): PdfPaymentBreakdown => {
    switch (offerType) {
        case 'finance': {
            return { offerType, finance: getPdfPaymentFinanceBreakdown(state, offerType) };
        }
        case 'lease': {
            return { offerType, lease: getPdfPaymentLeaseBreakdown(state, offerType) };
        }
        case 'cash': {
            return { offerType, cash: getPdfPaymentCashBreakdown(state, offerType) };
        }
    }
};

const getPdfPaymentFinance = (state: StateTree, offerType: OfferType): PdfFinance => {
    return {
        monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
        term: offerReduxSelectors.getSelectedTermMonths(state) as number,
        apr: offerReduxSelectors.getApr(state),
        msrp: vehicleSelectors.getRetailPrice(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        downPayment: offerReduxSelectors.getDownPaymentByOfferType(state, offerType),
        nonConditionalIncentivesTotal: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
        conditionalIncentivesTotal: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
        taxesAndFeesTotal: offerReduxSelectors.getTaxesAndFeesTotal(state, offerType),
        vehicleProtectionTotal: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
        accessoriesTotal: accessoriesSelectors.getAccessoriesTotalAmount(state),
        netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
        estimatedTotalFinanced: offerReduxSelectors.getSelectedTermAmountFinanced(state)
    };
};

const getPdfPaymentLease = (state: StateTree, offerType: OfferType): PdfLease => {
    return {
        monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
        term: offerReduxSelectors.getSelectedTermMonths(state) as number,
        msrp: vehicleSelectors.getRetailPrice(state),
        mileage: vehicleSelectors.getMileage(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        downPayment: offerReduxSelectors.getDownPaymentByOfferType(state, offerType),
        nonConditionalIncentivesTotal: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
        conditionalIncentivesTotal: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
        taxesAndFeesTotal: offerReduxSelectors.getTaxesAndFeesTotal(state, offerType),
        vehicleProtectionTotal: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
        accessoriesTotal: accessoriesSelectors.getAccessoriesTotalAmount(state),
        netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
        estimatedAdjustedCapCost: offerReduxSelectors.getAdjustedCapCost(state),
        residualValue: offerReduxSelectors.getAdjustedResidualValue(state)
    };
};

const getPdfPaymentCash = (state: StateTree, offerType: OfferType): PdfCash => {
    return {
        msrp: vehicleSelectors.getRetailPrice(state),
        discount: getDiscountForPrinting(state),
        offerPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
        nonConditionalIncentivesTotal: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
        conditionalIncentivesTotal: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
        taxesAndFeesTotal: offerReduxSelectors.getTaxesAndFeesTotal(state, offerType),
        vehicleProtectionTotal: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
        accessoriesTotal: accessoriesSelectors.getAccessoriesTotalAmount(state),
        netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
        totalAmountDue: offerReduxSelectors.getCashTotalAmountDueAfterCredits(state)
    };
};

const getPdfPaymentPayload = (state: StateTree, offerType: OfferType): PdfPaymentSummary => {
    switch (offerType) {
        case 'finance': {
            return { offerType, finance: getPdfPaymentFinance(state, offerType) };
        }
        case 'lease': {
            return { offerType, lease: getPdfPaymentLease(state, offerType) };
        }
        case 'cash': {
            return { offerType, cash: getPdfPaymentCash(state, offerType) };
        }
    }
};

const getPdfVehicle = (state: StateTree): PdfVehicle => {
    return {
        condition: vehicleSelectors.getVehicleCondition(state) as PdfVehicle['condition'],
        make: vehicleSelectors.getVehicleMake(state),
        model: vehicleSelectors.getVehicleModel(state),
        trim: vehicleSelectors.getVehicleTrim(state),
        vin: vehicleSelectors.getVehicleVin(state),
        stockNumber: vehicleSelectors.getVehicleStockNumber(state),
        urls: { imageUrl: vehicleSelectors.getVehicleImageUrl(state) },
        color: vehicleSelectors.getVehicleExteriorColor(state),
        engine: vehicleSelectors.getVehicleEngine(state),
        fuelEconomy: vehicleSelectors.getFuel(state),
        retailPrice: vehicleSelectors.getRetailPrice(state),
        mileage: vehicleSelectors.getMileage(state),
        year: vehicleSelectors.getVehicleYear(state)
    };
};

const getPdfDeal = (state: StateTree): PdfDeal => {
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const usePaymentGridMV = featureToggleSelectors.usePaymentGridMV(state);
    const paymentGridToggleEnabled = offerReduxSelectors.getPaymentGridToggle(state, offerType);
    const showPaymentGrid = usePaymentGridMV && paymentGridToggleEnabled;
    const paymentGridData: PDfPaymentGrid = offerReduxSelectors.getPaymentGridABPencilPrinting(state);
    const isItemizedDealBreakdownEnabled = dealerSelectors.isShowPrintItemizedDealBreakdownEnabled(state);

    return {
        vehicle: getPdfVehicle(state),
        payment: isItemizedDealBreakdownEnabled
            ? getPdfPaymentBreakdownPayload(state, offerType)
            : getPdfPaymentPayload(state, offerType),
        ...(showPaymentGrid && { paymentGridData })
    };
};

const getPdfDealer = (state: StateTree): PdfDealer => {
    return {
        id: dealerSelectors.getDealerId(state),
        name: dealerSelectors.getDealerName(state),
        brandingLogoUrl: dealerSelectors.getDealerBrandingExperienceLogo(state),
        street: dealerSelectors.getDealerStreet(state),
        city: dealerSelectors.getDealerCity(state),
        state: dealerSelectors.getDealerStateCode(state),
        zip: dealerSelectors.getDealerZip(state),
        sponsor: widgetSettingsSelectors.getSponsor(state),
        salesPerson: {
            firstName: bridgeUserSelectors.getBridgeFirstName(state),
            lastName: bridgeUserSelectors.getBridgeLastName(state)
        },
        phoneNumber: dealerSelectors.getDealerPhone(state)
    } as PdfDealer;
};

const getPdfShopper = (state: StateTree): PdfPerson => {
    const shopperInfo = offerReduxSelectors.getShopperInfo(state);

    return {
        firstName: shopperInfo.firstName,
        lastName: shopperInfo.lastName
    };
};

const getPdfTradeIn = (state: StateTree): PdfTradeIn => {
    return {
        year: tradeInSelectors.getTradeYear(state),
        make: tradeInSelectors.getTradeMake(state),
        model: tradeInSelectors.getTradeModel(state),
        trim: tradeInSelectors.getTradeTrim(state)
    };
};

export const getPdfPayload = (realState: RealState): RequestPdfPayload => {
    const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(realState);
    const primaryState = scopedStateSelectors.getScopeStateById(realState, primaryScopeId) as StateTree;
    const scopesOrder = scopedStateSelectors.getActiveScopesOrder(realState);
    const scopedStates = scopesOrder.map((scopeId) => scopedStateSelectors.getScopeStateById(realState, scopeId) as StateTree);

    const isSendProposalToDDJEnabled = featureToggleSelectors.enableSendProposalToDJ(primaryState);
    const isLeadOverrideSettingEnabled = dealerSelectors.getLeadRoutingOverrideSettingsEnabled(primaryState);
    const isItemizedDealBreakdownEnabled = dealerSelectors.isShowPrintItemizedDealBreakdownEnabled(primaryState);
    const deals = scopedStates.map(getPdfDeal);
    const allVehicles = deals.map((deal) => deal.vehicle);
    const isSingleVehicle = allVehicles.every((vehicle) => isEqual(vehicle, allVehicles[0]));
    const template = isSingleVehicle ? 'singleVehicle' : 'multiVehicle';
    const payload: RequestPdfPayload = {
        deals,
        template,
        applicationName: 'Manager View',
        dealUrl: getDealLink(primaryState),
        dealer: getPdfDealer(primaryState),
        trade: getPdfTradeIn(primaryState),
        shopper: getPdfShopper(primaryState),
        dealXgId: offerReduxSelectors.getDealXgId(primaryState),
        dealXgVersion: offerReduxSelectors.getDealXgVersion(primaryState),
        isSignatureLineEnabled: dealerSelectors.isPrintOfferShowSignatureLineEnabled(primaryState),
        isItemizedDealBreakdownEnabled
    };
    if (isSendProposalToDDJEnabled) {
        payload.pdfUpload = {
            dealRefId: offerReduxSelectors.getFsDealRefId(primaryState) || offerSelectors.getDealRefId(primaryState),
            dealerIdOverride: isLeadOverrideSettingEnabled ? dealerSelectors.getLeadOverrideDealerId(primaryState) : undefined
        };
    }
    return payload;
};
