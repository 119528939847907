// components
import { BaseDealContainerRoot, BaseDealContent, BaseDealVehicleInfo } from '../BaseDealActivity.interstate.style';
import { VehicleInfoCard } from '../../vehicleInfo/VehicleInfoCard';
import BaseDealTabContent from './BaseDealTabContent';
import InterstateDealSummary from '../../dealSummary/DealSummary.interstate';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Grid } from '@interstate/components/Grid';
import { useEffect } from 'react';

type baseDealContainerProps = {
    showDealSummary: boolean;
};

const BaseDealContainer: React.FC<baseDealContainerProps> = ({ showDealSummary }) => {
    const isMobileScreenSize = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    useEffect(() => {
        if (showDealSummary) {
            document.getElementById('expandable-root')?.focus();
        }
    }, [showDealSummary]);

    if (isMobileScreenSize && showDealSummary) {
        return (
            <BaseDealContainerRoot>
                <InterstateDealSummary />
            </BaseDealContainerRoot>
        );
    }

    return (
        <BaseDealContainerRoot>
            <BaseDealContent>
                <BaseDealVehicleInfo>
                    <VehicleInfoCard />
                </BaseDealVehicleInfo>

                <BaseDealTabContent />
            </BaseDealContent>
            {showDealSummary ? (
                <Grid xs={5} sx={{ flex: 1, padding: 0, marginLeft: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                    <InterstateDealSummary />
                </Grid>
            ) : null}
        </BaseDealContainerRoot>
    );
};

export default BaseDealContainer;
