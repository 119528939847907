import { SearchBarErrorContainer, SearchBarErrorDescription, SearchBarErrorLabel } from './SearchAndSelectVehicle.style';

export enum ErrorType {
    NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
    CURRENT_VEHICLE = 'CURRENT_VEHICLE'
}
const errorText = {
    [ErrorType.NO_RESULTS_FOUND]: {
        title: 'No results found',
        description: 'Make sure you have entered full VIN or its last 8 characters, or Stock # (3 characters minimum)'
    },
    [ErrorType.CURRENT_VEHICLE]: {
        title: 'This is the current vehicle',
        description:
            'Make sure you have entered a different full VIN or its last 8 characters, or a different Stock # (3 characters minimum)'
    }
};
const SearchBarError = ({ errorType }: { errorType: ErrorType }) => {
    return (
        <SearchBarErrorContainer>
            <SearchBarErrorLabel>{errorText[errorType].title}</SearchBarErrorLabel>
            <SearchBarErrorDescription>{errorText[errorType].description}</SearchBarErrorDescription>
        </SearchBarErrorContainer>
    );
};

export default SearchBarError;
