import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const BaseDealHeaderRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-top: 24px;
    align-items: center;
`;

export const BaseDealHeaderTitle = styled.h1`
    font-size: 28px;
    font-weight: 700;
`;

export const BaseDealContainerRoot = styled.div`
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #b2b2b2;
    display: flex;

    #expandable-root:focus {
        outline: 2px solid #005ba8;
    }
`;

export const BaseDealContent = styled.div`
    width: 100%;
    flex: 2;
`;

export const BaseDealVehicleInfo = styled.div`
    border-bottom: 1px solid #b2b2b2;
    padding-bottom: 1rem;
`;

export const BaseDealBreakdown = styled.div`
    .base-deal-breakdown-icon {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export const BaseDealTabs = styled.div`
    display: grid;
    @media (max-width: ${BreakPoint.PHONE}) {
        #base-deal-tabs {
            border-radius: 4px;
            border: 1px solid #b2b2b2;
            padding: 0;
            margin-top: 16px;
            box-shadow: 0px 4px 4px 0px #0000001a;
        }

        #base-deal-tabs > div > div {
            flex-direction: column;
            gap: 0px;

            & > button {
                justify-content: flex-start;
                max-width: 100%;
            }

            & > .Mui-selected {
                color: #1976d2;
                background-color: #ebf6ff;
            }
        }

        #base-deal-tabs .MuiTabs-indicator {
            display: none;
        }
    }
`;
