// consts/enums
import { HostEnvironment } from '../hostUrlHelper/hostUrlHelperEnums';

export const LOCAL_ASSET_PORT = 4310;
export const MANAGER_VIEW_LOCAL_BFF_PORT = 14310;
//TODO: SV - Replace 14310 with port for SV when  it's implemented in this repository.
export const SALES_VIEW_LOCAL_BFF_PORT = 14310;
export const OPEN_DEAL_LOCAL_BFF_PORT = 13180;
export const CHOOSE_VEHICLE_LOCAL_BFF_PORT = 13200;
export const SALES_CHECKIN_LOCAL_BFF_PORT = 13210;

export const DASH_DEBUG = 'dashDebug';
// TODO: SV - may need to change where this lives as it isn't "shared" across MV and SV
export const DASH_CSS = 'dashCss';
export const BFF_ENV = 'bffEnv';
export const THEME_ENV = 'themeEnv';

export const DASH_DEBUG_SOS = 'sos';

export const HOST_PREFIX_REPLACEMENT_TOKEN = '{prefix}';
export const LOCAL_DEV_HOST = 'localhost';
export const DEV_HOST = `${HOST_PREFIX_REPLACEMENT_TOKEN}.awsdrstnp.makemydeal.com`;
export const PRODUCTION_HOST = `${HOST_PREFIX_REPLACEMENT_TOKEN}.awsdrst.makemydeal.com`;

export const MANAGER_VIEW_HOST_PREFIX = 'dash';
export const SALES_VIEW_HOST_PREFIX = 'sv';
export const OPEN_DEAL_HOST_PREFIX = 'opendeal';
export const CHOOSE_VEHICLE_HOST_PREFIX = 'choosevehicle';
export const SALES_CHECKIN_HOST_PREFIX = 'salescheckin';

// MANAGER_VIEW_HOST was changed to APP_HOST to support MV & SV:
export const APP_HOST = `${HOST_PREFIX_REPLACEMENT_TOKEN}.app.coxautoinc.com`;

export const MANAGER_VIEW_APP_PREFIX = 'managerview';
export const SALES_VIEW_APP_PREFIX = 'salesview';
export const OPEN_DEAL_APP_PREFIX = 'opendeal';
export const CHOOSE_VEHICLE_APP_PREFIX = 'choosevehicle';
export const SALES_CHECKIN_APP_PREFIX = 'salescheckin';

export const LOCAL_SCHEME = 'http';
export const DEV_SCHEME = 'https';

export const ASSETS_BASE = 'assets';

export const BFF_ENV_LIST = [HostEnvironment.Local, HostEnvironment.Dev, HostEnvironment.Dev2, HostEnvironment.QA];

export const DEAL_EXCHANGE_ID = 'dealXgId';
// export const DEAL_EXCHANGE_VERSION = 'dealXgVersion';
// export const COMMON_ORG_ID = 'commonOrgId';
// export const COMMON_CONSUMER_ID = 'commonConsumerId';
export const ENVIRONMENT_TYPE = 'environmentType';
export const FS_DEAL_REF_ID = 'fsDealRefId';

export const COMMON_ORG_ID_PARAM = 'OrgID';
export const COMMON_CONSUMER_ID_PARAM = 'CCID';

export const ENABLE_TOGGLES = 'enableToggles';
export const DISABLE_TOGGLES = 'disableToggles';
export const ENABLE_DEALER_TOGGLES = 'enableDealerToggles';
export const DISABLE_DEALER_TOGGLES = 'disableDealerToggles';

export const TARGET = 'target';
