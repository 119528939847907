// externals
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { TextInput } from '@interstate/components/TextInput';
import { Button } from '@interstate/components/Button';
import { Spinner } from '@interstate/components/Spinner';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { MagnifyingGlassIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ToggleGroup, ToggleButtonOptions } from '@interstate/components/ToggleGroup';

// local components
import BuildVehicle, { InitBuildVehicleValueType } from '../buildVehicle/BuildVehicle';
import ChangeVehicleSearchResult from './ChangeVehicleSearchResult';
import SearchBarError, { ErrorType } from './SearchBarError';

// utils
import { getFilteredSearchResults } from '../../utils/filterSearchResults';

// types
import { IVehicle } from '@makemydeal/dr-platform-types';

// styles
import {
    InlineSearchButtonContainer,
    ChangeVehicleTabsContainer,
    SearchAndSelectVehicleContainerWrapper,
    SearchAndSelectVehicleContainer
} from './ChangeVehicle.interstate.style';
import {
    SearchBarInfoIcon,
    SearchBarLabelContainer,
    SearchBarTooltip,
    SearchBarLabel,
    SearchSpinnerContainer
} from './SearchAndSelectVehicle.style';

// store
import { changeVehicleCreators, changeVehicleSelectors } from '../../store';

enum TABS {
    searchVehicle,
    buildVehicle
}

type TabIndex = TABS.searchVehicle | TABS.buildVehicle;

const tabsData = [
    {
        title: <div data-testid="search-vehicle-tab">Search Vehicle</div>,
        value: TABS.searchVehicle
    },
    {
        title: <div data-testid="build-vehicle-tab">Build Vehicle</div>,
        value: TABS.buildVehicle
    }
];

interface SearchAndSelectVehicleProps {
    coOrgId?: string;
    isCreatingNewDeal?: boolean;
}

const SEARCH_FIELD_MAX_LENGTH = 17;
const SEARCH_FIELD_MIN_LENGTH = 3;

const normalizeSearchText = (value: string): string => {
    if (value[0] === '#') {
        return normalizeSearchText(value.slice(1));
    }
    return value;
};

const SearchAndSelectVehicle = ({ coOrgId, isCreatingNewDeal = false }: SearchAndSelectVehicleProps) => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const searchBarLabel = 'VIN/Stock #';
    const searchBarTooltipMessage = 'Enter full VIN or its last 8 characters, or Stock # (3 characters minimum)';
    const searchBarPlaceholder = 'Enter full VIN or its last 8 characters, or Stock # (3 characters minimum)';
    const { getServicesBff, getCommonOrgId } = changeVehicleSelectors.additionalSelectors;

    const [searchText, setSearchText] = useState('');
    const [isSearchDisabled, setSearchDisabled] = useState(true);
    const isSearchInProgress = useSelector(changeVehicleSelectors.getShowSearchLoader);
    const searchQuery = useSelector(changeVehicleSelectors.getSearchQuery);
    const vehicleVin = useSelector(changeVehicleSelectors.getVehicleVin);
    const vehicleStockNumber = useSelector(changeVehicleSelectors.getVehicleStockNumber);
    const searchResults = useSelector(changeVehicleSelectors.getSearchResults);
    const bffEndpoint = useSelector(getServicesBff);
    const isSearchError = useSelector(changeVehicleSelectors.getShowSearchError);
    const orgId = useSelector(getCommonOrgId);

    const { filteredSearchResults, isFilteredSearchError } = useMemo(
        () => getFilteredSearchResults(vehicleVin, vehicleStockNumber, searchResults),
        [vehicleVin, vehicleStockNumber, searchResults]
    );
    const [activeTabIndex, setActiveTabIndex] = useState<TabIndex>(TABS.searchVehicle);
    const isSearchVehicleTabActive = activeTabIndex === TABS.searchVehicle;
    const [buildVehicleValue, setBuildVehicleValue] = useState<InitBuildVehicleValueType>({
        vin: undefined,
        year: '',
        make: '',
        model: '',
        trim: ''
    });
    const commonOrgId = coOrgId ?? orgId;

    const tabs: ToggleButtonOptions[] = tabsData.map(({ title, value }) => {
        const children = <div>{title}</div>;
        return {
            value,
            children,
            selected: activeTabIndex === value
        };
    });

    const handleTextSearchChange = (event: InterstateOnChangeEvent<any>) => {
        setSearchText(normalizeSearchText(event.target.value.trim().toUpperCase()));
    };

    const handleSearchEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !isSearchDisabled) handleInterstateSearchClick();
    };

    const handleInterstateSearchClick = () => {
        dispatch(changeVehicleCreators.searchBtnClicked());
        const creator = !isCreatingNewDeal ? changeVehicleCreators.searchVehicles : changeVehicleCreators.fetchCviVehiclesResults;
        dispatch(creator(searchText, commonOrgId, bffEndpoint));
    };

    const redirectToBuildVehicleTab = (vehicle: IVehicle) => {
        const vin = searchQuery;
        const { make = '', model = '', trim = '', year = '' } = vehicle;
        setBuildVehicleValue({ vin, make, model, trim, year: `${year}` });
        setActiveTabIndex(TABS.buildVehicle);
    };

    const hasMaxLengthError = searchText.length > SEARCH_FIELD_MAX_LENGTH;
    const hasMinLengthError = searchText.length < SEARCH_FIELD_MIN_LENGTH;

    useEffect(() => {
        setSearchDisabled(hasMaxLengthError || hasMinLengthError);
    }, [hasMaxLengthError, hasMinLengthError]);

    const errorType = isFilteredSearchError ? ErrorType.CURRENT_VEHICLE : ErrorType.NO_RESULTS_FOUND;

    return (
        <SearchAndSelectVehicleContainerWrapper>
            <SearchAndSelectVehicleContainer className="search-select-vehicle-container">
                {!isCreatingNewDeal && (
                    <ChangeVehicleTabsContainer>
                        <ToggleGroup
                            onChange={(_e, value: TabIndex) => {
                                if (activeTabIndex !== value) {
                                    setActiveTabIndex(value);
                                    setBuildVehicleValue({
                                        vin: undefined,
                                        year: '',
                                        make: '',
                                        model: '',
                                        trim: ''
                                    });
                                    dispatch(changeVehicleCreators.changeVehicleTabChanged());
                                }
                            }}
                            toggleButtonOptions={tabs}
                            toggleWidth="50%"
                            size="medium"
                            id="change-vehicle-tabs"
                            data-testid="change-vehicle-tabs"
                        />
                    </ChangeVehicleTabsContainer>
                )}

                {isSearchVehicleTabActive || isCreatingNewDeal ? (
                    <>
                        <div id="search-bar-container">
                            <SearchBarLabelContainer data-testid="change-vehicle-search-label-container" theme={theme}>
                                <SearchBarLabel data-testid="change-vehicle-search-label">{searchBarLabel}</SearchBarLabel>
                                <SearchBarTooltip
                                    data-testid="change-vehicle-search-label-tooltip"
                                    toolTipContent={searchBarTooltipMessage}
                                    position="right"
                                    size="large"
                                >
                                    <SearchBarInfoIcon data-testid="change-vehicle-search-label-tooltip-icon" theme={theme} />
                                </SearchBarTooltip>
                            </SearchBarLabelContainer>
                            <TextInput
                                id="search-bar-input"
                                data-testid="change-vehicle-search-input"
                                name="textInputAppend"
                                placeholder={searchBarPlaceholder}
                                onChange={handleTextSearchChange}
                                onKeyDown={handleSearchEnter}
                                value={searchText}
                                maxLength={SEARCH_FIELD_MAX_LENGTH}
                                inputSuffix={
                                    <InlineSearchButtonContainer theme={theme}>
                                        <Button
                                            id="change-vehicle-search-btn-inline"
                                            buttonStyle="primary"
                                            icon={<MagnifyingGlassIcon />}
                                            disabled={isSearchDisabled}
                                            onClick={handleInterstateSearchClick}
                                            data-testid="change-vehicle-search-btn-inline"
                                        />
                                    </InlineSearchButtonContainer>
                                }
                            />
                            {(isSearchError || isFilteredSearchError) && <SearchBarError errorType={errorType} />}
                        </div>
                        {filteredSearchResults && (
                            <ChangeVehicleSearchResult
                                searchResults={filteredSearchResults}
                                isSearchError={isSearchError}
                                redirectToBuildVehicleTab={redirectToBuildVehicleTab}
                                isCreatingNewDeal={isCreatingNewDeal}
                            />
                        )}
                    </>
                ) : (
                    <div id="build-vehicle-content">
                        <BuildVehicle initValue={buildVehicleValue} />
                    </div>
                )}
                {isSearchInProgress && (
                    <SearchSpinnerContainer>
                        <Spinner data-testid="search-vehicle-spinner" />
                    </SearchSpinnerContainer>
                )}
            </SearchAndSelectVehicleContainer>
        </SearchAndSelectVehicleContainerWrapper>
    );
};

export default SearchAndSelectVehicle;
