// external
import axios from 'axios';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { getCurrentAxiosRequestInterceptor } from '@makemydeal/dr-shared-network';

// interfaces/types
import { DashMiddleware, DashNext, DashStore, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { printPdfLoading } from '../actions/printPdfActionCreator';
import { PRINT_PDF } from '../actionTypes/printPdfActionTypes';

// utils
import { apiConfigHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { getPdfPayload as getMultiscenarioPrintPayload } from '../selectors/printPdfDealSelectors';
import { getPrintPayload } from '../selectors/printPdfPayloadSelectors';
import { ScopedStore } from '@makemydeal/shared-scoped-store';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => async (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        case PRINT_PDF: {
            const enableMultiscenarioPrintMV = featureToggleSelectors.enableMultiscenarioPrintMV(state);
            const isABPencilStateMVEnabled = featureToggleSelectors.isABPencilStateMVEnabled(state);
            const useMultiscenarioPrint = enableMultiscenarioPrintMV && isABPencilStateMVEnabled;

            const pdfPayload = useMultiscenarioPrint
                ? getMultiscenarioPrintPayload((store as ScopedStore).getRealState())
                : getPrintPayload(state);
            const endpointPath = useMultiscenarioPrint ? '/generatePdfDeal' : `/generatePdf`;

            const locHref = locHrefUtil.getLocHref();
            const endpoint = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, endpointPath).url;

            const axiosRequestInterceptor = getCurrentAxiosRequestInterceptor();
            const { headers } = await axiosRequestInterceptor({ url: endpoint });

            try {
                store.dispatch(printPdfLoading(true));

                const response = await axios.post(endpoint, pdfPayload, {
                    headers: {
                        Authorization: headers.Authorization
                    }
                });

                window.open(response.data.data.url, '_blank')?.focus();
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(`Error calling pdf dash bff endpoint: ${err}`);
            } finally {
                store.dispatch(printPdfLoading(false));
            }

            break;
        }
    }
};
