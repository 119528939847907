// libraries
import hotkeys from 'hotkeys-js';

// components
import { Button } from '@interstate/components/Button';
import { UpdateOfferDialog } from '../UpdateOfferDialog/UpdateOfferDialog';
import ReadyToSendDialogInterstate from '../readyToSendDialog/ReadyToSendDialog.interstate';
import { ChevronDownIcon, EllipsisHorizontalIcon } from '@interstate/components/Icons';
import CheckoutAnywhereDrawer from '../dealActivity/activities/CheckoutAnywhereDrawer';
import { ShowWithFeatureToggle } from '../utils/ShowWithFeatureToggle';
import { PrintPdfLoadingModal } from '../printPdfLoadingModal/PrintPdfLoadingModal';
import { DropdownsContainer } from './Footer.style.interstate';
import { Snackbar } from '@interstate/components/Snackbar';
import PushToDms from '../dealActivity/activities/PushToDms.interstate';
import { PrinterIcon } from '@interstate/components/Icons';

// externals
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    deskingActionCreators,
    offerSelectors,
    configSelectors,
    dealXgDetailsSelectors,
    appSelectors,
    offerActionCreators,
    printPdfActionCreator,
    pushToDarwinActionCreators,
    navigationActionCreators,
    offerInfoSelectors
} from '@makemydeal/dr-dash-store';
import { apiConfigConsts, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { Menu, MenuReferenceType } from '@interstate/components/Menu';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';

enum ActionTypes {
    UPDATE = 'UPDATE',
    DARWIN = 'DARWIN',
    BEGIN_CHECKOUT = 'BEGIN_CHECKOUT',
    MANAGE_DOCUMENTS = 'MANAGE_DOCUMENTS',
    OPEN_IN_FI = 'OPEN_IN_FI',
    DMS = 'DMS'
}

const ActionsInterstate = () => {
    const isMobile = useMediaQuery({ query: `(max-width: ${BreakPoint.PHONE})` });
    const actionsEnabled = useSelector(appSelectors.isLazyInitCompleteForSmartInit);
    const isLatestPushToDmsPlusEnabled = useSelector(dealXgDetailsSelectors.getLatestPushToDmsPlus);
    const enableSendProposalToDJ = useSelector(featureToggleSelectors.enableSendProposalToDJ);
    const dealRefId = useSelector(offerSelectors.getDealRefId) || offerSessionUtils.getSessionValue(apiConfigConsts.FS_DEAL_REF_ID);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;
    const enablePushToDms = useSelector(dealerSelectors.getDMSIntegrationToggle).toLowerCase() !== 'off';
    const enablePatchDealRefId = useSelector(featureToggleSelectors.enablePatchDealRefId);
    const enableBeginCheckout = useSelector(dealerSelectors.enableCheckoutAnywhereToggle);
    const enableDigitalDealJacket = useSelector(featureToggleSelectors.enableDigitalDealJacket);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const pushToDmsRef = useRef<{ handleConfirmPushToDmsShow: () => void }>();
    const dispatch = useDispatch();

    const [showPushToDropdown, setPushToDropdown] = useState<null | HTMLElement>(null);
    const [showActionsDropdown, setActionsDropdown] = useState<null | HTMLElement>(null);
    const overflowMenuRef = useRef<null | HTMLElement>(null);
    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [showReadyToSendDialogSave, setShowReadyToSendDialogSave] = useState(false);
    const openPushToDropdown = Boolean(showPushToDropdown);
    const openActionsDropdown = Boolean(showActionsDropdown);
    const [openOverflowMenu, setOpenOverflowMenu] = useState(false);
    const [showUpdateOfferForBeginCheckout, setShowUpdateOfferForBeginCheckout] = useState(false);
    const [isCheckoutAnywhereDrawerEnabled, setIsCheckoutAnywhereDrawerEnabled] = useState(false);
    const [showUpdateOfferForFni, setShowUpdateOfferForFni] = useState(false);
    const [showUpdateOfferForDdj, setShowUpdateOfferForDdj] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [openFni, setOpenFni] = useState(false);
    const [waitForWS, setWaitForWS] = useState(true);
    const [openDdj, setOpenDdj] = useState(false);

    const handleSaveOfferForBeginCheckout = () => {
        if (isCDL3InMVEnabled) {
            dispatch(deskingActionCreators.beginCheckoutClicked());
        }

        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOfferForBeginCheckout(true);
    };

    useEffect(() => {
        if (dealRefId) {
            if (openFni) {
                setWaitForWS(false);
                openFniInNewTab();
                setOpenFni(false);
            }
            if (openDdj) {
                setWaitForWS(false);
                dispatch(navigationActionCreators.navigateToDDJ());
                setOpenDdj(false);
            }
        }
    }, [dealRefId]);

    useEffect(() => {
        if (!showUpdateOffer) return;

        if (!offerIsBeingSaved && offerHasBeenSaved) {
            setSuccessNotification(true);
            setShowUpdateOffer(false);
            return;
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]);

    // hotkey for save
    useEffect(() => {
        hotkeys('alt+s', function (event) {
            event.preventDefault();
            if (actionsEnabled) confirmAndSaveRequest();
        });

        return () => {
            hotkeys.unbind('alt+s');
        };
    }, [actionsEnabled]);

    const handlePushToButtonClose = () => setPushToDropdown(null);

    const handleActionsButtonClose = () => setActionsDropdown(null);

    const handleOverflowMenuClose = () => setOpenOverflowMenu(false);

    const updateOfferDialogOnHideCallback = () => setShowUpdateOffer(false);

    const snackbarOnCloseCallback = () => setSuccessNotification(false);

    const handlePushToDarwin = () => {
        dispatch(pushToDarwinActionCreators.pushToDarwin());
    };

    const handleSaveOffer = () => {
        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOffer(true);
    };

    const parseUnifiDeepLinkFromConfigUrl = (configUrl: string) => {
        if (configUrl.length > 0) {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            configUrl = `${configUrl}/dealjacket_deal_summary/${fsProvider}/${dealRefId}/`;
        }
        return configUrl;
    };

    const openFniInNewTab = () => {
        const url: string = parseUnifiDeepLinkFromConfigUrl(configUrl);
        window.open(url, '_blank');
    };

    const openUniFiDeepLinkUrl = () => {
        if (enablePatchDealRefId && !dealRefId) {
            setOpenFni(true);
            dispatch(offerActionCreators.saveOffer());
            setShowUpdateOfferForFni(true);
        } else {
            openFniInNewTab();
        }
    };

    const handleManageDocumentsClick = () => {
        if (enablePatchDealRefId && !dealRefId) {
            setOpenDdj(true);
            dispatch(offerActionCreators.saveOffer());
            setShowUpdateOfferForDdj(true);
        } else {
            dispatch(navigationActionCreators.navigateToDDJ());
        }
    };

    const handleMenuActions = (actionType: ActionTypes) => {
        switch (actionType) {
            case ActionTypes.DARWIN:
                handlePushToDarwin();
                handlePushToButtonClose();
                break;
            case ActionTypes.DMS:
                // This is tested in FinishDealCard PushToDm component unit test
                // istanbul ignore next
                pushToDmsRef.current?.handleConfirmPushToDmsShow();
                handlePushToButtonClose();
                break;
            case ActionTypes.BEGIN_CHECKOUT:
                handleSaveOfferForBeginCheckout();
                handleActionsButtonClose();
                break;
            case ActionTypes.MANAGE_DOCUMENTS:
                handleManageDocumentsClick();
                handleActionsButtonClose();
                break;
            case ActionTypes.OPEN_IN_FI:
                openUniFiDeepLinkUrl();
                handleActionsButtonClose();
                break;
        }
    };

    const confirmAndSaveRequest = useCallback(() => {
        if (isLatestPushToDmsPlusEnabled) {
            setShowReadyToSendDialogSave(true);
        } else {
            handleSaveOffer();
        }
    }, [isLatestPushToDmsPlusEnabled]);

    // istanbul ignore next
    const handleSave = () => setShowReadyToSendDialogSave(false);

    // istanbul ignore next
    const setShowReadyToSendDialogSaveOnNoOrHide = () => setShowReadyToSendDialogSave(false);

    const pushToOptions = useMemo(
        () => [
            {
                label: 'DMS',
                value: ActionTypes.DMS,
                id: 'dms-option',
                disabled: !enablePushToDms,
                onSelect: () => {
                    handleMenuActions(ActionTypes.DMS);
                }
            },
            {
                label: 'Darwin',
                value: ActionTypes.DARWIN,
                id: 'darwin-option',
                disabled: !enablePushToDarwin,
                onSelect: () => {
                    handleMenuActions(ActionTypes.DARWIN);
                }
            }
        ],
        [enablePushToDarwin]
    );

    const overflowMenuOptions = useMemo(
        () => [
            {
                label: 'Print',
                value: 'Print',
                id: 'print-offer',
                disabled: !actionsEnabled,
                onSelect: () => {
                    handleSavePrint();
                    handleOverflowMenuClose();
                }
            }
        ],
        [actionsEnabled]
    );

    const actions = useMemo(
        () => [
            {
                label: 'Begin Checkout',
                value: ActionTypes.BEGIN_CHECKOUT,
                id: 'begin-checkout-option',
                disabled: !enableBeginCheckout,
                onSelect: () => {
                    handleMenuActions(ActionTypes.BEGIN_CHECKOUT);
                }
            },
            {
                label: 'Manage Documents',
                value: ActionTypes.MANAGE_DOCUMENTS,
                id: 'manage-documents-option',
                disabled: !enableDigitalDealJacket,
                onSelect: () => {
                    handleMenuActions(ActionTypes.MANAGE_DOCUMENTS);
                }
            },
            {
                label: 'Open In F&I',
                value: ActionTypes.OPEN_IN_FI,
                id: 'F&I-option',
                disabled: enablePatchDealRefId ? false : !dealRefId,
                onSelect: () => {
                    handleMenuActions(ActionTypes.OPEN_IN_FI);
                }
            }
        ],
        [dealRefId, enableBeginCheckout, enablePatchDealRefId]
    );

    const handleSavePrint = () => {
        if (enableSendProposalToDJ && !dealRefId) {
            dispatch(offerActionCreators.saveOffer());
        }
        dispatch(printPdfActionCreator.printPdf());
    };

    const handlePushToButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setPushToDropdown(e.currentTarget);
    };

    const handleActionsButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setActionsDropdown(e.currentTarget);
    };

    const handleOverflowMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        overflowMenuRef.current = e.currentTarget;
        setOpenOverflowMenu(true);
    };

    return (
        <>
            <PushToDms ref={pushToDmsRef} isBaseScreen={true} />
            {!isMobile ? (
                <Button
                    buttonStyle="tertiary"
                    data-testid="print-offer-button"
                    size="medium"
                    disabled={!actionsEnabled}
                    onClick={handleSavePrint}
                    startIcon={<PrinterIcon />}
                >
                    Print
                </Button>
            ) : (
                <DropdownsContainer>
                    <Button
                        buttonStyle="tertiary"
                        onClick={handleOverflowMenuClick}
                        startIcon={<EllipsisHorizontalIcon />}
                        data-testid="over-flow-menu"
                    ></Button>
                    <Menu
                        referenceProps={{
                            open: openOverflowMenu,
                            anchorEl: overflowMenuRef.current as MenuReferenceType,
                            onClose: handleOverflowMenuClose
                        }}
                        icon={<PrinterIcon />}
                        position={'top-end'}
                        menuOptions={overflowMenuOptions.map((item) => ({ ...item }))}
                        width={'150px'}
                    />
                </DropdownsContainer>
            )}

            <DropdownsContainer>
                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="actions-dropdown-button"
                    data-testid="action-dropdown"
                    onClick={handleActionsButtonClick}
                >
                    Actions
                </Button>

                <Menu
                    referenceProps={useMemo(
                        () => ({
                            open: openActionsDropdown,
                            anchorEl: showActionsDropdown as MenuReferenceType,
                            onClose: handleActionsButtonClose
                        }),
                        [openActionsDropdown, showActionsDropdown]
                    )}
                    position={'top-end'}
                    menuOptions={useMemo(
                        () =>
                            actions.map((item) => ({
                                ...item
                            })),
                        [actions]
                    )}
                    width={'185px'}
                />

                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="push-to-dropdown-button"
                    data-testid="push-to-dropdown"
                    onClick={handlePushToButtonClick}
                >
                    Push to...
                </Button>

                <Menu
                    referenceProps={useMemo(
                        () => ({
                            open: openPushToDropdown,
                            anchorEl: showPushToDropdown as MenuReferenceType,
                            onClose: handlePushToButtonClose
                        }),
                        [openPushToDropdown, showPushToDropdown]
                    )}
                    position={'top-end'}
                    menuOptions={useMemo(
                        () =>
                            pushToOptions.map((item) => ({
                                ...item
                            })),
                        [pushToOptions]
                    )}
                    width={'185px'}
                />

                <Button data-testid="update-offer-button" size="medium" disabled={!actionsEnabled} onClick={confirmAndSaveRequest}>
                    Save
                </Button>
            </DropdownsContainer>

            <UpdateOfferDialog show={showUpdateOffer} onHide={updateOfferDialogOnHideCallback} />
            <ReadyToSendDialogInterstate
                data-testid="ready-to-send-save-dialog"
                dialogHeader={'Warning'}
                dialogPromptHeader={'Important Note:'}
                dialogPrompt={`Pushing again will overwrite the existing deal in your DMS.`}
                show={showReadyToSendDialogSave}
                onHide={setShowReadyToSendDialogSaveOnNoOrHide}
                onNo={setShowReadyToSendDialogSaveOnNoOrHide}
                onSaved={handleSave}
                promptSx={{
                    paddingLeft: '0',
                    b: {
                        paddingRight: '5px'
                    }
                }}
            />
            <Snackbar
                data-testid="update-offer-dialog-success"
                show={successNotification}
                message="Deal successfully saved."
                position="bottom-center"
                onClose={snackbarOnCloseCallback}
                type="success"
            />
            <UpdateOfferDialog
                show={showUpdateOfferForBeginCheckout}
                onHide={() => setShowUpdateOfferForBeginCheckout(false)}
                onSuccess={() => setIsCheckoutAnywhereDrawerEnabled(true)}
            />
            {isCheckoutAnywhereDrawerEnabled && (
                <CheckoutAnywhereDrawer
                    setShowUpdateOffer={setShowUpdateOfferForBeginCheckout}
                    setIsOpenSlider={setIsCheckoutAnywhereDrawerEnabled}
                    isOpenSlider={isCheckoutAnywhereDrawerEnabled}
                />
            )}
            <PrintPdfLoadingModal />
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog
                    show={showUpdateOfferForFni}
                    onHide={() => setShowUpdateOfferForFni(false)}
                    waitForWS={waitForWS}
                />
            </ShowWithFeatureToggle>
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog
                    show={showUpdateOfferForDdj}
                    onHide={() => setShowUpdateOfferForDdj(false)}
                    waitForWS={waitForWS}
                />
            </ShowWithFeatureToggle>
        </>
    );
};

export default ActionsInterstate;
