// libraries
import { navigationHooks, RouteValues } from '@makemydeal/dr-dash-store';

import { ChevronLeftIcon } from '@interstate/components/Icons/ChevronLeftIcon';
import { Button } from '@interstate/components/Button';

interface BackToMyDealButtonProps {
    isBaseDealScreenExperience: boolean;
}

const BackToMyDealButton = ({ isBaseDealScreenExperience }: BackToMyDealButtonProps) => {
    const handleBackButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);
    return (
        <Button
            buttonStyle="aux"
            id="MyButton"
            onClick={handleBackButtonClick}
            size="small"
            startIcon={<ChevronLeftIcon />}
            data-testid="back-to-my-deal"
        >
            {isBaseDealScreenExperience ? 'Back' : 'Back to my deal'}
        </Button>
    );
};

export default BackToMyDealButton;
