// components
import { Box } from '@interstate/components/Box';
import React from 'react';
import { Typography } from '@interstate/components/Typography';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import TaxItemRateField from './TaxItemRateField';
import TaxItemMonthlyTaxField from './TaxItemMonthlyTaxField';

//types
import { MonthlyTaxSummaryProps } from '../types';

// actions
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { deskingActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { TAXRATE_MUT_TOTAL_THRESHOLD } from '../constants';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { AcceleronTaxItem } from '@makemydeal/dr-platform-types';
import { SimpleMonthUsage, StyledMonthlyRate } from './MonthlyTaxSummary.style';

export default function MonthlyTaxSummary<Props extends MonthlyTaxSummaryProps>({
    taxItem,
    taxOverrideEnabled,
    handleAmountBlur,
    simplifyMonthlyUsageTax,
    offerType
}: Props) {
    const dispatch = useDispatch();
    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const monthlyTax = useSelector(offerReduxSelectors.getMonthlyUseTax);

    const isRateReadable = hasManualTotalTax || taxItem.manualChanges?.taxAmount;

    const handleMutTaxTotalRateChange = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        const fieldName = 'taxRate';
        const newValue = !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0;

        if (taxItem[fieldName] === newValue) {
            return;
        }

        const updatedTaxItem = {
            ...taxItem,
            manualChanges: {
                taxRate: true
            },
            // we need to convert rate from percent to decimal format, to have consistent data format between PS and dxg
            [fieldName]: Number((newValue / 100).toFixed(5)),
            isManualTax: true,
            isManualTaxRate: true,
            isManualTaxAmount: false,
            taxBreakdown: undefined
        };

        const isAmountExceedThreshold = newValue > TAXRATE_MUT_TOTAL_THRESHOLD;

        if (isAmountExceedThreshold) {
            return;
        }
        dispatch(actionCreators.updateTaxItems([updatedTaxItem], offerType, true));
        dispatch(deskingActionCreators.hideTotalTaxRateSupersedeAlert());
    };

    const renderMutTax = () => {
        if (simplifyMonthlyUsageTax) {
            return (
                <SimpleMonthUsage
                    taxOverrideEnabled={taxOverrideEnabled || false}
                    data-testid="simple-monthly-usage"
                    display="inline-flex"
                    gap="1rem"
                    paddingRight="0.5rem"
                >
                    <Box display="grid" textAlign="end" width={'9rem'}>
                        {taxOverrideEnabled ? (
                            <Box display="grid" textAlign="end" data-testid="simple-monthly-rate">
                                <Typography sx={{ 'margin-right': '2.5rem' }} variant="label-md" color="sem.color.on-surface.muted">
                                    Rate
                                </Typography>

                                {hasManualTotalTax ? (
                                    <StyledMonthlyRate $textAlign="end" $minWidth="11rem">
                                        <DefaultFallback />
                                    </StyledMonthlyRate>
                                ) : (
                                    <Typography data-testid="simple-monthly-rate-value" variant="body-md" tag="span">
                                        {taxItem.taxRate}
                                    </Typography>
                                )}
                            </Box>
                        ) : (
                            <Box display="grid" marginLeft={isRateReadable ? '1rem' : ''} textAlign="start">
                                <Typography variant="label-md" color="sem.color.on-surface.muted">
                                    Rate
                                </Typography>

                                {isRateReadable ? (
                                    <StyledMonthlyRate>
                                        <DefaultFallback />
                                    </StyledMonthlyRate>
                                ) : (
                                    <TaxItemRateField
                                        data-testid="simple-monthly-rate-input"
                                        data={{
                                            isEditedRate:
                                                (taxItem as AcceleronTaxItem).isManualTaxRate || taxItem.manualChanges?.taxRate,
                                            rate: taxItem.taxRate,
                                            name: 'monthlyRate',
                                            hasError: false,
                                            key: 'monthlyRate'
                                        }}
                                        handleBlur={handleMutTaxTotalRateChange}
                                        key={taxItem.taxRate}
                                        maxValue={99}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    <Box width="8rem">
                        {taxOverrideEnabled ? (
                            <Box display="grid" textAlign="end" data-testid="simple-monthly-tax">
                                <Typography variant="label-md" color="sem.color.on-surface.muted">
                                    Monthly Tax
                                </Typography>
                                <Typography data-testid="simple-monthly-tax-value" variant="body-md" tag="span">
                                    {formatDollarsAndCents(taxItem.monthlyTax || monthlyTax)}
                                </Typography>
                            </Box>
                        ) : (
                            <TaxItemMonthlyTaxField
                                data-testid="simple-monthly-tax-input"
                                simplifyMonthlyUsageTax={simplifyMonthlyUsageTax}
                                data={taxItem}
                                handleBlur={handleAmountBlur}
                                key={taxItem.taxAmount}
                            />
                        )}
                    </Box>
                </SimpleMonthUsage>
            );
        }

        return (
            <Box data-testid="monthly-usage" display="inline-flex" gap="2rem" paddingRight="0.5rem">
                <Box width="8rem">
                    {taxOverrideEnabled ? (
                        <Box display="grid" textAlign="end" data-testid="simple-monthly-tax">
                            <Typography variant="label-md" color="sem.color.on-surface.muted">
                                Monthly Tax
                            </Typography>
                            <Typography variant="body-md" tag="span">
                                {formatDollarsAndCents(taxItem.monthlyTax || monthlyTax)}
                            </Typography>
                        </Box>
                    ) : (
                        <TaxItemMonthlyTaxField
                            simplifyMonthlyUsageTax={simplifyMonthlyUsageTax}
                            data={taxItem}
                            handleBlur={handleAmountBlur}
                            key={taxItem.taxAmount}
                        />
                    )}
                </Box>
                <Box display="grid" textAlign="end" width={'9rem'}>
                    <Typography variant="label-md" color="sem.color.on-surface.muted">
                        Tax Total
                    </Typography>
                    <Typography variant="body-md" tag="span">
                        {formatDollarsAndCents(taxItem.taxAmount)}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Box display="flex" justifyContent="flex-end" marginTop="1rem">
            {renderMutTax()}
        </Box>
    );
}
