// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { Reducer } from 'redux';
import {
    REQUEST_ROLL_TO_PAYMENTS,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS,
    REQUEST_ROLL_TO_PAYMENTS_FAILURE
} from '../actionTypes/offerActionTypes';
import { RollToBasicObject } from '../types/rollTo';

type RollToPayments = {
    rollToItems?: RollToBasicObject[];
    messages?: RollToBasicObject[];
    errors?: RollToBasicObject[];
};

export type RollToPaymentsReducer = Reducer<RollToPayments>;

export const initialState = {};

export const reducer: RollToPaymentsReducer = (state = initialState, action: AnyFSA) => {
    switch (action.type) {
        case REQUEST_ROLL_TO_PAYMENTS: {
            return { ...state, loading: true };
        }

        case REQUEST_ROLL_TO_PAYMENTS_SUCCESS: {
            const { rollProgramItems, messages } = action.payload;

            return { ...state, loading: false, rollProgramItems, messages };
        }

        case REQUEST_ROLL_TO_PAYMENTS_FAILURE: {
            const { response } = action.payload;
            /* istanbul ignore next */
            const errors = response?.errors || [];

            return { ...state, loading: false, errors };
        }

        default: {
            return state;
        }
    }
};
