import { deskingSelectors, tradeActionTypes, tradeInSelectors, tradeShopperActivity } from '@makemydeal/dr-dash-store';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, usePaymentCall } from '@makemydeal/dr-dash-components';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';
import { handleTabChange } from '../utils';
import { Grid } from '@interstate/components/Grid';
import { BaseTabs } from '../../baseDealTypes';

const TradeInAllowance = () => {
    const {
        value: allowance,
        handleChange: changeAllowance,
        handleBlur: allowanceBlur
    } = usePaymentCall(tradeInSelectors.getTradeValue, tradeShopperActivity.tradeActionCreators.allowanceChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const failedAllowance = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ALLOWANCE_CHANGED));
    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const tradeInputsDisabled = !isTradeInCompleted;
    const dispatch = useDispatch();

    return (
        <>
            <label
                data-testid="trade-allowance-input-label"
                style={{
                    color: '#005BA8',
                    cursor: 'pointer'
                }}
                onClick={() => handleTabChange(BaseTabs.TRADE_IN, dispatch)}
            >
                Trade Allowance
            </label>
            <NumericInput
                inputPrefix={'$'}
                autoInsertCommas
                allowDecimal
                decimalMaxLength={2}
                decimalMinLength={2}
                data-testid="allowance"
                name={kebabCase('Trade Allowance')}
                onChange={changeAllowance}
                placeholder="10,000.00"
                value={allowance}
                hasError={failedAllowance}
                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                onBlur={allowanceBlur}
                disabled={tradeInputsDisabled}
                displayLabel={false}
            />
        </>
    );
};

export default TradeInAllowance;
